import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, TextSize } from '@freelancer/ui/text';

enum GetStartedTab {
  DEVELOP = 'develop',
  DESIGN = 'design',
}

@Component({
  selector: 'app-static-get-started',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Get Started
        </fl-heading>
      </fl-card>

      <fl-card
        [edgeToEdge]="true"
        [flMarginBottom]="'small'"
      >
        <fl-tabs>
          <fl-tab-item
            titleText="Design"
            iconName="ui-penbrush"
            [selected]="selectedTab === GetStartedTab.DESIGN"
            (click)="handleSelect(GetStartedTab.DESIGN)"
          ></fl-tab-item>
          <fl-tab-item
            titleText="Develop"
            iconName="ui-computer"
            [selected]="selectedTab === GetStartedTab.DEVELOP"
            (click)="handleSelect(GetStartedTab.DEVELOP)"
          ></fl-tab-item>
        </fl-tabs>
      </fl-card>
      <ng-container *ngIf="selectedTab === GetStartedTab.DESIGN">
        <fl-card
          class="SectionCard"
          [flMarginBottom]="'mid'"
          [edgeToEdge]="true"
        >
          <fl-heading
            [size]="TextSize.SMALL"
            [headingType]="HeadingType.H3"
            [weight]="HeadingWeight.BOLD"
            [flMarginBottom]="'xxsmall'"
          >
            Design with Figma
          </fl-heading>
          <fl-text
            [size]="TextSize.XSMALL"
            [flMarginBottom]="'mid'"
          >
            To design product with Bits, you may use the Figma design system
            library. The Figma library is an interactive playground that maps to
            the Bits source of truth. To access Figma, please reach out to UI
            Engineering or Design who will make you an account.
          </fl-text>
          <fl-heading
            [size]="TextSize.SMALL"
            [headingType]="HeadingType.H3"
            [weight]="HeadingWeight.BOLD"
            [flMarginBottom]="'xxsmall'"
          >
            What resources are in Figma?
          </fl-heading>
          <fl-text
            [size]="TextSize.XSMALL"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'mid'"
          >
            <fl-list [type]="ListItemType.UNORDERED">
              <fl-list-item>
                Onboarding activities to learn how it works.
              </fl-list-item>
              <fl-list-item>
                Brand Foundations which include details on colour, grid,
                typography and logos. These foundations can be access directly
                when adding any of these features to your design.
              </fl-list-item>
              <fl-list-item>
                Bit Components which allows you to interact with our responsive
                components. When creating a design, you may drag and drop in
                these assets directly from the Figma library.
              </fl-list-item>
              <fl-list-item>
                Icons and Illustrations approved by the Brand.
              </fl-list-item>
            </fl-list>
          </fl-text>
          <fl-heading
            [size]="TextSize.SMALL"
            [headingType]="HeadingType.H3"
            [weight]="HeadingWeight.BOLD"
            [flMarginBottom]="'xxsmall'"
          >
            What I need is missing?
          </fl-heading>
          <fl-text
            [size]="TextSize.XSMALL"
            [flMarginBottom]="'small'"
          >
            The Figma component library should contain all the components that
            can be found in the Bits source of truth. To enforce consistency
            across the site, you shouldn't need to make anything from scratch.
            If you want something new, talk to design and the ui-engineering
            teams. If both teams agree that it is a worthy addition, either your
            team or ui-engineering will work with design to implement it and get
            it included in the &#64;freelancer/ui module.
          </fl-text>
        </fl-card>
      </ng-container>
      <ng-container *ngIf="selectedTab === GetStartedTab.DEVELOP">
        <fl-card
          class="SectionCard"
          [flMarginBottom]="'mid'"
          [edgeToEdge]="true"
        >
          <fl-text
            [size]="TextSize.XSMALL"
            [fontType]="FontType.CONTAINER"
          >
            <div [flMarginBottom]="'large'">
              <fl-heading
                [headingType]="HeadingType.H2"
                [size]="TextSize.SMALL"
                [flMarginBottom]="'xsmall'"
              >
                How do I use it?
              </fl-heading>
              <fl-text
                [size]="TextSize.XSMALL"
                [flMarginBottom]="'xsmall'"
              >
                Component pages are broken in to the following sections:
              </fl-text>

              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.CONTAINER"
              >
                <fl-list
                  class="List"
                  [type]="ListItemType.UNORDERED"
                  [flMarginBottom]="'small'"
                >
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Heading, containing story menu (top right)
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Brief description and component owner (see owner section)
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    The current story rendered in a light gray bubble
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    The source code for the aforementioned story
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Buttons to smart copy, copy, and expand/collapse the source
                    code
                  </fl-list-item>
                  <fl-list-item class="SubItem">
                    Smart copy will copy the template string of the story only
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    A longer description of the componets with dos and donts
                    (optional)
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Generated input and output tables, describing the components
                    api
                  </fl-list-item>
                  <fl-list-item class="SubItem">
                    <div>
                      Any comments
                      <span class="InlineSnippet">/** */</span> above the
                      parameter will be added to the docs.
                    </div>
                  </fl-list-item>
                  <fl-list-item class="SubItem">
                    <div>
                      <span class="InlineSnippet">:?</span> represents optional
                      parameter
                    </div>
                  </fl-list-item>
                  <fl-list-item class="SubItem">
                    If the type is not primitive it will be a link which will
                    open a modal with the type definition
                  </fl-list-item>
                  <fl-list-item class="SubItem">
                    If the type is an Angular class it should take you to the
                    doc page on angular.io
                  </fl-list-item>
                </fl-list>
              </fl-text>

              <fl-text [size]="TextSize.XSMALL">
                Loading a component page will load the
                <span class="InlineSnippet">default</span> story if one exists,
                always check the story menu (top right) to see if there is one
                that closer describes your use case
              </fl-text>
            </div>

            <div
              class="Section"
              [flMarginBottom]="'large'"
            >
              <fl-heading
                [headingType]="HeadingType.H2"
                [size]="TextSize.SMALL"
                [flMarginBottom]="'xsmall'"
              >
                What I need is missing?
              </fl-heading>
              <fl-text
                [size]="TextSize.XSMALL"
                [flMarginBottom]="'xxxsmall'"
              >
                To enforce consistency across the site, you shouldn't need to
                make anything from scratch.
              </fl-text>
              <fl-text [size]="TextSize.XSMALL">
                If you want something new, talk to
                <span class="InlineSnippet">design</span> and the
                <span class="InlineSnippet">ui-engineering</span> teams. If both
                teams agree that it is a worthy addition, either your team or
                <span class="InlineSnippet">ui-engineering</span> will work with
                design to implement it and get it included in the
                <span class="InlineSnippet">&#64;freelancer/ui</span> module.
              </fl-text>
            </div>
          </fl-text>
        </fl-card>
      </ng-container>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetStartedComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  ListItemType = ListItemType;
  GetStartedTab = GetStartedTab;

  selectedTab = GetStartedTab.DESIGN;

  handleSelect(tab: GetStartedTab): void {
    this.selectedTab = tab;
  }
}
