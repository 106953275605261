import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Environment, ENVIRONMENT_NAME } from '@freelancer/config';
import { Seo } from '@freelancer/seo';
import { ThemeService } from '@freelancer/theme';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { ContainerSize } from '@freelancer/ui/container';
import { VerticalAlignment } from '@freelancer/ui/grid';
import {
  HeadingColor,
  HeadingType,
  HeadingWeight,
} from '@freelancer/ui/heading';
import { IconColor } from '@freelancer/ui/icon';
import { LinkHoverColor } from '@freelancer/ui/link';
import { BackgroundColor, LogoSize } from '@freelancer/ui/logo';
import { PictureLayout, PictureObjectFit } from '@freelancer/ui/picture';
import { FontColor, FontType, TextSize } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import { filter, map, Subscription } from 'rxjs';
import type { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  template: `
    <app-nav
      (menuButtonClicked)="toggleMobileSidebar()"
      [hideInternalPages]="hideInternalPages"
      [isHomepage]="true"
    ></app-nav>
    <div class="MainContainer">
      <div
        *ngIf="(storyOnly$ | flAsync) === false"
        [flHideDesktop]="true"
        class="MainSidebar"
      >
        <div
          class="MainSidebar-backdrop"
          [attr.data-sidebar-open]="sidebarOpen"
          [attr.tabindex]="0"
          [flHideDesktop]="true"
          (click)="toggleMobileSidebar()"
        ></div>
        <app-sidebar
          class="MainSidebar-content"
          [attr.data-sidebar-open]="sidebarOpen"
          [hideInternalPages]="hideInternalPages"
          (closeSidebar)="toggleMobileSidebar()"
        ></app-sidebar>
      </div>
      <div class="HomeContainer">
        <fl-container [size]="ContainerSize.DESKTOP_XLARGE">
          <div
            [flMarginBottom]="'large'"
            [flMarginBottomDesktop]="'xxxlarge'"
          >
            <div
              class="Hero"
              [flMarginBottom]="'xlarge'"
            >
              <div [flMarginBottom]="'large'">
                <fl-picture
                  alt="Bits Logo illustration"
                  [src]="
                    (currentTheme$ | flAsync) === 'dark'
                      ? 'bits/bits-logo-dark.svg'
                      : 'bits/bits-logo-light.svg'
                  "
                  [fullWidth]="false"
                  [layout]="PictureLayout.FILL"
                  [objectFit]="PictureObjectFit.COVER"
                ></fl-picture>
              </div>
              <div
                class="Headline"
                [flMarginBottom]="'xlarge'"
              >
                <fl-heading
                  [color]="HeadingColor.FOREGROUND"
                  [headingType]="HeadingType.H2"
                  [size]="TextSize.SMALL"
                  [sizeTablet]="TextSize.MID"
                  [weight]="HeadingWeight.NORMAL"
                >
                  Easily build functional, beautiful interfaces that match the
                  brand, principles, and language of Freelancer’s design system.
                </fl-heading>
              </div>
              <div class="Hero-actions">
                <fl-button
                  class="Hero-button"
                  [color]="ButtonColor.PRIMARY"
                  [size]="ButtonSize.LARGE"
                  [link]="'/info/introduction'"
                  [flMarginBottom]="'small'"
                  [flMarginBottomTablet]="'none'"
                >
                  Get started
                </fl-button>
              </div>
            </div>
            <fl-grid [vAlign]="VerticalAlignment.VERTICAL_STRETCH">
              <fl-col
                class="HomepageFeatures"
                [col]="12"
                [colDesktopSmall]="4"
                [flMarginBottom]="'large'"
              >
                <fl-link
                  [hoverColor]="
                    (currentTheme$ | flAsync) === 'dark'
                      ? LinkHoverColor.DEFAULT
                      : LinkHoverColor.INHERIT
                  "
                  [link]="'/foundations'"
                >
                  <fl-picture
                    alt="Foundations illustration"
                    [src]="
                      (currentTheme$ | flAsync) === 'dark'
                        ? 'bits/foundations-dark.png'
                        : 'bits/foundations-light.png'
                    "
                    [fullWidth]="true"
                  ></fl-picture>
                  <fl-heading
                    class="HomepageFeatures-heading"
                    [size]="TextSize.MID"
                    [headingType]="HeadingType.H3"
                  >
                    Foundations
                  </fl-heading>
                  <fl-text>
                    Foundations are the core visual elements needed to create
                    engaging layouts and end-to-end user experiences.
                  </fl-text>
                </fl-link>
              </fl-col>
              <fl-col
                class="HomepageFeatures"
                [col]="12"
                [colDesktopSmall]="4"
              >
                <fl-link
                  [hoverColor]="
                    (currentTheme$ | flAsync) === 'dark'
                      ? LinkHoverColor.DEFAULT
                      : LinkHoverColor.INHERIT
                  "
                  [link]="'/components'"
                >
                  <fl-picture
                    alt="Components illustration"
                    [src]="
                      (currentTheme$ | flAsync) === 'dark'
                        ? 'bits/components-dark.png'
                        : 'bits/components-light.png'
                    "
                    [fullWidth]="true"
                  ></fl-picture>
                  <fl-heading
                    class="HomepageFeatures-heading"
                    [size]="TextSize.MID"
                    [headingType]="HeadingType.H3"
                  >
                    Components
                  </fl-heading>
                  <fl-text>
                    The building blocks that make up our interfaces such as
                    buttons, form fields, and other interactive elements.
                  </fl-text>
                </fl-link>
              </fl-col>
              <fl-col
                class="HomepageFeatures"
                [col]="12"
                [colDesktopSmall]="4"
              >
                <fl-link
                  [hoverColor]="
                    (currentTheme$ | flAsync) === 'dark'
                      ? LinkHoverColor.DEFAULT
                      : LinkHoverColor.INHERIT
                  "
                  [link]="'/patterns'"
                >
                  <fl-picture
                    alt="Patterns illustration"
                    [src]="
                      (currentTheme$ | flAsync) === 'dark'
                        ? 'bits/patterns-dark.png'
                        : 'bits/patterns-light.png'
                    "
                    [fullWidth]="true"
                  ></fl-picture>
                  <fl-heading
                    class="HomepageFeatures-heading"
                    [size]="TextSize.MID"
                    [headingType]="HeadingType.H3"
                  >
                    Patterns
                  </fl-heading>
                  <fl-text>
                    Shared Freelancer-specific components for core UI sections
                    to maintain consistency between different products.
                  </fl-text>
                </fl-link>
              </fl-col>
            </fl-grid>
          </div>
        </fl-container>
        <div class="Footer">
          <fl-text
            class="Footer-text-freelancer"
            [color]="FontColor.FOREGROUND"
            [flMarginRightDesktop]="'xlarge'"
            [flMarginRightTablet]="'xlarge'"
            [size]="TextSize.XXSMALL"
            [sizeTablet]="TextSize.XSMALL"
          >
            {{ '© Freelancer.com 2024' }}
          </fl-text>
          <fl-text
            class="Footer-text"
            [color]="FontColor.FOREGROUND"
            [size]="TextSize.XXSMALL"
            [sizeTablet]="TextSize.XSMALL"
          >
            {{ 'Made with' }}
            <fl-icon
              [name]="'ui-heart-v2'"
              [color]="IconColor.ERROR"
            ></fl-icon>
            {{ 'by UI Engineering and Design team' }}
          </fl-text>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  BackgroundColor = BackgroundColor;
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  ContainerSize = ContainerSize;
  FontColor = FontColor;
  FontType = FontType;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  IconColor = IconColor;
  LinkHoverColor = LinkHoverColor;
  LogoSize = LogoSize;
  TextSize = TextSize;
  VerticalAlignment = VerticalAlignment;
  PictureObjectFit = PictureObjectFit;
  PictureLayout = PictureLayout;

  currentTheme$: Observable<string>;

  sidebarOpen = false;
  hideInternalPages = false;
  storyOnly$: Observable<boolean>;

  private subscriptions = new Subscription();

  constructor(
    private seo: Seo,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService,
    private renderer: Renderer2,
    @Inject(ENVIRONMENT_NAME) private environment: Environment,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.currentTheme$ = this.themeService.getTheme();
    this.renderer.removeStyle(this.document.body, 'overflow');

    this.seo.setPageTags({
      title: 'Home',
      description: `A live showcase of Freelancer.com's official design system, Bits`,
    });

    this.storyOnly$ = this.activatedRoute.queryParamMap.pipe(
      map(params => isDefined(params.get('story_only'))),
    );

    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.sidebarOpen = false;
        }),
    );

    this.hideInternalPages = this.environment === 'prod';
  }

  nav(path: string): void {
    this.router.navigate([path]);
  }

  toggleMobileSidebar(): void {
    if (this.sidebarOpen) {
      this.renderer.removeStyle(this.document.body, 'overflow');
    } else {
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    }
    this.sidebarOpen = !this.sidebarOpen;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
