import { isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DateRange as MatDateRange,
  MatCalendar,
  MatDatepicker,
} from '@angular/material/datepicker';
import { shakeAnimation } from '@freelancer/animations';
import { RepetitiveSubscription } from '@freelancer/decorators';
import type { Timer } from '@freelancer/time-utils';
import { TimeUtils } from '@freelancer/time-utils';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { Focus } from '@freelancer/ui/focus';
import { HoverColor, IconColor, IconSize } from '@freelancer/ui/icon';
import { FontColor, FontType, FontWeight, TextSize } from '@freelancer/ui/text';
import { dirtyAndValidate } from '@freelancer/ui/validators';
import type { IsAny } from '@freelancer/utils';
import { isDefined, isNumber, isString } from '@freelancer/utils';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { generateUniqueID } from '../helpers/helpers';
import { LocalizedDateFns } from '../localized-date-fns.service';
import { TESTING_CLOSE_ANIMATION_DISABLED } from '../ui.config';
import type { DateRange } from './input.types';
import {
  AutoCompleteHint,
  AutoFocusOption,
  ControlType,
  InputAlign,
  InputEditInlineTitleSize,
  InputEditInlineType,
  InputSize,
  InputTextAlign,
  InputTextColor,
  InputType,
  KeyboardType,
} from './input.types';

@Component({
  selector: 'fl-input',
  template: `
    <div
      *ngIf="type !== InputType.DATERANGE_INLINE; else dateRangeInline"
      class="InputInner"
      [attr.data-type]="type"
      [attr.data-align]="align"
      [attr.data-edit-inline]="editInline"
    >
      <fl-button
        *ngIf="isNumberIncrement"
        i18n="Input increment button"
        class="InputIncrement"
        [color]="ButtonColor.CUSTOM"
        [disabled]="attrDisabled === true || shouldDisableDecrementButton()"
        [size]="buttonIncrementSize"
        [attr.data-invalid]="hasError && !isWarning"
        [attr.data-valid]="isValid"
        [attr.data-warning]="isWarning"
        [flMarginBottom]="hasError ? 'xxxsmall' : 'none'"
        (click)="handleMinus()"
      >
        <fl-text> - </fl-text>
      </fl-button>
      <div
        #inputContainer
        class="InputContainer"
        [ngClass]="{
          BeforeLabelPresent: beforeLabel,
          AfterLabelPresent: afterLabel,
          Shadowed: isShadowed,
          IsFocused: inputFocused === true,
          IsExpandable: isExpandable === true,
          IsBorderless: borderless === true,
          IsBorderlessMobile: borderlessMobile === true,
          SearchInput: type === InputType.SEARCH,
          'SearchInput-roundCorners':
            type === InputType.SEARCH && searchRoundCorners,
          IsInvalid: hasError && !isWarning,
          IsValid: isValid === true,
          IsDateRangeDataApplied:
            this.type === InputType.DATERANGE &&
            showDateSelectedState &&
            !!control.value &&
            isDateRangeControl(control) &&
            !!control.value?.start &&
            !!control.value?.end
        }"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.disabled]="attrDisabled"
        [attr.data-expanded]="expanded"
        [attr.data-edit-inline]="editInline"
        [attr.data-hide-icon]="
          inputFocused || (isString(control.value) && control.value?.length > 0)
        "
        [attr.data-transparent-background]="transparentBackground"
        [flMarginBottom]="hasError ? 'xxxsmall' : 'none'"
        [@shakeAnimation]="{
          value: shakeState,
          params: {
            duration: 200,
            translateX: '3px'
          }
        }"
        (@shakeAnimation.done)="shakeDone()"
      >
        <div
          class="InputLabel BeforeLabel"
          *ngIf="beforeLabel"
          [attr.data-size]="size"
        >
          <span class="LabelText"> {{ beforeLabel }} </span>
        </div>

        <fl-button
          *ngIf="iconStartClickable"
          [class]="
            iconMarginMobile
              ? 'InputButtonStart'
              : 'InputButtonStart-noMarginMobile'
          "
          [label]="iconStartLabel"
          (click)="handleIconStartClick($event)"
        >
          <fl-icon
            *ngIf="iconStart"
            [size]="leftIconSize"
            [color]="leftIconColor"
            [hoverColor]="HoverColor.PRIMARY"
            [name]="iconStart"
          ></fl-icon>
        </fl-button>
        <fl-icon
          *ngIf="iconStart && !iconStartClickable"
          [class]="
            iconMarginMobile
              ? 'IconElementStart'
              : 'IconElementStart-noMarginMobile'
          "
          [size]="leftIconSize"
          [color]="leftIconColor"
          [label]="iconStartLabel"
          [name]="iconStart"
        ></fl-icon>
        <div
          class="NativeElementContainer"
          [ngClass]="{
            DateRangeNativeElementContainer: type === InputType.DATERANGE
          }"
          [attr.data-transparent-background]="transparentBackground"
          [attr.data-edit-inline]="editInline"
        >
          <ng-container *ngIf="isDateInput; else normalInput">
            <!--
              NOTE: for [type="date"] we set the native [attr.type] to "text"
              This is to avoid the native date-picker stuff showing up
            -->
            <ng-container *ngIf="type === InputType.DATERANGE; else dateInput">
              <mat-date-range-input
                [formGroup]="dateRangeGroup"
                [rangePicker]="picker"
              >
                <input
                  #nativeElement
                  matStartDate
                  class="NativeElement DateRangeNativeElementLeft"
                  [attr.id]="id"
                  [attr.type]="'text'"
                  [attr.placeholder]="dateFormat | flAsync | uppercase"
                  [attr.readonly]="attrReadonly ? true : null"
                  [attr.disabled]="attrDisabled"
                  [attr.maxlength]="maxLength ? maxLength : null"
                  [attr.data-align]="textAlign"
                  [attr.data-text-color]="color"
                  [attr.aria-label]="label ?? 'Start Date'"
                  [attr.autocomplete]="autoComplete"
                  [attr.data-size]="size"
                  [attr.data-borderless]="borderless"
                  [attr.data-borderlessMobile]="borderlessMobile"
                  [attr.data-weight]="fontWeight"
                  formControlName="dateStartControl"
                  (focus)="handleFocusDatePicker('nativeElement')"
                  (keydown)="handleKeydown()"
                  (blur)="handleBlurDatePicker()"
                />
                <input
                  #nativeElement2
                  matEndDate
                  class="NativeElement DateRangeNativeElementRight"
                  [attr.type]="'text'"
                  [attr.placeholder]="dateFormat | flAsync | uppercase"
                  [attr.readonly]="attrReadonly ? true : null"
                  [attr.disabled]="attrDisabled"
                  [attr.maxlength]="maxLength ? maxLength : null"
                  [attr.data-align]="textAlign"
                  [attr.data-text-color]="color"
                  [attr.aria-label]="label ?? 'End Date'"
                  [attr.autocomplete]="autoComplete"
                  [attr.data-size]="size"
                  [attr.data-borderless]="borderless"
                  [attr.data-borderlessMobile]="borderlessMobile"
                  [attr.data-weight]="fontWeight"
                  formControlName="dateEndControl"
                  (focus)="handleFocusDatePicker('nativeElement2')"
                  (keydown)="handleKeydown()"
                  (blur)="handleBlurDatePicker()"
                />
              </mat-date-range-input>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </ng-container>
            <ng-template #dateInput>
              <input
                #nativeElement
                class="NativeElement"
                [ngClass]="{
                  HasIconStart: iconStart,
                  HasIconEnd: iconEnd,
                  CompactDateInput: compactDateInput,
                }"
                [attr.id]="id"
                [attr.type]="'text'"
                [attr.placeholder]="dateFormat | flAsync | uppercase"
                [attr.readonly]="attrReadonly ? true : null"
                [attr.disabled]="attrDisabled"
                [attr.maxlength]="maxLength ? maxLength : null"
                [attr.data-align]="textAlign"
                [attr.data-text-color]="color"
                [attr.data-applied]="showDateSelectedState && !!control.value"
                [attr.aria-label]="label"
                [attr.autocomplete]="autoComplete"
                [attr.data-size]="size"
                [attr.data-borderless]="borderless"
                [attr.data-borderlessMobile]="borderlessMobile"
                [attr.data-transparent-background]="transparentBackground"
                [attr.data-weight]="fontWeight"
                [formControl]="control"
                [matDatepicker]="picker"
                (focus)="handleFocusDatePicker('nativeElement')"
                (keydown)="handleKeydown()"
                (blur)="handleBlurDatePicker()"
              />
              <mat-datepicker #picker></mat-datepicker>
            </ng-template>
          </ng-container>
          <ng-template #normalInput>
            <!--
              This is separate because otherwise matDatepicker will add date validation
            -->
            <ng-container *ngIf="isNumberInput; else nonNumberInput">
              <!--
                FIXME: T276037 - This is separate because [attr.type] binding with 'number'
                returns 'string' instead of 'number': https://github.com/angular/angular/issues/13243
            -->
              <input
                #nativeElement
                class="NativeElement"
                [ngClass]="{
                  HasIconStart: iconStart,
                  HasIconEnd: iconEnd
                }"
                type="number"
                [attr.controlType]="controlType"
                [attr.id]="id"
                [attr.step]="type === InputType.CURRENCY ? 0.01 : null"
                [attr.placeholder]="placeholder"
                [attr.readonly]="attrReadonly ? true : null"
                [attr.disabled]="attrDisabled"
                [attr.maxlength]="maxLength ? maxLength : null"
                [attr.data-align]="textAlign"
                [attr.data-text-color]="color"
                [attr.autocomplete]="autoComplete"
                [attr.data-size]="size"
                [attr.data-left-icon-size]="leftIconSize"
                [attr.data-right-icon-size]="rightIconSize"
                [attr.aria-errormessage]="errorId"
                [attr.aria-invalid]="hasError && !isWarning"
                [attr.aria-label]="label"
                [attr.data-borderless]="borderless"
                [attr.data-borderlessMobile]="borderlessMobile"
                [attr.data-custom-arrows]="
                  isNumberIncrement || type === InputType.NUMBER_TEXT
                "
                [attr.data-edit-inline]="editInline"
                [attr.min]="minValue"
                [attr.data-transparent-background]="transparentBackground"
                [attr.inputmode]="
                  type === InputType.CURRENCY ? 'decimal' : keyboardType
                "
                [formControl]="control"
                (focus)="handleFocus()"
                (blur)="handleBlur()"
                (keyup)="handleKeyUp()"
                (wheel)="handleInputWheel()"
                (change)="handleOnChange()"
              />
            </ng-container>
            <ng-template #nonNumberInput>
              <input
                #nativeElement
                class="NativeElement"
                [ngClass]="{
                  HasIconStart: iconStart,
                  HasIconEnd: iconEnd,
                }"
                [attr.id]="id"
                [attr.type]="inputType"
                [attr.step]="type === InputType.CURRENCY ? 0.01 : null"
                [attr.placeholder]="
                  this.placeholders?.length > 0 &&
                  isStringControl(control) &&
                  control.value.length === 0
                    ? placeholder + '|'
                    : placeholder
                "
                [attr.readonly]="attrReadonly ? true : null"
                [attr.disabled]="attrDisabled"
                [attr.maxlength]="maxLength ? maxLength : null"
                [attr.data-align]="textAlign"
                [attr.data-text-color]="color"
                [attr.autocomplete]="autoComplete"
                [attr.data-size]="size"
                [attr.data-size-tablet]="sizeTablet"
                [attr.data-size-desktop]="sizeDesktop"
                [attr.data-left-icon-size]="leftIconSize"
                [attr.data-right-icon-size]="rightIconSize"
                [attr.aria-errormessage]="errorId"
                [attr.aria-invalid]="hasError && !isWarning"
                [attr.aria-label]="label"
                [attr.data-borderless]="borderless"
                [attr.data-borderlessMobile]="borderlessMobile"
                [attr.data-weight]="fontWeight"
                [attr.data-edit-inline-title-size]="
                  isText && editInlineTitleSize ? editInlineTitleSize : null
                "
                [attr.data-is-shadowed]="isShadowed"
                [attr.min]="minValue"
                [formControl]="control"
                [attr.data-transparent-background]="transparentBackground"
                [attr.data-edit-inline]="editInline"
                [attr.inputmode]="keyboardType"
                (focus)="handleFocus()"
                (blur)="handleBlur()"
                (keyup)="handleKeyUp()"
                (wheel)="handleInputWheel()"
                (change)="handleOnChange()"
              />
            </ng-template>
          </ng-template>
        </div>
        <fl-button
          *ngIf="iconEndClickable"
          class="InputButtonEnd"
          [flHideMobile]="compactDateInput"
          [label]="iconEndLabel"
          (click)="handleIconEndClick($event)"
        >
          <fl-icon
            *ngIf="iconEnd"
            [size]="rightIconSize"
            [color]="rightIconColor"
            [hoverColor]="HoverColor.PRIMARY"
            [name]="iconEnd"
          ></fl-icon>
        </fl-button>

        <fl-icon
          *ngIf="iconEnd && !iconEndClickable"
          class="IconElementEnd"
          [size]="rightIconSize"
          [color]="rightIconColor"
          [flHideMobile]="compactDateInput"
          [label]="iconEndLabel"
          [name]="iconEnd"
        ></fl-icon>

        <div
          class="InputLabel AfterLabel"
          *ngIf="afterLabel"
          [attr.data-size]="size"
        >
          <span class="LabelText"> {{ afterLabel }} </span>
        </div>
        <ng-content select="fl-button"></ng-content>
      </div>
      <label
        *ngIf="editInline"
        class="LabelTag"
        for="id"
        [ngClass]="{
          HasValue:
            (isStringControl(control) && control.value.length !== 0) ||
            (isNumberControl(control) && isDefined(control.value)),
          BeforeLabelSpace: beforeLabel,
          HideLabelTag: hideLabelTag,
          TitleInput: isText && editInlineTitleSize
        }"
        [attr.data-edit-inline-title-size]="
          isText && editInlineTitleSize ? editInlineTitleSize : null
        "
      >
        {{ labelTag }}
      </label>
      <fl-text
        *ngIf="maxCharacter !== undefined && !hasError"
        class="Counter"
        [class.IsFocus]="inputFocused"
        [color]="remainingCharCount >= 0 ? FontColor.DARK : FontColor.ERROR"
        [size]="TextSize.XXSMALL"
        [fontType]="FontType.PARAGRAPH"
      >
        <ng-container
          *ngIf="remainingCharCount >= 0"
          i18n="Input field characters left text"
        >
          {{ remainingCharCount }} characters left
        </ng-container>

        <ng-container
          *ngIf="remainingCharCount < 0"
          i18n="Input field characters over limit text"
        >
          {{ -remainingCharCount }} characters over limit
        </ng-container>
      </fl-text>
      <fl-button
        *ngIf="isNumberIncrement"
        i18n="Input increment button"
        class="InputIncrement"
        [color]="ButtonColor.CUSTOM"
        [disabled]="attrDisabled === true"
        [size]="buttonIncrementSize"
        [attr.data-invalid]="hasError && !isWarning"
        [attr.data-valid]="isValid"
        [attr.data-warning]="isWarning"
        [flMarginBottom]="hasError ? 'xxxsmall' : 'none'"
        (click)="handleAdd()"
      >
        <fl-text> + </fl-text>
      </fl-button>
    </div>
    <ng-template #dateRangeInline>
      <mat-calendar
        ngSkipHydration
        [selected]="inlineDateRangeValue"
        (selectedChange)="handleInlineDateRangeChange($event)"
        #inlineRangePicker
      >
      </mat-calendar>
    </ng-template>
    <fl-text
      *ngIf="hint && inputFocused && !hasError && !isValid"
      class="Hint"
      [size]="TextSize.XXSMALL"
    >
      {{ hint }}
    </fl-text>
    <fl-validation-error
      class="ValidationError"
      [id]="errorId"
      [attr.data-align]="align"
      [control]="control"
      [isWarning]="isWarning"
    >
    </fl-validation-error>
  `,
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [shakeAnimation],
})
export class InputComponent<T>
  implements AfterViewInit, OnChanges, OnInit, OnDestroy
{
  ButtonColor = ButtonColor;
  FontColor = FontColor;
  FontType = FontType;
  TextSize = TextSize;
  HoverColor = HoverColor;
  InputType = InputType;
  IconColor = IconColor;
  IconSize = IconSize;

  isDefined = isDefined;
  isNumber = isNumber;
  isString = isString;

  remainingCharCount = 0;
  isValid = false;
  isWarning = false;
  attrDisabled?: true;
  @RepetitiveSubscription()
  private errorSubscription?: Subscription;
  private autofocusSubscription?: Subscription;
  private dateRangeSubscription?: Subscription;
  private dateRangeControlSubscription?: Subscription;
  inputFocused = false;
  iconStartClickable = false;
  iconEndClickable = false;
  dateFormat: Promise<string>;
  isDateInput: boolean;
  isPasswordInput: boolean;
  showPassword = false;
  buttonIncrementSize: ButtonSize;
  errorId: string;

  PLACEHOLDER_ANIMATION_FULL_TEXT_DELAY = 1000;
  PLACEHOLDER_ANIMATION_TYPING_DELAY = 150;

  currentAnimatedPlaceholderIndex = 0;
  private placeholderAnimationTimeout?: Timer;

  // Only used for date range
  dateRangeGroup: FormGroup<{
    dateStartControl: FormControl<Date | undefined>;
    dateEndControl: FormControl<Date | undefined>;
  }>;

  // Only used for inline date range
  inlineDateRangeValue?: DateRange;

  @Input() editInline: InputEditInlineType;
  @Input() expanded = false;
  @Input() hideLabelTag = false;
  @Input() id?: string;
  @Input() attrReadonly?: true;
  @Input() control: FormControl<T>;
  /**
   * Not for general use. This is only for larger title inputs of type TEXT.
   * If you'd like to use this input, make sure you discuss with UI Eng
   */
  @Input() editInlineTitleSize?: InputEditInlineTitleSize;
  @Input() fontWeight: FontWeight = FontWeight.NORMAL;
  @Input() placeholder?: string;
  @Input() labelTag?: string;
  @Input() @HostBinding('attr.data-size') size: InputSize = InputSize.MID;
  @Input() sizeTablet: InputSize;
  @Input() sizeDesktop: InputSize;

  @Input() transparentBackground = false;

  @Input() isShadowed = false;

  // Not for general use. This is only for search.
  // If you'd like to use this input, make sure you discuss with UI Eng
  @Input() isExpandable = false;
  /**
   * Not for general use. This is only for Messaging search.
   * If you'd like to use this input, make sure you discuss with UI Eng
   */
  @Input() borderless = false;
  /**
   * Not for general use. This is only for browse mobile page.
   * If you'd like to use this input, make sure you discuss with UI Eng
   */
  @Input() borderlessMobile = false;
  /**
   * Not for general use. This is only for browse mobile page.
   * If you'd like to use this input, make sure you discuss with UI Eng.
   */
  @Input() iconMarginMobile = true;
  /**
   * Clears the inline date range picker's value. Only used for InputType.DATERANGE_INLINE.
   * Since inline usage of sat-calendar component doesn't have a binding input element,
   * we need to reset the date range picker's value manually when the form control's value gets reseted.
   */
  @Input() clearDateRange = false;
  // FIXME: T241170 - Remove the IsAny check once all forms are typed.
  // Currently used to defer fixing untyped FormControl.
  @Input() type: IsAny<T> extends true
    ? any
    : // Accepts `undefined` as a default value, but internally, it
    // should never set `control.value` to `undefined`
    [T] extends [number] | [number | undefined]
    ? InputType.NUMBER | InputType.NUMBER_INCREMENT
    : [T] extends [string]
    ?
        | InputType.CURRENCY
        | InputType.EMAIL
        | InputType.NUMBER_TEXT
        | InputType.PASSWORD
        | InputType.TEXT
        | InputType.SEARCH
    : [T] extends [Date | null]
    ? InputType.DATE
    : [T] extends [DateRange | null]
    ? InputType.DATERANGE | InputType.DATERANGE_INLINE
    : never;
  @Input() iconStart?: string;
  @Input() iconEnd?: string;
  @Input() iconStartLabel?: string;
  @Input() iconEndLabel?: string;
  @Input() leftIconSize = IconSize.MID;
  @Input() leftIconColor = IconColor.INHERIT;
  @Input() rightIconSize = IconSize.MID;
  @Input() rightIconColor = IconColor.INHERIT;
  @Input() beforeLabel = '';
  @Input() afterLabel = '';
  /**
   * Sets aria label for accessibility when there is no associated visible text
   */
  @Input() label?: string;
  @Input() maxLength?: number;
  @Input() maxCharacter?: number;
  @Input() textAlign: InputTextAlign = InputTextAlign.LEFT;
  /**
   * Improves UX for form fields like Usernames, Emails, Company Names, etc. Autocomplete defaults to 'on' but you can disable
   * it with 'off'. If you wish to use more specific tokens like 'given-name' and 'family-name', see the link below.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autoComplete#token_list_tokens
   */
  @Input() autoComplete: AutoCompleteHint = 'on';
  @Input() set disabled(value: boolean) {
    this.attrDisabled = value ? true : undefined;
    if (this.isDateInput || this.isPasswordInput) {
      this.iconEndClickable = !value;
    }
  }
  /**
   * Set how to horizontally align the number increment input
   */
  @Input() align = InputAlign.LEFT;

  /**
   * Force color white for transparent cases in light mode.
   */
  @Input() color = InputTextColor.DEFAULT;

  /**
   * Set a minimum value for number inputs
   */
  @Input() minValue?: number;

  // Allow input autofocus, not just dateInput
  @Input() autofocus = AutoFocusOption.NONE;

  /** First validation happens only on blur, and validates on keyup once an error exists */
  @HostBinding('attr.data-dynamic-validation')
  @Input()
  dynamicValidation?: boolean = false;

  /** Displays hint under input field upon focus */
  @Input() hint?: string;
  /** Highlight input field when date value is set */
  @Input() showDateSelectedState?: boolean = false;
  /** Shake input on blur event */
  @Input() shakeOnBlur = true;
  /** Immediately check state of input with validation */
  @Input() validationStateCheck = false;

  @Input() placeholders: readonly string[] = [];

  @Input() searchRoundCorners = true;

  /** Hints the browser what kind of virtual keyboard to display when the input is focused. */
  @Input() keyboardType?: KeyboardType;
  /**
   * This input only works on mobile when the type is `InputType.DATE`.
   * If true, the date icon will be hidden on mobile.
   */
  @Input() compactDateInput = false;

  @Output() iconStartClick = new EventEmitter<MouseEvent>();
  @Output() iconEndClick = new EventEmitter<MouseEvent>();
  @Output() onFocus = new EventEmitter<void>();
  @Output() onBlur = new EventEmitter<void>();

  @ViewChild('nativeElement') nativeElement: ElementRef<HTMLInputElement>;
  @ViewChild('nativeElement2') nativeElement2?: ElementRef<HTMLInputElement>;
  @ViewChild('inputContainer', { read: ElementRef })
  inputContainer: ElementRef<HTMLDivElement>;
  @ViewChild('picker') matDatepicker?: MatDatepicker<Date>;
  @ViewChild('inlineRangePicker') inlineRangePicker?: MatCalendar<Date>;

  shakeState = 'void';

  get controlType(): ControlType {
    switch (this.type) {
      case InputType.NUMBER:
      case InputType.NUMBER_INCREMENT:
        return ControlType.NUMBER;
      case InputType.DATE:
        return ControlType.DATE;
      case InputType.DATERANGE:
      case InputType.DATERANGE_INLINE:
        return ControlType.DATERANGE;
      case InputType.CURRENCY:
      case InputType.NUMBER_TEXT:
      default:
        return ControlType.STRING;
    }
  }

  get isNumberInput(): boolean {
    return [
      InputType.NUMBER,
      InputType.NUMBER_INCREMENT,
      InputType.CURRENCY,
      InputType.NUMBER_TEXT,
    ].includes(this.type);
  }

  get isNumberIncrement(): boolean {
    return this.type === InputType.NUMBER_INCREMENT;
  }

  get isText(): boolean {
    return this.type === InputType.TEXT;
  }

  get inputType(): InputType {
    if (this.type === InputType.PASSWORD) {
      return this.showPassword ? InputType.TEXT : InputType.PASSWORD;
    }

    if (this.isNumberIncrement || this.type === InputType.CURRENCY) {
      return InputType.NUMBER;
    }

    return this.type;
  }

  get hasError(): boolean {
    return this.control.invalid && (this.control.touched || this.control.dirty);
  }

  private autofocusSubject$ = new BehaviorSubject<boolean>(false);
  private afterViewInitSubject$ = new BehaviorSubject<boolean>(false);

  constructor(
    private cd: ChangeDetectorRef,
    private datefns: LocalizedDateFns,
    private focus: Focus,
    private timeUtils: TimeUtils,
    @Inject(PLATFORM_ID) private platformId: Object,

    /**
     * This should only be injected in UI tests
     * Tests are failing when run with other tests due to animationDone() callback not destroying datepicker overlay
     * https://github.com/angular/components/blob/d02cc6817cddee66303ba9242a33d94ace320ad1/src/material/datepicker/datepicker-base.ts#L658
     */
    @Optional()
    @Inject(TESTING_CLOSE_ANIMATION_DISABLED)
    private readonly testingDatepickerOpenCloseDisabled?: boolean,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('id' in changes || 'label' in changes) {
      const id = this.id ?? generateUniqueID();
      const labelId = this.label ? `-${this.label}` : '';
      this.errorId = `errorId-${id}${labelId}`;
    }

    if (this.control !== undefined && this.InputType === undefined) {
      // Throw an error since `InputType` is required to
      // ensure type correctness for `control`
      throw new Error('InputType must be specified.');
    }

    if (
      'type' in changes &&
      changes.type.previousValue !== changes.type.currentValue
    ) {
      this.isDateInput =
        this.type === InputType.DATE || this.type === InputType.DATERANGE;
      this.isPasswordInput = this.type === InputType.PASSWORD;

      this.formatCurrency();
    }

    if ('autofocus' in changes) {
      this.autofocusSubject$.next(this.autofocus !== AutoFocusOption.NONE);
    }

    if ('control' in changes || 'dynamicValidation' in changes) {
      if (this.errorSubscription) {
        this.errorSubscription.unsubscribe();
      }
      this.errorSubscription = this.control.statusChanges.subscribe(() => {
        if (
          this.control.invalid &&
          (this.control.touched || this.control.dirty)
        ) {
          this.validationStateCheck = true;
        }

        if (this.validationStateCheck && this.control.valid) {
          this.isValid = true;
        }

        if (this.validationStateCheck && this.control.invalid) {
          this.isValid = false;
          if (this.hasError && !this.isWarning && this.dynamicValidation) {
            this.shakeState = 'active';
          }
        }

        this.cd.markForCheck();
      });

      // This sets the control's updateOn value to `blur` if on dynamicValidation
      if (this.control && this.dynamicValidation) {
        this.control = Object.defineProperty(this.control, 'updateOn', {
          get: () => 'blur',
          configurable: true,
        });
      }
    }

    if ('size' in changes) {
      this.updateButtonIncrementSize();
    }

    if ('clearDateRange' in changes) {
      if (this.type === InputType.DATERANGE_INLINE && this.clearDateRange) {
        this.clearInlineDateRange();
      }
    }

    if ('placeholders' in changes && this.placeholders.length > 0) {
      if (isPlatformBrowser(this.platformId)) {
        this.placeholder = '';
        this.typePlaceholder(false);
      } else {
        [this.placeholder] = this.placeholders;
      }
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInitSubject$.next(true);
    this.updateCharCount();
  }

  ngOnInit(): void {
    this.dateFormat = this.datefns.dateFormat;

    // We are able to check for subscriptions to these inputs in order to infer
    // whether or not the icons should be clickable (displayed as buttons)
    this.iconStartClickable = this.iconStartClick.observed;
    this.iconEndClickable = this.iconEndClick.observed;

    if (this.isDateInput) {
      // force a calendar picker icon
      this.iconEnd = 'ui-calendar-v2';
      this.iconEndLabel = this.iconEndLabel ?? 'ui-calendar-v2';
      this.iconEndClickable = !this.attrDisabled;

      if (this.type === InputType.DATERANGE) {
        this.dateRangeGroup = new FormGroup({
          dateStartControl: new FormControl<Date | undefined>(undefined, {
            nonNullable: true,
          }),
          dateEndControl: new FormControl<Date | undefined>(undefined, {
            nonNullable: true,
          }),
        });

        // propagate manual changes on the combined public control to the internal daterange controls
        this.dateRangeControlSubscription = this.control.valueChanges.subscribe(
          value => {
            // Despite the `emitEvent: false` option being supplied, all of these events are
            // still trigger the dateRangeGroup valueChanges subscription ¯\_(ツ)_/¯
            if (!value) {
              this.dateRangeGroup?.reset(undefined, {
                emitEvent: false,
                onlySelf: true,
              });
              return;
            }

            // Not in the next if block due to prettier error
            if (!(value instanceof MatDateRange)) {
              return;
            }

            if (
              !(
                (value.start === null || value.start instanceof Date) &&
                (value.end === null || value.end instanceof Date)
              )
            ) {
              return;
            }

            if (
              value.start !==
              this.dateRangeGroup?.controls.dateStartControl.value
            ) {
              this.dateRangeGroup?.controls.dateStartControl.setValue(
                value.start,
                { emitEvent: false, onlySelf: true },
              );
            }

            if (
              value.end !== this.dateRangeGroup?.controls.dateEndControl.value
            ) {
              this.dateRangeGroup?.controls.dateEndControl.setValue(value.end, {
                emitEvent: false,
                onlySelf: true,
              });
            }
          },
        );

        // propagate changes on the internal daterange controls to the combined public control
        this.dateRangeSubscription = this.dateRangeGroup.valueChanges.subscribe(
          value => {
            if (
              this.type === InputType.DATERANGE &&
              this.isDateRangeControl(this.control) &&
              isDefined(value.dateStartControl) &&
              isDefined(value.dateEndControl)
            ) {
              this.control.markAsTouched();
              this.control.markAsDirty();

              this.control.setValue(
                new MatDateRange(value.dateStartControl, value.dateEndControl),
              );

              // set errors on control if dateRangeGroup controls are invalid
              if (this.dateRangeGroup?.controls.dateStartControl.errors) {
                this.control.setErrors({
                  incorrect: true,
                  ...this.dateRangeGroup.controls.dateStartControl.errors,
                });
              } else if (this.dateRangeGroup?.controls.dateEndControl.errors) {
                this.control.setErrors({
                  incorrect: true,
                  ...this.dateRangeGroup.controls.dateEndControl.errors,
                });
              }
            }
          },
        );
      }
    }

    if (this.isPasswordInput) {
      this.iconEnd = 'ui-hide-v2';
      this.iconEndClickable = !this.attrDisabled;
    }

    this.updateButtonIncrementSize();

    this.autofocusSubscription = combineLatest([
      this.autofocusSubject$.asObservable(),
      this.afterViewInitSubject$.asObservable(),
    ])
      .pipe(filter(([autofocus, afterViewInit]) => afterViewInit && autofocus))
      .subscribe(() => {
        if (!this.isDateInput) {
          this.handleAutoFocus();
        }
      });
  }

  // Match button size with input size
  private updateButtonIncrementSize(): undefined {
    if (!this.isNumberIncrement) {
      return;
    }

    switch (this.size) {
      case InputSize.SMALL:
        this.buttonIncrementSize = ButtonSize.SMALL;
        break;
      case InputSize.MID:
        this.buttonIncrementSize = ButtonSize.MID;
        break;
      case InputSize.LARGE:
        this.buttonIncrementSize = ButtonSize.LARGE;
        break;
      default:
        return undefined;
    }
  }

  ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.autofocusSubscription) {
      this.autofocusSubscription.unsubscribe();
    }

    if (this.dateRangeControlSubscription) {
      this.dateRangeControlSubscription.unsubscribe();
    }

    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }

    if (this.placeholderAnimationTimeout) {
      clearTimeout(this.placeholderAnimationTimeout);
    }
  }

  handleIconEndClick(e: MouseEvent): void {
    if (this.isDateInput) {
      // open the matDatePicker instead of emitting
      // it automatically closes when you click outside
      this.matDatepicker?.open();
      return;
    }

    if (this.isPasswordInput) {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.iconEnd = 'ui-show';
      } else {
        this.iconEnd = 'ui-hide-v2';
      }
      return;
    }
    this.iconEndClick.emit(e);
  }

  handleAutoFocus(): void {
    if (!this.inputFocused) {
      if (this.autofocus !== AutoFocusOption.NONE) {
        this.focus.focusElement(this.nativeElement, {
          allowMobile: this.autofocus === AutoFocusOption.ALWAYS,
        });
        this.inputFocused = true;
      }
    }
  }

  handleFocusDatePicker(element: string): void {
    if (
      !this.inputFocused &&
      !this.matDatepicker?.opened &&
      !this.testingDatepickerOpenCloseDisabled
    ) {
      this.matDatepicker?.open();
      // timeout to retain focus on input field after datepicker opens
      this.timeUtils.setTimeout(() => {
        if (element === 'nativeElement') {
          this.focus.focusElement(this.nativeElement, {
            allowMobile: this.autofocus === AutoFocusOption.ALWAYS,
          });
        } else if (this.nativeElement2) {
          this.focus.focusElement(this.nativeElement2, {
            allowMobile: this.autofocus === AutoFocusOption.ALWAYS,
          });
        }

        this.inputFocused = true;
        this.cd.markForCheck();
      });
    }
  }

  handleBlurDatePicker(): void {
    // Timeout to avoid re-triggering the focus conditions as
    // clicking the input causes the datepicker to open, which invokes a blur event
    // before it is re-focused programatically inside handleFocusDatePicker().
    this.timeUtils.setTimeout(() => {
      // Check if the input is still focused after the timeout to avoid incorrectly
      // overriding the inputFocused state.
      this.inputFocused =
        document.activeElement === this.nativeElement.nativeElement ||
        document.activeElement === this.nativeElement2?.nativeElement;
      this.cd.markForCheck();
    }, 200);
  }

  handleKeydown(): void {
    if (this.matDatepicker?.opened) {
      this.matDatepicker?.close();
    }
  }

  handleIconStartClick(e: MouseEvent): void {
    this.iconStartClick.emit(e);
  }

  // Prevents "scrolling" inside the input type number
  handleInputWheel(): void {
    if (
      (this.type === InputType.NUMBER || this.type === InputType.CURRENCY) &&
      this.inputFocused
    ) {
      this.nativeElement.nativeElement.blur();
    }
  }

  formatCurrency(): void {
    if (
      this.type === InputType.CURRENCY &&
      this.isStringControl(this.control) &&
      this.control.value
    ) {
      const currentVal: string = this.control.value;
      const currentValNum = parseFloat(currentVal);
      this.control.setValue(currentValNum.toFixed(2));
    }
  }

  handleFocus(): void {
    this.onFocus.emit();
    this.inputFocused = !this.inputFocused;

    if (this.dynamicValidation && this.hasError) {
      this.isWarning = true;
    }
  }

  handleBlur(): void {
    if (this.dynamicValidation) {
      this.validateField();
      this.isWarning = false;
    }

    this.onBlur.emit();
    this.inputFocused = false;
    this.formatCurrency();

    if (
      this.control.invalid &&
      (this.control.touched || this.control.dirty) &&
      this.shakeOnBlur
    ) {
      this.shakeState = 'active';
    }
  }

  handleOnChange(): void {
    if (this.type === InputType.CURRENCY || this.type === InputType.NUMBER) {
      this.handleDynamicValidation();
    }
  }

  handleKeyUp(): void {
    this.updateCharCount();
    this.handleDynamicValidation();
  }

  handleDynamicValidation(): void {
    // We want to only keep validating if there is already an existing error
    if (!this.dynamicValidation || !this.hasError) {
      return;
    }

    this.validateField();
  }

  shakeDone(): void {
    this.shakeState = 'void';
  }

  handleAdd(): void {
    if (this.isNumberIncrement && this.isNumberControl(this.control)) {
      let currentVal: number = this.control.value;
      currentVal = Number.isNaN(currentVal) ? 0 : currentVal;
      this.control.setValue(currentVal + 1);
      this.control.markAsTouched();
    }
  }

  handleMinus(): void {
    if (this.isNumberIncrement && this.isNumberControl(this.control)) {
      let currentVal: number = this.control.value;
      currentVal = Number.isNaN(currentVal) ? 0 : currentVal;
      if (isDefined(this.minValue) && this.minValue >= currentVal) {
        return;
      }
      this.control.setValue(currentVal - 1);
      this.control.markAsTouched();
    }
  }

  handleInlineDateRangeChange(date: Date): void {
    // material datepicker's inline calendar doesn't have a built-in control
    // and doesn't supply a DateRange type so we have to set it manually
    if (!date) {
      // date is only null when date control is cleared manually
      return;
    }

    if (
      this.inlineDateRangeValue &&
      this.inlineDateRangeValue.start &&
      !this.inlineDateRangeValue.end &&
      date >= this.inlineDateRangeValue.start
    ) {
      // start selected, end not selected and selected date is after start date
      this.inlineDateRangeValue = new MatDateRange(
        this.inlineDateRangeValue.start,
        date,
      );

      if (this.isDateRangeControl(this.control)) {
        this.control.setValue(this.inlineDateRangeValue);
      }
    } else {
      // neither or both selected or selected date is before start date
      this.inlineDateRangeValue = new MatDateRange(date, null);
    }
  }

  validateField(): void {
    const nativeElementValue = this.nativeElement.nativeElement.value;

    // Handle number and string, Date is not validated
    if (this.isNumberControl(this.control)) {
      this.control.setValue(parseFloat(nativeElementValue));
    } else if (this.isStringControl(this.control)) {
      this.control.setValue(nativeElementValue);
    }

    if (this.nativeElement.nativeElement.value !== '') {
      dirtyAndValidate(this.control);
    } else if (this.isNumberControl(this.control)) {
      this.control.reset(NaN);
    } else if (this.isStringControl(this.control)) {
      this.control.reset('');
    }
  }

  // We have to manually update the max character counter instead of getting
  // control.value.length because updateOn is 'blur' when dynamic validation is used
  updateCharCount(): void {
    if (this.maxCharacter) {
      this.remainingCharCount =
        this.maxCharacter - this.nativeElement.nativeElement.value.length;
    }
  }

  clearInlineDateRange(): void {
    if (this.inlineRangePicker) {
      this.inlineDateRangeValue = undefined;
      this.inlineRangePicker.selectedChange.emit(undefined);
    }
  }

  shouldDisableDecrementButton(): boolean {
    if (this.isNumberIncrement && this.isNumberControl(this.control)) {
      let currentVal: number = this.control.value;

      currentVal = Number.isNaN(currentVal) ? 0 : currentVal;
      return isDefined(this.minValue) && this.minValue >= currentVal;
    }

    return false;
  }

  isStringControl(control: AbstractControl): control is FormControl<string> {
    return this.controlType === ControlType.STRING;
  }

  isNumberControl(control: AbstractControl): control is FormControl<number> {
    return this.controlType === ControlType.NUMBER;
  }

  isDateControl(
    control: AbstractControl,
  ): control is FormControl<Date | undefined> {
    return this.controlType === ControlType.DATE;
  }

  isDateRangeControl(
    control: AbstractControl,
  ): control is FormControl<DateRange | undefined> {
    return this.controlType === ControlType.DATERANGE;
  }

  typePlaceholder(isDeletingText: boolean): void {
    const currentFullPlaceholderText =
      this.placeholders[this.currentAnimatedPlaceholderIndex];
    const words = currentFullPlaceholderText.split(' ');

    this.placeholder = words
      .slice(
        0,
        this.placeholder
          ? this.placeholder.split(' ').length + (isDeletingText ? -1 : 1)
          : 1,
      )
      .join(' ');

    let _isDeletingText = isDeletingText;
    if (this.placeholder === currentFullPlaceholderText && !isDeletingText) {
      _isDeletingText = true;
    } else if (this.placeholder === '' && isDeletingText) {
      _isDeletingText = false;
      this.currentAnimatedPlaceholderIndex =
        (this.currentAnimatedPlaceholderIndex + 1) % this.placeholders.length;
    }

    this.cd.markForCheck();

    this.placeholderAnimationTimeout = this.timeUtils.setTimeout(
      () => this.typePlaceholder(_isDeletingText),
      this.placeholder === currentFullPlaceholderText
        ? this.PLACEHOLDER_ANIMATION_FULL_TEXT_DELAY
        : this.PLACEHOLDER_ANIMATION_TYPING_DELAY,
    );
  }
}
