import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-accessibility',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Accessibility and Internationalisation
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-text
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'small'"
          >
            We connect over millions of users globally. They interact with our
            user interfaces through language. It is important to consider
            different cultures, languages and user disabilities when we design
            our elements by making it accessible.
          </fl-text>
          <div [flMarginBottom]="'large'">
            <fl-heading
              [headingType]="HeadingType.H2"
              [size]="TextSize.SMALL"
              [flMarginBottom]="'xsmall'"
            >
              Examples of things to consider
            </fl-heading>
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.INHERIT"
              [flMarginBottom]="'xxxsmall'"
            >
              Labels above fields
            </fl-heading>
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.CONTAINER"
              [flMarginBottom]="'small'"
            >
              Having labels above input field accommodates lengthy text
              especially when it gets translated into other languages. It also
              reduces the risk of overlapping text and breaking layouts.
            </fl-text>
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.INHERIT"
              [flMarginBottom]="'xxxsmall'"
            >
              Use of familiar icons
            </fl-heading>
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.CONTAINER"
              [flMarginBottom]="'small'"
            >
              Don’t give other meaning to universally recognizable icons such as
              save, help and delete.
            </fl-text>
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.INHERIT"
              [flMarginBottom]="'xxxsmall'"
            >
              Adapt elements that surround it
            </fl-heading>
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.CONTAINER"
              [flMarginBottom]="'small'"
            >
              Always keep in mind to view your user interface in a different
              language, to get an understanding of how the layout might change.
            </fl-text>
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.INHERIT"
              [flMarginBottom]="'xxxsmall'"
            >
              Currencies
            </fl-heading>
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.CONTAINER"
            >
              Consider how currency values would look in different currencies
              (eg. larger numbers, or different decimal places).
            </fl-text>
          </div>

          <div
            class="Section"
            [flMarginBottom]="'small'"
          >
            <fl-heading
              [headingType]="HeadingType.H2"
              [size]="TextSize.SMALL"
              [flMarginBottom]="'xsmall'"
            >
              Resources:
            </fl-heading>
            <fl-text
              [size]="TextSize.XSMALL"
              [fontType]="FontType.CONTAINER"
            >
              <fl-list
                class="List"
                [type]="ListItemType.UNORDERED"
                [flMarginBottom]="'small'"
              >
                <fl-list-item [flMarginBottom]="'xxxsmall'">
                  Color contrast checker
                </fl-list-item>
                <fl-list-item [flMarginBottom]="'xxxsmall'">
                  <fl-link
                    [size]="TextSize.INHERIT"
                    link="https://www.iso.org/iso-4217-currency-codes.html"
                    >International Standard for Currency Codes</fl-link
                  >
                </fl-list-item>
                <fl-list-item>
                  <fl-link
                    [size]="TextSize.INHERIT"
                    link="https://www.w3.org/TR/WCAG21/"
                    >WCAG (Web Content Accessibility Guideline)</fl-link
                  >
                </fl-list-item>
              </fl-list>
            </fl-text>
          </div>
        </fl-text>
      </fl-card>

      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Accessibility Testing
        </fl-heading>

        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'small'"
          >
            Familiarity with
            <fl-link
              [size]="TextSize.INHERIT"
              link="https://www.w3.org/TR/WCAG21/"
            >
              Web Content Accessibility Guideline (WCAG)</fl-link
            >
            will prove useful in building accessible websites so we recommend
            going through it. But to make things easier, we can leverage some
            tools to automate accessibility audits for us. For consistency's
            sake, we'll be using
            <fl-link
              [size]="TextSize.INHERIT"
              link="https://developers.google.com/web/tools/lighthouse"
            >
              Lighthouse
            </fl-link>
            which you can readily use in Google Chrome.
          </fl-text>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxxsmall'"
          >
            Manual Audit
          </fl-heading>
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'small'"
          >
            Run Lighthouse and make sure that new components or any updates to
            existing ones are passing. This can flag things like low-contrast
            text, form elements not having associated labels, etc. Do note that
            there are some areas which automated audits cannot test. We will
            cover those in the next sections below.
          </fl-text>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxxsmall'"
          >
            Keyboard Accessible
          </fl-heading>
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'xsmall'"
          >
            Interactive elements should be operable via keyboard only. It is
            important to make controls accessible for those who cannot or do not
            use a mouse.
          </fl-text>
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'xsmall'"
          >
            Things to look out for when reviewing keyboard accessibility:
          </fl-text>
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item>
              Interactive elements can be reached by tabbing through items in
              your page.
            </fl-list-item>
            <fl-list-item>
              The order in which elements are focused should make sense.
            </fl-list-item>
            <fl-list-item>
              Display a focus indicator (e.g focus ring) so users can see which
              element they are on.
            </fl-list-item>
            <fl-list-item>
              Avoid adding focus to non-interactive content.
            </fl-list-item>
            <fl-list-item>
              Complex controls such as dropdown items should be usable, i.e
              items inside it can be reached via keyboard.
            </fl-list-item>
            <fl-list-item>
              Everytime you add event handlers like click, consider keyboard
              access and appropriate mobile behavior. Does the action need to
              react to enter keypress and touch events? How will the mouse
              events like hover translate in mobile devices?
            </fl-list-item>
          </fl-list>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxxsmall'"
          >
            Perceivable
          </fl-heading>
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'xsmall'"
          >
            Your components and page as a whole should be accessible and
            presentable for assistive technologies such as screen readers. We
            use ChromeVox extension in Google Chrome to emulate a screen reader.
            In the absence of visual cues, it is important to add information
            like the role, status, relationship, sequence, etc.
          </fl-text>
          <fl-text
            [size]="TextSize.INHERIT"
            [fontType]="FontType.CONTAINER"
            [flMarginBottom]="'xsmall'"
          >
            Here are some general guidelines:
          </fl-text>
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item>
              <fl-text>
                Writing semantic markup goes a long way. They are already tagged
                with proper roles and assistive technologies already know how to
                navigate and interpret them.
              </fl-text>
            </fl-list-item>
            <fl-list-item>
              <fl-text>
                Images should have <span class="InlineSnippet">alt</span> text.
                It is a way for screen readers to describe the content of an
                image. An exception to this rule is for when an image is used
                primarily for presentation, usually they have an adjacent text
                that sufficiently describes it. In such cases, add an empty
                <span class="InlineSnippet">alt=""</span>
                string so they can be skipped by a screen reader.
              </fl-text>
            </fl-list-item>
            <fl-list-item>
              <fl-text>
                Custom controls should be labeled appropriately, e.g add
                appropriate <span class="InlineSnippet">role</span>,
                <span class="InlineSnippet">label</span>, etc. For more details
                on how to provide semantics for custom controls, see
                <fl-link
                  [size]="TextSize.INHERIT"
                  link="https://developers.google.com/web/fundamentals/accessibility/semantics-aria"
                >
                  Introduction to ARIA</fl-link
                >.
              </fl-text>
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibilityComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
