import { generateId } from '@freelancer/datastore/testing/helpers';
import type { RemovalReasonApi } from 'api-typings/messages/messages_types';
import {
  ContextTypeApi,
  FolderApi,
  PrivacyLevelApi,
  ThreadTypeApi,
} from 'api-typings/messages/messages_types';
import type { GenerateMessageOptions } from '../messages/message.seed';
import { generateMessage } from '../messages/message.seed';
import type {
  Thread,
  ThreadContext,
  ThreadContextType,
  ThreadType,
} from './threads.model';

export interface GenerateThreadsOptions {
  readonly id?: number;
  readonly userId: number;
  readonly otherMembers: readonly number[];
  readonly context: ThreadContext;
  readonly unreadMessageCount?: number;
  readonly message?: Omit<GenerateMessageOptions, 'threadId'>;
  readonly threadType?: ThreadType;
  readonly folder?: FolderApi;
  readonly isMuted?: boolean;
  readonly isBlocked?: boolean;
  readonly isStarred?: boolean;
  readonly writePrivacy?: PrivacyLevelApi;
  readonly inactiveMembers?: readonly number[];
  readonly loggedInUser?: number;
  readonly isFake?: boolean;
  readonly timeUpdated?: number;
}

export function generateThread({
  id = generateId(),
  userId,
  otherMembers,
  context,
  unreadMessageCount = 0,
  message,
  threadType = ThreadTypeApi.PRIVATE_CHAT,
  folder = FolderApi.INBOX,
  isMuted = false,
  isStarred = false,
  isBlocked = false,
  writePrivacy = PrivacyLevelApi.MEMBERS,
  inactiveMembers = [],
  loggedInUser = userId,
  isFake = false,
  timeUpdated = Date.now(),
}: GenerateThreadsOptions): Thread {
  const now = Date.now();

  return {
    context,
    contextType: context.type,
    folder,
    id,
    isBlocked,
    isFake,
    isStarred,
    isMuted,
    isRead: unreadMessageCount === 0,
    members: [...otherMembers, userId],
    inactiveMembers,
    message: message
      ? generateMessage({ ...message, threadId: id })
      : undefined,
    messageUnreadCount: unreadMessageCount,
    otherMembers: [...otherMembers, userId].filter(
      member => member !== loggedInUser,
    ),
    owner: userId,
    threadType:
      // FIXME: T267853 - This needs to take into account the various Team-related thread types
      // and context types as described in T36092
      otherMembers.length > 1 && context.type !== ContextTypeApi.TEAM
        ? ThreadTypeApi.GROUP
        : threadType ?? ThreadTypeApi.PRIVATE_CHAT,
    timeCreated: now,
    timeRead: now,
    timeUpdated: now,
    userReadTimes: {
      [userId]: now,
      [otherMembers[0]]: now,
    },
    writePrivacy,
  };
}

// --- Mixins ---
export function withContext(
  id: number,
  type: ThreadContextType,
): Pick<GenerateThreadsOptions, 'context'> {
  return {
    context: {
      id,
      type,
    },
  };
}

export function withMessage(
  message: string,
  fromUserId: number,
  removeReason?: RemovalReasonApi,
): Pick<GenerateThreadsOptions, 'message'> {
  return {
    message: {
      attachments: [],
      fromUserId,
      message,
      removeReason,
    },
  };
}

export function withNoneContext(): Pick<GenerateThreadsOptions, 'context'> {
  return {
    context: {
      type: ContextTypeApi.NONE,
    },
  };
}
