import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-faq',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          FAQ
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
          [flMarginBottom]="'small'"
        >
          <fl-text [size]="TextSize.INHERIT">
            Frequently asked questions
          </fl-text>
        </fl-text>
        <fl-list
          [clickable]="true"
          [expandable]="true"
        >
          <fl-list-item>
            <fl-list-item-header>
              <fl-text [size]="TextSize.XSMALL">
                How to align components side by side?
              </fl-text>
            </fl-list-item-header>
            <fl-list-item-body>
              <fl-text
                >We recommend using flex, you can practice using flex in
                <fl-link link="https://flexboxfroggy.com/"
                  >https://flexboxfroggy.com/</fl-link
                >.</fl-text
              >
            </fl-list-item-body>
          </fl-list-item>
          <fl-list-item>
            <fl-list-item-header>
              <fl-text [size]="TextSize.XSMALL">
                What's the difference between [fontType] and [weights] for text
                components?
              </fl-text>
            </fl-list-item-header>
            <fl-list-item-body>
              <fl-text
                >[fontType] is mainly for semantics that changes the html tag to
                a p, span, strong while [weight] is mainly for changing
                literally the font weight to normal or bold.</fl-text
              >
            </fl-list-item-body>
          </fl-list-item>
          <fl-list-item>
            <fl-list-item-header>
              <fl-text [size]="TextSize.XSMALL">
                How do we change components based on viewport
                (mobile/tablet/desktop)?
              </fl-text>
            </fl-list-item-header>
            <fl-list-item-body>
              <fl-text
                >As we are moving away from BreakpointObserver since it is not
                compatible with SSR, we encourage you to use CSS media queries
                or switching markups using our hide directives.</fl-text
              >
            </fl-list-item-body>
          </fl-list-item>
          <fl-list-item>
            <fl-list-item-header>
              <fl-text [size]="TextSize.XSMALL">
                Where can I optimize svg?
              </fl-text>
            </fl-list-item-header>
            <fl-list-item-body>
              <fl-text
                >You can optimize your svg assets in
                <fl-link link="https://jakearchibald.github.io/svgomg/"
                  >https://jakearchibald.github.io/svgomg/</fl-link
                >. Make sure that you turn off the "Remove viewBox" option in
                order for your svg to scale properly.</fl-text
              >
            </fl-list-item-body>
          </fl-list-item>
          <fl-list-item>
            <fl-list-item-header>
              <fl-text [size]="TextSize.XSMALL">
                Where can I optimize png images?
              </fl-text>
            </fl-list-item-header>
            <fl-list-item-body>
              <fl-text
                >You can optimize your png assets in
                <fl-link link="https://tinypng.com/"
                  >https://tinypng.com/</fl-link
                >.</fl-text
              >
            </fl-list-item-body>
          </fl-list-item>
        </fl-list>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FAQComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
