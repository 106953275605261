import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Clipboard } from '@freelancer/clipboard';
import { trackByValue } from '@freelancer/ui/helpers';
import { FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: `app-static-illustrations-list`,
  template: `
    <fl-text
      [weight]="FontWeight.BOLD"
      [size]="TextSize.MID"
      [flMarginBottom]="'small'"
    >
      {{ titleText }}
    </fl-text>
    <div class="IllustrationList">
      <div
        *ngFor="let source of sources; trackBy: trackByValue"
        class="IllustrationItem"
        (click)="copyToClipboard(source)"
      >
        <fl-picture
          [src]="source"
          alt="illustrations"
        >
        </fl-picture>
        <fl-text [size]="TextSize.XXXSMALL"> {{ source }} </fl-text>
      </div>
    </div>
  `,
  styleUrls: ['./illustrations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationsListComponent {
  FontWeight = FontWeight;
  TextSize = TextSize;

  trackByValue = trackByValue;

  @Input() titleText: string;
  @Input() sources: readonly string[];

  constructor(private clipboard: Clipboard) {}

  copyToClipboard(name: string): boolean | void {
    return this.clipboard.copy(
      `<fl-picture [src]="'${name}'" alt="illustrations"></fl-picture>`,
    );
  }
}
