import { generateId } from '@freelancer/datastore/testing/helpers';
import type { DashboardPoll } from './dashboard-polls.model';
import { PollAnswerType } from './dashboard-polls.model';

export type DashboardPollOptions = Readonly<Partial<DashboardPoll>>;

export function generateDashboardPollObject({
  id = generateId(),
  question = 'Is this a poll? (Y/N)',
  pollAnswerType = PollAnswerType.RADIO,
  options = [
    {
      id: generateId(),
      pollId: generateId(),
      answer: 'Yeah',
      isOtherField: false,
      orderId: 1,
    },
    {
      id: generateId(),
      pollId: generateId(),
      answer: 'Nope! no.',
      isOtherField: false,
      orderId: 2,
    },
  ],
  answered = false,
  hide = false,
  answer,
}: DashboardPollOptions = {}): DashboardPoll {
  return {
    id,
    question,
    options: options.map(o => ({ ...o, pollId: id })),
    answered,
    submitDate: id,
    pollAnswerType,
    hide,
    answer,
  };
}

// ----- Mixins -----
