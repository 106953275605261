import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { LayoutType } from '@freelancer/ui/page-layout';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-layouts',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Layouts
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          By default, the layout component maximizes the width of the container.
          Take note that you'll have to use fl-page-layout-single in order to
          use this single column layout.
        </fl-text>
        <fl-page-layout
          class="LayoutsContainer"
          [flMarginBottom]="'small'"
        >
          <fl-page-layout-single>
            <fl-card class="CardPrimary"> Content </fl-card>
          </fl-page-layout-single>
        </fl-page-layout>
        <app-code-block
          [code]="codeSnippetSingleColumnLayout"
          [language]="'xml'"
          [flMarginBottom]="'xlarge'"
        ></app-code-block>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xsmall'"
        >
          Split Type Layout
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          The split type layout uses the grid system with 8 columns for the
          primary content and 4 columns for secondary content. Take note that
          you'll have to use the fl-page-layout-primary and
          fl-page-layout-secondary to use the split type layout.
        </fl-text>
        <fl-page-layout
          class="LayoutsContainer"
          [layoutType]="LayoutType.SPLIT"
          [flMarginBottom]="'small'"
        >
          <fl-page-layout-primary class="LayoutsPrimary">
            <fl-card class="CardPrimary"> Primary Content </fl-card>
          </fl-page-layout-primary>
          <fl-page-layout-secondary class="LayoutsSecondary">
            <fl-card class="CardSecondary"> Secondary Content </fl-card>
          </fl-page-layout-secondary>
        </fl-page-layout>
        <app-code-block
          [code]="codeSnippetSplitLayout"
          [language]="'xml'"
        ></app-code-block>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss', './layouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutsComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  LayoutType = LayoutType;

  codeSnippetSingleColumnLayout = `
<fl-page-layout class="LayoutsContainer">
  <fl-page-layout-single>
    Content
  </fl-page-layout-single>
</fl-page-layout>
  `;

  codeSnippetSplitLayout = `
<fl-page-layout class="LayoutsContainer"
  [layoutType]="LayoutType.SPLIT"
>
  <fl-page-layout-primary>
    Primary Content
  </fl-page-layout-primary>
  <fl-page-layout-secondary>
    Secondary Content
  </fl-page-layout-secondary>
</fl-page-layout>
  `;
}
