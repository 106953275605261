import { isPlatformBrowser } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';
import { LongPressDirective } from '@freelancer/directives';
import type { Timer } from '@freelancer/time-utils';
import { TimeUtils } from '@freelancer/time-utils';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';
import { fromEvent, Subscription } from 'rxjs';
import type {
  CalloutTriggerComponentInterface,
  CalloutTriggerOpenInterface,
} from './callout.types';

@Component({
  selector: 'fl-callout-trigger',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./callout-trigger.component.scss'],
  hostDirectives: [
    {
      directive: LongPressDirective,
      outputs: ['longPressedMobile: handleLongPressedMobile'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalloutTriggerComponent
  implements CalloutTriggerComponentInterface, OnDestroy, OnInit
{
  @Output() trigger = new EventEmitter<MouseEvent>();
  @Output() open = new EventEmitter<CalloutTriggerOpenInterface>();
  @Output() close = new EventEmitter<void>();

  @Input() disableMobile = false;

  @Input() disabled = false;

  hover: boolean;
  mobileLongPress: boolean;
  isHoverDisabled = false;

  @HostBinding('attr.data-mobile-force-click') mobileForceClick: boolean;

  private scrollSubscription = new Subscription();

  /**
   * To prevent the callout to open when the user is scrolling, we need to check if the user is
   * still scrolling after 500ms. This timer is used to check if the user is still scrolling.
   *
   * @private
   */
  private scrollTimerId: Timer | undefined = undefined;
  private scrollPos: number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public containerRef: ViewContainerRef,
    private timeUtils: TimeUtils,
  ) {}

  /**
   * Reason for exposing the nativeElement is to allow the parent component to
   * pass the nativeElement to the callout content component to prevent emitting
   * outsideClick event after clicking the trigger.
   */
  get nativeElement(): HTMLElement {
    return this.containerRef.element.nativeElement as HTMLElement;
  }

  @HostListener('click', ['$event'])
  handleTriggerClick(event: MouseEvent): void {
    /**
     * Force open if hover is set to true
     * to support touch devices as well.
     *
     * Note: Clicking the trigger again or outside
     * the callout will close the callout.
     */
    if (this.disabled) {
      return;
    }

    if (this.isMobileViewport() && this.disableMobile) {
      return;
    }

    if (!this.isMobileViewport() && this.hover) {
      this.open.emit({ isPinnedOnClick: true });

      return;
    }

    if (
      this.isMobileViewport() &&
      this.mobileLongPress &&
      !this.mobileForceClick
    ) {
      return;
    }

    this.trigger.emit(event);
  }

  @HostListener('handleLongPressedMobile', ['$event'])
  handleLongPressedMobile(): void {
    if (!this.mobileLongPress) {
      return;
    }

    this.open.emit();
  }

  @HostListener('mouseenter')
  handleTriggerMouseover(): void {
    // Prevent this from running on touch device as it suppress
    // click event from triggering
    if (
      !this.isMobileViewport() &&
      !this.isTouchDevice() &&
      !this.isHoverDisabled
    ) {
      this.open.emit();
    }
  }

  @HostListener('mouseleave')
  handleTriggerMouseout(): void {
    if (!this.isMobileViewport() && !this.isTouchDevice()) {
      this.close.emit();
    }
  }

  getElementBoundingRect(): DOMRect {
    return this.nativeElement.getBoundingClientRect();
  }

  setOptions(options: {
    hover: boolean;
    mobileForceClick: boolean;
    mobileLongPress: boolean;
  }): void {
    this.hover = options.hover;
    this.mobileForceClick = options.mobileForceClick;
    this.mobileLongPress = options.mobileLongPress;
  }

  ngOnInit(): void {
    if (this.hover && isPlatformBrowser(this.platformId)) {
      this.disableHoverWhileScrolling();
    }
  }

  private isMobileViewport(): boolean {
    return window.innerWidth < parseInt(FreelancerBreakpointValues.TABLET, 10);
  }

  private isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  /**
   * Handles the scroll event by setting the scroll position, disabling hover, and delaying further checks for 500ms.
   * If the scroll position changes within that time, hover will remain disabled and the delay will reset.
   * Otherwise, hover will be enabled and wait for a new scroll event to fire.
   *
   * @returns {void}
   * @internal
   *
   * NOTE: Reason for defining this as an arrow function is to prevent the need to bind this to the function
   */
  private onScroll = (): void => {
    this.scrollPos = window.scrollY;
    this.isHoverDisabled = true;

    if (this.scrollTimerId !== undefined) {
      clearTimeout(this.scrollTimerId);
    }

    // Delay checking scroll position to prevent multiple scroll events
    this.scrollTimerId = this.timeUtils.setTimeout(() => {
      /**
       * Check if scroll position changed after 500ms
       * If true, keep hover disabled and check again after 500ms
       * Else, enable hover and wait for a new scroll event to fire
       */
      const scrollPosChanged = this.scrollPos !== window.scrollY;

      this.isHoverDisabled = scrollPosChanged;
      this.disableHoverWhileScrolling(scrollPosChanged);
      this.scrollTimerId = undefined;
    }, 500);
  };

  /**
   * Removes the scroll event listener.
   *
   * @returns {void}
   */
  private destroyScrollListener(): void {
    if (this.hover && isPlatformBrowser(this.platformId)) {
      this.scrollSubscription.unsubscribe();
    }
  }

  /**
   * Disables hover while the user is scrolling by removing the scroll event listener,
   * adding it back once with the "once" option to listen for the next scroll event,
   * and/or immediately invoking the onScroll handler if the scroll position has already changed.
   *
   * @param {boolean} [scrollCheckRetry=false] - Indicates whether to immediately invoke the onScroll handler.
   * @returns {void}
   */
  private disableHoverWhileScrolling(scrollCheckRetry: boolean = false): void {
    this.destroyScrollListener();

    if (!scrollCheckRetry) {
      // eslint-disable-next-line local-rules/no-scroll-listener
      this.scrollSubscription = fromEvent(window, 'scroll', {
        once: true,
      }).subscribe(this.onScroll);
    } else {
      this.onScroll();
    }
  }

  ngOnDestroy(): void {
    if (this.scrollTimerId !== undefined) {
      clearTimeout(this.scrollTimerId);
    }

    this.destroyScrollListener();
  }
}
