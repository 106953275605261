import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BannerAlertType } from '@freelancer/ui/banner-alert';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { TagStatusColor } from '@freelancer/ui/tag-status';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-getting-started',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <div [flMarginBottom]="'small'">
          <fl-tag-status [color]="TagStatusColor.BLUE">Beta</fl-tag-status>
        </div>

        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.XXLARGE"
          [flMarginBottom]="'mid'"
        >
          Motion System
        </fl-heading>

        <fl-text
          [size]="TextSize.MARKETING_SMALL"
          [flMarginBottom]="'xlarge'"
        >
          The motion system is a set of reusable transition patterns that can
          easily be applied to various UI elements. They help users understand
          the app and provide delightful experiences.
        </fl-text>

        <fl-hr [flMarginBottom]="'large'"></fl-hr>

        <div [flMarginBottom]="'xxlarge'">
          <fl-heading
            id="overview"
            [headingType]="HeadingType.H2"
            [size]="TextSize.LARGE"
            [weight]="HeadingWeight.NORMAL"
            [flMarginBottom]="'small'"
          >
            Overview
          </fl-heading>

          <fl-banner-alert
            bannerTitle="Before you start"
            [closeable]="false"
            [type]="BannerAlertType.WARNING"
            [flMarginBottom]="'large'"
          >
            Just because you can add animations, does not mean you should.
            Please consider impact on performance and user experience before
            using this library.
          </fl-banner-alert>

          <fl-text [flMarginBottom]="'small'">
            The library is built using
            <fl-link [link]="'https://angular.io/guide/animations'"
              >Angular Animations</fl-link
            >
            and contains exportable triggers and animations.
          </fl-text>

          <fl-text [flMarginBottom]="'xxsmall'">Sample usage:</fl-text>
          <fl-text [flMarginBottom]="'xxxsmall'">
            Triggers have predefined states and can be used directly into
            templates.
          </fl-text>
          <div
            class="Code"
            [flMarginBottom]="'small'"
          >
            {{ triggerCodeBlock }}
          </div>

          <fl-text [flMarginBottom]="'xxxsmall'">
            While animations can be used if custom triggers and states are
            needed.
          </fl-text>
          <div class="Code">{{ animationCodeBlock }}</div>
        </div>

        <fl-hr [flMarginBottom]="'large'"></fl-hr>

        <div [flMarginBottom]="'xxlarge'">
          <fl-heading
            id="easing-and-speed"
            [headingType]="HeadingType.H2"
            [size]="TextSize.LARGE"
            [weight]="HeadingWeight.NORMAL"
            [flMarginBottom]="'small'"
          >
            Easing and Speed
          </fl-heading>

          <fl-text [flMarginBottom]="'small'">
            Easing functions specify the acceleration or deceleration of motion
            over time. Like objects in the real world, movement rarely happens
            at a constant speed. As such, choosing the right easing function is
            crucial to produce animations that feel natural and fluid.
          </fl-text>

          <fl-text [flMarginBottom]="'small'">
            The animation functions in the library have their pre-defined easing
            function and duration, but can be overridden when needed.
          </fl-text>

          <fl-text [flMarginBottom]="'small'">
            Below are some of the CSS keywords which you can visualize and
            compare in
            <fl-link [link]="'https://easings.net/'">easings.net</fl-link>.
          </fl-text>

          <fl-text
            [flMarginBottom]="'xxxsmall'"
            [weight]="FontWeight.BOLD"
          >
            Ease-out
          </fl-text>
          <fl-text [flMarginBottom]="'xxsmall'">
            This animation begins quickly and slows down which gives a feeling
            of responsiveness. Useful for user actions that require instant
            reaction like menus and buttons.
          </fl-text>
          <fl-text [flMarginBottom]="'small'">
            Duration should generally be set around 200ms to 500ms.
          </fl-text>

          <fl-text
            [flMarginBottom]="'xxxsmall'"
            [weight]="FontWeight.BOLD"
          >
            Ease-in
          </fl-text>
          <fl-text [flMarginBottom]="'xxsmall'">
            Conversely, this animation starts slowly and accelerates. This might
            feel a little unnatural because of the sudden stop. Could be useful
            to animate elements that are moving out.
          </fl-text>
          <fl-text [flMarginBottom]="'small'">
            Duration should generally be set around 200ms to 500ms. This can be
            perceived as too slow when used with long durations.
          </fl-text>

          <fl-text
            [flMarginBottom]="'xxxsmall'"
            [weight]="FontWeight.BOLD"
          >
            Ease-in-out
          </fl-text>
          <fl-text [flMarginBottom]="'xxsmall'">
            This has a slow start, fast middle and slow end. Could provide a
            dramatic effect when used correctly.
          </fl-text>
          <fl-text [flMarginBottom]="'small'">
            Duration should generally be set around 300ms to 500ms. Like
            ease-in, this can be perceived as sluggish with long durations.
          </fl-text>

          <fl-text
            [flMarginBottom]="'xxxsmall'"
            [weight]="FontWeight.BOLD"
          >
            Linear
          </fl-text>
          <fl-text [flMarginBottom]="'mid'">
            This tends to feel robotic and unnatural. Generally speaking, this
            should be avoided.
          </fl-text>

          <fl-text>
            These are just general guidelines and the right easing and duration
            might differ between projects. In some cases, you might need to go
            completely custom by writing your equations (cubic-bezier).
            Experiment and choose what feels right and natural.
          </fl-text>
        </div>

        <fl-hr [flMarginBottom]="'large'"></fl-hr>

        <div>
          <fl-heading
            id="performance"
            [headingType]="HeadingType.H2"
            [size]="TextSize.LARGE"
            [weight]="HeadingWeight.NORMAL"
            [flMarginBottom]="'small'"
          >
            Performance
          </fl-heading>

          <fl-text [flMarginBottom]="'small'">
            Animations are not cheap. Even when optimized, they still have
            computational costs, and might lead to jarring experience.
          </fl-text>

          <fl-text
            [flMarginBottom]="'xxxsmall'"
            [weight]="FontWeight.BOLD"
          >
            Animate the right property
          </fl-text>
          <fl-text [flMarginBottom]="'xxsmall'">
            When writing animations, either in CSS or JS, use
            <code>opacity</code> or <code>transform</code> properties whenever
            possible. In fact, try to limit it to those properties.
          </fl-text>
          <fl-text [flMarginBottom]="'small'">
            Some properties are more expensive to animate and can cause a
            "reflow" avalanche. See
            <fl-link [link]="'https://csstriggers.com/'">CSS triggers</fl-link>
            for more details.
          </fl-text>

          <!--
            TODO: This section needs more work. Add some general guidelines
            on how to check performance on the consumer side, how to check
            and keep frames at 60fps, common gotchas and caveats.
          -->
        </div>
      </fl-card>
    </div>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './getting-started.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GettingStartedComponent {
  BannerAlertType = BannerAlertType;
  FontColor = FontColor;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  TagStatusColor = TagStatusColor;
  TextSize = TextSize;

  // Uglified so rendered text is properly indented
  triggerCodeBlock = `
<div @fadeInOutAnimation>...</div>
`;

  animationCodeBlock = `
<div @myCustomAnimation>...</div>

trigger('myCustomAnimation', [
  transition('* => active', [useAnimation(fadeInOut)]),
]);

`;
}
