export enum SelectSize {
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

// FIXME: T241170 - Remove the default type once all SelectItem are
// typed everywhere
export interface SelectItem<T = string | number | object> {
  value: T;
  displayText: string;
  disabled?: boolean;
  isInvalid?: boolean;
}

// FIXME: T241170 - Remove the default type once all SelectItem are
// typed everywhere
export interface SelectGroups<T = string | number | object> {
  groupName: string;
  options: SelectItem<T>[];
}

// FIXME: T241170 - Remove the default type once all SelectItem are
// typed everywhere
export type SelectAcceptedType<T = string | number | object> =
  | string
  | SelectItem<T>
  | SelectGroups<T>;

export type SelectTextColor = 'foreground' | 'white';
