import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ContainerSize } from '@freelancer/ui/container';
import { LayoutMinHeight, LayoutType } from './page-layout.types';

@Component({
  selector: 'fl-page-layout-single',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutSingleComponent {}

@Component({
  selector: 'fl-page-layout-primary',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutPrimaryComponent {}

@Component({
  selector: 'fl-page-layout-secondary',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutSecondaryComponent {}

@Component({
  selector: 'fl-page-layout',
  template: `
    <fl-container [size]="pageSize">
      <div
        class="Split"
        *ngIf="layoutType === LayoutType.SPLIT"
      >
        <ng-content select="fl-page-layout-primary"></ng-content>
        <ng-content select="fl-page-layout-secondary"></ng-content>
      </div>

      <ng-content
        *ngIf="layoutType === LayoutType.SINGLE"
        select="fl-page-layout-single"
      ></ng-content>
    </fl-container>
  `,
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  LayoutMinHeight = LayoutMinHeight;
  LayoutType = LayoutType;

  @Input() layoutType = LayoutType.SINGLE;
  @HostBinding('attr.data-minheight')
  @Input()
  layoutMinHeight = LayoutMinHeight.FULL;
  @Input() pageSize = ContainerSize.DESKTOP_LARGE;
}
