import type { HtmlElement } from '@freelancer/html-renderer';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { HtmlElementType } from '@freelancer/html-renderer/html-elements.model';
import { assertNever } from '@freelancer/utils';
import { phpSkill } from '../skills';
import type {
  HirePageDetails,
  HirePageDetailsResult,
  SeoFaq,
  SeoSemArticle,
  SimilarSkills,
  SkillStats,
  SkillWorker,
} from './hire-page-details.model';

export enum HirePageDetailsResultObjectSkill {
  PHP = 'php',
  INCOMPLETE = 'incomplete',
}

export interface GenerateHirePageDetailsOptions {
  readonly id?: string;
  readonly details?: HirePageDetails;
}

export function generateHirePageDetailsObject({
  details = phpHirePageDetailsResult(),
}: GenerateHirePageDetailsOptions): HirePageDetailsResult {
  return {
    id: details.id,
    details,
  };
}

export function getHirePageDetailsResultObject(
  hirePageDetailsSkill: HirePageDetailsResultObjectSkill,
  overwrites: {
    readonly id?: string;
    readonly seoUrl?: string;
    readonly name?: string;
    readonly header?: string;
    readonly subheader?: string;
    readonly seoTextHeader?: string;
    readonly seoText?: readonly HtmlElement[];
    readonly skillDescription?: readonly HtmlElement[];
    readonly serviceDescription?: readonly HtmlElement[];
    readonly footer?: string;
    readonly metaTitle?: string;
    readonly metaDescription?: string;
    readonly excludeAll?: boolean;
    readonly articles?: readonly SeoSemArticle[];
    readonly similarSkills?: readonly SimilarSkills[];
    readonly categoryId?: number;
    readonly faq?: readonly SeoFaq[];
    readonly worker?: SkillWorker;
    readonly skillStats?: SkillStats;
  } = {},
): HirePageDetails {
  let details;
  switch (hirePageDetailsSkill) {
    case HirePageDetailsResultObjectSkill.PHP:
      details = phpHirePageDetailsResult();
      break;
    case HirePageDetailsResultObjectSkill.INCOMPLETE:
      details = incompleteHirePageDetailsResult();
      break;
    default:
      return assertNever(hirePageDetailsSkill);
  }

  return { ...details, ...overwrites };
}

export function phpHirePageDetailsResult(): HirePageDetails {
  const php = phpSkill();

  return {
    id: 'php',
    name: php.name,
    header: 'a PHP Developer',
    subheader:
      'From a simple code/script fix for $10 to freelance PHP programming for larger jobs.',
    seoUrl: php.seoUrl,
    seoTextHeader: 'a PHP Developer',
    footer: 'PHP Developers',
    metaTitle: 'Hire the best PHP Developers in October 2022',
    metaDescription:
      "Hire top PHP Developers from the world's largest marketplace of 31.5m freelancers. Post your job online for free & receive quotes in seconds.",
    excludeAll: false,
    categoryId: 6,
    worker: {
      singular: 'PHP Developer',
      plural: 'PHP Developers',
    },
    skillStats: {
      averageRating: 4.99,
      reviewCount: 11_504,
    },
    similarSkills: createSimilarSkillsArray([
      'Scrum Development',
      'HTML',
      'Visual Basic',
    ]),
    skillDescription: [
      {
        type: HtmlElementType.PARAGRAPH,
        children: [
          {
            type: HtmlElementType.TEXT,
            value: 'Test Skill Description',
          },
        ],
      },
    ],
    serviceDescription: [
      {
        type: HtmlElementType.PARAGRAPH,
        children: [
          {
            type: HtmlElementType.TEXT,
            value:
              'On Freelancer.com you can hire php developers to build a website, online store, software, maintain existing code, migrate to a new platform...and more!',
          },
        ],
      },
    ],
    articles: [
      {
        id: 64_349,
        title: 'Web development',
        description:
          'Want to take web development into your own hands? In this post we introduce you to the powerfully capable and easy to learn language, Python',
        url: '/articles/web-development',
        readTime: '11 min read',
        coverPhoto: 'assets-ui-tests/portfolio/thumb-1.jpg',
      },
    ],
    faq: [
      {
        question: 'What does a Website Designer do?',
        answer:
          "A website designer's job is to create the look and feel of a website. This includes color schemes, typography, imagery, layout, and overall graphical appearance. A good website designer uses an array of design tools and techniques to create a design that is aesthetically pleasing to the eye, is easy for users to navigate and meets business goals.",
      },
    ],
    seoText: [
      {
        type: HtmlElementType.PARAGRAPH,
        children: [
          {
            type: HtmlElementType.TEXT,
            value:
              'PHP is a recursive acronym which stands for Hypertext Preprocessor. PHP is essentially a coding or scripting language which can be utilized in diverse ',
          },
          {
            type: HtmlElementType.LINK,
            url: 'https://www.freelancer.com.au/website-developers',
            rel: 'nofollow noreferrer',
            children: [
              {
                type: HtmlElementType.TEXT,
                value: 'web development',
              },
            ],
          },
          {
            type: HtmlElementType.TEXT,
            value: ' and software based applications.',
          },
        ],
      },
    ],
    seoTextReadTimeInMin: 3,
  };
}

export function incompleteHirePageDetailsResult(): HirePageDetails {
  // does not contain description, profile blade, and ratings
  return {
    id: 'dot-1test',
    seoUrl: 'dot-1test',
    name: '.1tester',
    header: 'a .1test',
    seoTextHeader: 'a .1test',
    seoText: [],
    skillDescription: [],
    serviceDescription: [],
    footer: '.1tests',
    metaTitle: 'Hire the best .1tests in October 2022',
    metaDescription:
      "Hire top .1tests from the world's largest marketplace of 31.5m freelancers. Post your job online for free & receive quotes in seconds.",
    excludeAll: false,
    articles: [],
    similarSkills: createSimilarSkillsArray(['Solaris', 'Zendesk', 'CubeCart']),
    categoryId: 6,
    faq: [
      {
        question: 'hiiii???',
        answer: 'helloooo',
      },
    ],
    worker: {
      singular: '.1test',
      plural: '.1tests',
    },
  };
}

export function createSimilarSkillsArray(
  skills: readonly string[],
): readonly SimilarSkills[] {
  // Approximation for real SimilarSkills data
  return skills.map(skill => ({
    name: skill,
    seoUrl: skill.toLowerCase().replace(' ', '-'),
  }));
}
