import { isPlatformServer } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserAgent } from '@freelancer/user-agent';
import { Subscription, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

interface AnchorRef {
  name: string;
  el: HTMLElement;
  cleanup?(): void;
}

type Anchors = {
  [key in string]: AnchorRef;
};

interface AnchorScrollOptions {
  name: string;
  onlyIfNeeded?: boolean;
  behavior?: ScrollBehavior;
}

@Injectable({
  providedIn: 'root',
})
export class AnchorScroll implements OnDestroy {
  private anchors: Anchors = {};
  private subscriptions = new Subscription();

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private errorHandler: ErrorHandler,
    private ua: UserAgent,
  ) {}

  /**
   * Scroll to an anchor.
   *
   * Returns a Promise that resolves when the animation is completed
   * Use that to show a loading state while the animation is running to reduce
   * stutter & improve the UX.
   */
  scrollTo(
    anchorNameOrOptions: string | AnchorScrollOptions,
  ): Promise<undefined> {
    const name =
      typeof anchorNameOrOptions !== 'string'
        ? anchorNameOrOptions.name
        : anchorNameOrOptions;

    const anchor = this.anchors[name];
    const onlyIfNeeded =
      typeof anchorNameOrOptions !== 'string'
        ? anchorNameOrOptions.onlyIfNeeded
        : false;
    const scrollBehavior =
      typeof anchorNameOrOptions !== 'string' && anchorNameOrOptions.behavior
        ? anchorNameOrOptions.behavior
        : 'smooth';

    if (isPlatformServer(this.platformId)) {
      throw new Error(
        `scrollTo() cannot be called on the server. If you want to scroll on page load, put a fragment (#${name}) in the link URL instead.`,
      );
    }

    if (!anchor) {
      this.errorHandler.handleError(
        new Error(`Anchor '${name}' does not exist`),
      );
      return Promise.resolve(undefined);
    }
    if (anchor.cleanup) {
      anchor.cleanup();
    }

    return new Promise(resolve => {
      const bounding = anchor.el.getBoundingClientRect();
      // Don't scroll if onlyIfNeeded is true & the anchor is already in the viewport
      if (
        onlyIfNeeded &&
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= window.innerWidth &&
        bounding.bottom <= window.innerHeight
      ) {
        setTimeout(() => {
          resolve(undefined);
        });
      } else {
        // Is the browser supporting passive events?
        let supportsPassive = false;
        try {
          const opts = Object.defineProperty({}, 'passive', {
            get() {
              supportsPassive = true;
            },
          });
          const subscription = fromEvent(
            window,
            'testPassive',
            opts,
          ).subscribe();
          subscription.unsubscribe();
        } catch (e: any) {
          // ignore
        }

        // On some devices/browsers the anchor is not in the viewport
        // after scrolling due to sub-pixel rounding issues.
        // This ensures the IntersectionObserver will always fire.
        const observerOptions = {
          rootMargin: '1px',
        };
        // Detect when top of the list has been reached
        const observer = new IntersectionObserver(changes => {
          if (changes[0].isIntersecting) {
            if (anchor.cleanup) {
              anchor.cleanup();
            }
          }
        }, observerOptions);

        observer.observe(anchor.el);
        // Called when the animation completes
        const cleanup = (): void => {
          if (observer) {
            observer.disconnect();
          }
          resolve(undefined);
          anchor.cleanup = undefined;
        };
        anchor.cleanup = cleanup;
        // Detect if the animation is interrupted
        this.subscriptions.add(
          fromEvent(
            document,
            'touchstart',
            supportsPassive ? { passive: true } : { passive: false },
          )
            .pipe(take(1))
            .subscribe(cleanup),
        );
        this.subscriptions.add(
          fromEvent(
            document,
            'wheel',
            supportsPassive ? { passive: true } : { passive: false },
          )
            .pipe(take(1))
            .subscribe(cleanup),
        );
        // Scroll the the anchor
        setTimeout(() => {
          if (
            (this.ua.getUserAgent().getBrowser().version || '').startsWith('79')
          ) {
            // TODO: T267853 - remove that when Chrome 79 has retired.
            // https://bugs.chromium.org/p/chromium/issues/detail?id=1036378
            // https://bugs.chromium.org/p/chromium/issues/detail?id=1038039
            // Native smooth scrolling is broken in certain scenarios in Chrome 79,
            // fixed in 80.
            // This catches Edge/Chromnium and other Blink-based browser on purpose
            // as the issue is in Blink.
            // This does the trick as Scroll-behavior is not yet supported for
            // Element.scrollIntoView() in Chrome, i.e. the polyfill will take
            // over.
            anchor.el.scrollIntoView({
              behavior: scrollBehavior,
            });
          } else {
            // In theory this could just be scrollIntoView(), but Scroll-behavior
            // is not yet supported for Element.scrollIntoView() in Chrome.
            window.scrollTo({
              top: anchor.el.getBoundingClientRect().top + window.scrollY,
              behavior: scrollBehavior,
            });
          }
        });
      }
    });
  }

  // PRIVATE
  // Used by the fl-anchor-scroll component to register a new anchor
  registerAnchor(name: string, el: HTMLElement): void {
    if (this.anchors[name]) {
      this.errorHandler.handleError(
        new Error(`Anchor '${name}' is already registered`),
      );
      return;
    }
    this.anchors[name] = { name, el };
  }

  // PRIVATE
  // Used by the fl-anchor-scroll component to unregister an anchor
  unregisterAnchor(name: string): void {
    const anchor = this.anchors[name];
    if (!anchor) {
      this.errorHandler.handleError(
        new Error(`Anchor '${name}' does not exist`),
      );
      return;
    }
    if (anchor.cleanup) {
      anchor.cleanup();
    }
    delete this.anchors[name];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
