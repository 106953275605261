import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import type { LocalStorageTypes } from '@freelancer/local-storage';
import { LocalStorage } from '@freelancer/local-storage';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { FlexAlign, FlexDirection, FlexJustify } from '@freelancer/ui/flex';
import { HeadingType } from '@freelancer/ui/heading';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { RibbonComponent } from '@freelancer/ui/ribbon';
import { TextSize } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import type { Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  CardBorder,
  CardBorderRadius,
  CardHeaderButton,
  CardLevel,
  CardSize,
} from './card.types';

@Component({
  selector: 'fl-card-header-title',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderTitleComponent {}

@Component({
  selector: 'fl-card-header-right',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderRightComponent {}

@Component({
  selector: 'fl-card-header-secondary',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderSecondaryComponent {}

@Component({
  selector: 'fl-card',
  template: `
    <div
      *ngIf="(localStorageflCardId && finishedLoading) || !localStorageflCardId"
      class="CardContainer"
      [ngClass]="{ DisableFullHeight: disableFullHeight }"
      [attr.data-closeable]="closeable"
    >
      <fl-icon
        *ngIf="closeable && !ribbon"
        class="CardCloseIcon"
        i18n-label="Card close icon label"
        label="Close card"
        [name]="'ui-close'"
        [size]="IconSize.SMALL"
        (click)="handleCloseClick()"
      ></fl-icon>
      <div
        class="CardHeader"
        *ngIf="cardHeaderTitle || cardHeaderButton || cardHeaderSecondary"
        [attr.data-closeable]="closeable"
        [attr.data-size]="size"
        [flHideMobile]="hideHeaderMobile"
        [flShowMobile]="showHeaderMobile"
        (click)="handleTitleClick()"
      >
        <div
          class="CardHeaderInner"
          *ngIf="cardHeaderTitle || cardHeaderButton"
          [attr.data-size]="size"
          [attr.data-button-available]="cardHeaderButton && true"
        >
          <fl-heading
            class="CardHeading"
            [headingType]="HeadingType.H2"
            [size]="size == CardSize.MID ? TextSize.MID : TextSize.SMALL"
          >
            <ng-content select="fl-card-header-title"></ng-content>
          </fl-heading>
          <div
            class="CardHeaderRight"
            *ngIf="cardHeaderRight || cardHeaderButton"
          >
            <fl-button
              *ngIf="cardHeaderButton"
              [color]="
                cardHeaderButton.color
                  ? cardHeaderButton.color
                  : ButtonColor.SECONDARY
              "
              [size]="size == CardSize.MID ? ButtonSize.SMALL : ButtonSize.MINI"
              [link]="cardHeaderButton.link"
              [linkActive]="cardHeaderButton.linkActive"
              [fragment]="cardHeaderButton.fragment"
              [queryParams]="cardHeaderButton.queryParams"
              [busy]="cardHeaderButton.busy"
              [linkActiveOptions]="cardHeaderButton.linkActiveOptions"
              [flMarginRight]="
                closeable && !cardHeaderRight ? 'xsmall' : 'none'
              "
              [flMarginRightTablet]="
                closeable && !cardHeaderRight ? 'xxsmall' : 'none'
              "
              (click)="handleButtonClick()"
            >
              {{ cardHeaderButton.text }}
            </fl-button>
            <ng-content select="fl-card-header-right"></ng-content>
          </div>
          <fl-icon
            class="ExpandIcon"
            *ngIf="expandable"
            [ngClass]="{ IsExpanded: expanded }"
            [name]="'ui-chevron-down'"
            [size]="IconSize.SMALL"
          >
          </fl-icon>
        </div>
        <ng-content select="fl-card-header-secondary"></ng-content>
      </div>

      <div
        class="CardBody"
        [attr.data-flex-direction]="flexDirection"
        [attr.data-flex-direction-tablet]="flexDirectionTablet"
        [attr.data-flex-direction-desktop]="flexDirectionDesktop"
        [attr.data-flex-align]="flexAlign"
        [attr.data-flex-justify]="flexJustify"
        [attr.data-size]="size"
        [attr.data-closeable]="closeable"
        [attr.data-edge-to-edge]="edgeToEdge"
        [attr.data-ribbon]="ribbon ? true : false"
        [class.IsHidden]="expandable && !expanded"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnDestroy {
  @HostBinding('attr.data-disable-boxshadow')
  @Input()
  disableBoxshadow = false;

  /** Forces the card to have a full 100% width of its parent container */
  @HostBinding('class.MaxContent')
  @Input()
  maxContent = true;

  /** Defines the level of the box shadow of the card */
  @HostBinding('attr.data-level')
  @Input()
  level = CardLevel.PRIMARY;

  /** Defines the level of the box shadow of the card */
  @HostBinding('attr.data-border')
  @Input()
  border = CardBorder.NONE;

  /** Makes the card appear to be clickable */
  @HostBinding('class.CardClickable')
  @Input()
  clickable = false;

  @Input() flexDirection: FlexDirection = 'column';
  @Input() flexDirectionTablet?: FlexDirection;
  @Input() flexDirectionDesktop?: FlexDirection;

  @Input() flexAlign: FlexAlign;
  @Input() flexJustify: FlexJustify;

  /** Makes the card expandable */
  /** Please be careful when handle click event if you have clickable and expandable enabled at the same time.
   * It might conflict if the user clicks the card header */
  @Input() expandable = false;

  /** Allow user to specify if they want the card to initally render in expanded state or not */
  @Input() initialExpandedState = false;

  @HostBinding('attr.data-radius')
  @Input()
  borderRadius = CardBorderRadius.MID;

  @Input() cardHeaderButton?: CardHeaderButton;

  /** Removes padding inside the card component */
  @Input() edgeToEdge = false;

  /** Defines the padding level inside the card */
  @Input() size = CardSize.MID;

  /** Local storage reference to allow expanded state to be tracked */
  @Input() localStorageflCardId?: string;

  /** Allow user to close card */
  /** Not available if ribbon is present */
  @Input() closeable = false;

  @HostBinding('class.IsHidden') cardHidden = false;

  @Input() hideHeaderMobile = false;

  @Input() showHeaderMobile = false;

  /** Disable height 100% so the radio button on the radio card can be centered*/
  @Input() disableFullHeight = false;

  @Output() closeClicked = new EventEmitter<void>();
  @Output() buttonClicked = new EventEmitter<void>();

  @ContentChild(CardHeaderTitleComponent)
  cardHeaderTitle: CardHeaderTitleComponent;
  @ContentChild(CardHeaderRightComponent)
  cardHeaderRight: CardHeaderRightComponent;
  @ContentChild(CardHeaderSecondaryComponent)
  cardHeaderSecondary: CardHeaderSecondaryComponent;
  @ContentChild(RibbonComponent) ribbon: RibbonComponent;

  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  CardSize = CardSize;
  TextSize = TextSize;
  HeadingType = HeadingType;
  IconColor = IconColor;
  IconSize = IconSize;
  expanded: boolean;
  expandedLocalStorageState:
    | LocalStorageTypes['flCardsExpandedStatus']
    | undefined;
  // if we are tracking expanded state, this ensures we wait for localStorage expanded state
  // to load before rendering the card
  finishedLoading = false;

  private isExpandedSubscription?: Subscription;

  constructor(
    private localStorage: LocalStorage,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.expandable && this.localStorageflCardId) {
      const cardId = this.localStorageflCardId;

      const isExpanded$ = this.localStorage.get('flCardsExpandedStatus').pipe(
        tap(state => {
          // get the object containing all expanded states for any tracked fl-cards in the webapp, so we can add the state of
          // this card to the object later, whenever the expand chevron is clicked
          if (state) {
            this.expandedLocalStorageState = state;
          } else {
            this.expandedLocalStorageState = {};
          }
        }),
        map(flCardsExpandedStates =>
          !flCardsExpandedStates || !isDefined(flCardsExpandedStates[cardId])
            ? this.initialExpandedState
            : flCardsExpandedStates[cardId],
        ),
        distinctUntilChanged(),
      );

      this.isExpandedSubscription = isExpanded$.subscribe(isExpanded => {
        this.expanded = isExpanded;
        this.finishedLoading = true;
        this.cdRef.markForCheck();
      });
    } else {
      this.expanded = this.initialExpandedState;
    }
  }

  handleButtonClick(): void {
    this.buttonClicked.emit();
  }

  handleTitleClick(): Promise<void> | undefined {
    if (!this.expandable) {
      return;
    }
    // update local storage, inserting new expanded state of this card
    // into object holding all cards states
    if (this.expandable && this.localStorageflCardId) {
      const newLocalStorageState = {
        ...this.expandedLocalStorageState,
        [this.localStorageflCardId]: !this.expanded,
      };
      return this.localStorage.set(
        'flCardsExpandedStatus',
        newLocalStorageState,
      );
    }
    this.expanded = !this.expanded;
  }

  handleCloseClick(): void {
    this.cardHidden = true;
    this.closeClicked.emit();
  }

  ngOnDestroy(): void {
    this.isExpandedSubscription?.unsubscribe();
  }
}
