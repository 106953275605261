import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { FontType, TextSize } from '@freelancer/ui/text';

interface SpacingGuide {
  enum: string;
  value: string;
  useCase: string;
}

@Component({
  selector: 'app-static-spacing',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Spacing
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Whitespace is referred to as negative space or empty space in a page.
          For the key freelancer interface we prefer a compact UI - this means
          keeping our usage of whitespace tight where we can do improve the
          information density of our pages. For instances like marketing pages
          we use larger spacing tokens to add more breathing room and create a
          greater impact with our brand storytelling.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Our spacing scales are defined in two categories:
        </fl-text>
        <fl-picture
          alt="Spacing guide"
          [src]="'story/spacing-guide.jpg'"
          [alignCenter]="true"
          [boundedWidth]="true"
        ></fl-picture>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'small'"
        >
          1. Component Spacing Scale
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxxsmall'"
        >
          - This scale contains spacing tokens in the range from 4px - 48px.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          - Use this scale to build individual elements and components.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          - It includes tokens needed to create appropriate spacial
          relationships for detail-level designs.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'small'"
        >
          2. Layout Spacing Scale
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxxsmall'"
        >
          - This scale contains spacing tokens in the range from 16px - 96px.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          - Use this scale to arrange components and other UI parts into a pull
          page layout.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          - This scale contains larger increments that are used to control the
          density of a design.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          - Use the smaller layout tokens (16px - 48px) to create more dense
          components and direct relationships.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          - Use the larger tokens (64px - 96px) to increase the amount of white
          space and to disassociate sections.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Some common use-cases for spacing tokens:
        </fl-heading>
        <fl-table
          [dataSource]="spacingGuides"
          [flMarginBottom]="'small'"
        >
          <fl-table-col titleText="Enum">
            <ng-template
              #cell
              let-item
              >{{ item.enum }}</ng-template
            >
          </fl-table-col>
          <fl-table-col titleText="Value">
            <ng-template
              #cell
              let-item
              >{{ item.value }}</ng-template
            >
          </fl-table-col>
          <fl-table-col titleText="Common use-case">
            <ng-template
              #cell
              let-item
              >{{ item.useCase }}</ng-template
            >
          </fl-table-col>
        </fl-table>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
        >
          NOTE: Spacing is measured as the distance from element to element. In
          CSS it might not be exactly the same numbers as in design software.
          I.e. Text by default have a small margin at the bottom in HTML/CSS
          which is added to this measurement. In design software the spacing
          measurements often begin at the text baseline.
        </fl-text>
      </fl-card>
      <fl-card [flMarginBottom]="'mid'">
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Usage
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Any component supports a mobile first responsive spacing inputs.
          Supported margin values are defined by the Margin enum found in
          &#64;freelancer/ui/margin/margin.directive.ts.
        </fl-text>
        <div>
          <div
            class="SampleComponent"
            [flMarginRight]="'xxsmall'"
            [flMarginRightTablet]="'large'"
            [flMarginRightDesktop]="'xxxxlarge'"
            [flMarginBottom]="'mid'"
          >
            <fl-text>Component</fl-text>
          </div>
          <div
            class="SampleComponent"
            [flMarginBottom]="'mid'"
          >
            <fl-text>Component</fl-text>
          </div>
        </div>
        <app-code-block
          [code]="codeSnippet1"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'small'"
        >
          Here's how it works:
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'large'"
        >
          Give the example above, the margin of the first element is XXXSMALL by
          default. From tablet and up, the margin changes to LARGE then changes
          to XXXXLARGE from desktop and up.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'small'"
        >
          Breakpoints:
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          <fl-text [fontType]="FontType.STRONG"
            >[flMarginRightTablet] / [flMarginBottomTablet]</fl-text
          >: 768px;
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'large'"
        >
          <fl-text [fontType]="FontType.STRONG"
            >[flMarginRightDesktop] / [flMarginBottomDesktop]</fl-text
          >: 960px;
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'small'"
        >
          Other Examples
        </fl-heading>
        <div
          class="SampleSection"
          [flMarginBottom]="'large'"
        >
          <div>
            <div
              class="SampleComponent SampleComponent--fullWidth"
              [flMarginBottom]="'mid'"
              [flMarginBottomTablet]="'none'"
              [flMarginBottomDesktop]="'xxxlarge'"
            >
              <fl-text>Component</fl-text>
            </div>
            <div
              class="SampleComponent SampleComponent--fullWidth"
              [flMarginBottom]="'mid'"
            >
              <fl-text [size]="TextSize.XSMALL">Component</fl-text>
            </div>
          </div>
          <app-code-block
            [code]="codeSnippet2"
            [language]="'xml'"
            [flMarginBottom]="'large'"
          ></app-code-block>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [size]="TextSize.XSMALL"
          >
            For the example above, we're utilising the [flMarginBottom] inputs
            where we'll have a MID margin on mobile then we remove the margin on
            tablet and add a XXXLARGE margin from desktop and up.
          </fl-text>
        </div>
        <div class="SampleSection">
          <div>
            <div
              class="SampleComponent"
              [flMarginRightTablet]="'small'"
              [flMarginBottom]="'mid'"
            >
              <fl-text [size]="TextSize.XSMALL">Component</fl-text>
            </div>
            <div
              class="SampleComponent"
              [flMarginBottom]="'mid'"
            >
              <fl-text [size]="TextSize.XSMALL">Component</fl-text>
            </div>
          </div>
          <app-code-block
            [code]="codeSnippet3"
            [language]="'xml'"
            [flMarginBottom]="'large'"
          ></app-code-block>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [size]="TextSize.XSMALL"
          >
            On the example above, we're only setting a SMALL right margin only
            for tablet and up which means that there is no margin for screens
            that are smaller than tablets.
          </fl-text>
        </div>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss', './spacing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpacingComponent {
  HeadingType = HeadingType;
  TextSize = TextSize;
  FontType = FontType;

  spacingGuides: readonly SpacingGuide[] = [
    {
      enum: 'NONE',
      value: '0',
      useCase: 'Mainly for removing margins from smaller screens',
    },
    {
      enum: 'XXXSMALL',
      value: '4px',
      useCase: 'Used for compact spacing in between text components',
    },
    {
      enum: 'XXSMALL',
      value: '8px',
      useCase: 'Spacing buttons and text',
    },
    {
      enum: 'XSMALL',
      value: '12px',
      useCase: 'When 16px may *feel* too large',
    },
    {
      enum: 'SMALL',
      value: '16px',
      useCase:
        'Margins in small modules such as tooltips, margins in showcase cards, user cards and text',
    },
    {
      enum: 'MID',
      value: '24px',
      useCase:
        'Margins in card elements and as margin from the left/right/top/bottom border of the card',
    },
    {
      enum: 'LARGE',
      value: '32px',
      useCase:
        'Margins in modals and big cards that require more white space around the content',
    },
    {
      enum: 'XLARGE',
      value: '48px',
      useCase:
        'Margins in modals and big cards that require more white space around the content',
    },
    {
      enum: 'XXLARGE',
      value: '64px',
      useCase:
        'Extremely large cards for margins such as full view of entries on contest and showcase',
    },
    {
      enum: 'XXXLARGE',
      value: '72px',
      useCase: 'Used to separate sections on landing pages',
    },
    {
      enum: 'XXXXLARGE',
      value: '96px',
      useCase: 'Used to separate sections on landing pages and section heroes',
    },
  ];

  codeSnippet1 = `
<div class="SampleComponent"
  [flMarginRight]="'xxsmall'"
  [flMarginRightTablet]="'large'"
  [flMarginRightDesktop]="'xxxxlarge'"
  [flMarginBottom]="'mid'"
>
  <fl-text>Component</fl-text>
</div>
<div class="SampleComponent"
  [flMarginBottom]="'mid'"
>
  <fl-text>Component</fl-text>
</div>
  `;

  codeSnippet2 = `
<div class="SampleComponent SampleComponent--fullWidth"
  [flMarginBottom]="'mid'"
  [flMarginBottomTablet]="'none'"
  [flMarginBottomDesktop]="'xxxlarge'"
>
  <fl-text>Component</fl-text>
</div>
<div class="SampleComponent SampleComponent--fullWidth"
  [flMarginBottom]="'mid'"
>
  <fl-text>Component</fl-text>
</div>
  `;

  codeSnippet3 = `
<div class="SampleComponent"
  [flMarginRightTablet]="'small'"
  [flMarginBottom]="'mid'"
>
  <fl-text>Component</fl-text>
</div>
<div class="SampleComponent"
  [flMarginBottom]="'mid'"
>
  <fl-text>Component</fl-text>
</div>
  `;
}
