import type { OnDestroy } from '@angular/core';
import {
  Input,
  Directive,
  EventEmitter,
  HostListener,
  inject,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Haptics } from '@freelancer/haptics';
import { type Timer, TimeUtils } from '@freelancer/time-utils';
import { getScreenSize } from '../directives.utils';

@Directive({
  selector: '[flLongPress]',
  standalone: true,
})
export class LongPressDirective implements OnDestroy {
  private readonly time = inject(TimeUtils);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly haptics = inject(Haptics);

  @Input() enableHaptics = true;
  @Output() longPressedDesktop = new EventEmitter<void>();
  @Output() longPressedTablet = new EventEmitter<void>();
  @Output() longPressedMobile = new EventEmitter<void>();

  private pressStartId: Timer;
  private readonly duration = 500;

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: Event): void {
    this.pressStartId = this.time.setTimeout(() => {
      const screenSize = getScreenSize(this.platformId);

      switch (screenSize) {
        case 'desktop':
          this.longPressedDesktop.emit();
          break;
        case 'tablet':
          this.longPressedTablet.emit();
          break;
        case 'mobile':
          this.longPressedMobile.emit();

          if (this.enableHaptics) {
            this.haptics.light();
          }
          break;
        default:
          break;
      }
    }, this.duration);
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchmove')
  onPressEnd(): void {
    clearTimeout(this.pressStartId);
  }

  ngOnDestroy(): void {
    clearTimeout(this.pressStartId);
  }
}
