import type {
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from '../experiments';

export const sessionExperiments: Partial<SessionExperiments> = {
  'T301279-pjp-omni-update-test': 'control',
  'T298055-google-one-tap': 'control',
  'T302870-hero-home-page': 'control',
  'T297799-pjp-description-entropy': 'control',
};

export const userExperiments: Partial<UserExperiments> = {
  'T111865-logged-in-pjp-live-chat-just-enrollment': 'control',
  'T90189-image-thumbnail-service': 'control',
  webapp_playground_test: 'control',
  'T195472-web-push-notifications': 'control',
  'T281208-group-search-by-name': 'control',
  'T294922-share-revamp': 'control',
  'T296533-freelancer-onboarding-remove-payment-verification-step': 'control',
  'T298323-profile-redesign-noop': 'control',
  'T296370-profile-redesign': 'control',
  'T296347-pvp-floating-action-button': 'control',
  'T299115-activation-remarketing-newly-signed': 'control',
  'T299134-activation-remarketing-release-milestone-noop': 'control',
  'T301219-chain-funding': 'control',
  'T286144-share-revamp-v2': 'control',
  'T303953-chatbox-on-profile-v2': 'control',
  'T302575-project-workspace-page': 'control',
  'T304311-shortlist-bids': 'control',
  'T302868-enforce-dark-mode': 'control',
  'T302944-enforce-dark-mode-noop': 'control',
  'T304331-applepay-as-default-option': 'control',
  'T301037-rehire-on-dashboard-noop-v2': 'control',
};
export const projectExperiments: Partial<ProjectExperiments> = {};

export const isWhitelistUser = false;

export const shouldEnrol = true;
