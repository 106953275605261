import { CorporateStatusApi } from 'api-typings/users/users';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { Qualification } from '../project-view-projects/project-view-projects.model';
import type { MembershipPackage } from '../project-view-users';
import {
  generateFreeMembershipPackage,
  LocationPreset,
} from '../project-view-users';
import type { Location } from '../project-view-users/location.model';
import {
  generateSkills,
  getProjectViewUserLocations,
} from '../project-view-users/project-view-users.seed';
import type { UserStatus } from '../project-view-users/user-status.model';
import { generateFreelancerReputationObjects } from '../reputation/reputation-data.seed';
import type { Reputation } from '../reputation/reputation.model';
import type { Skill } from '../skills/skills.model';
import type { UserDocumentSubmission } from '../user-document-submissions';
import type { User } from '../users/users.model';
import { generateUserObject, unverifiedUser } from '../users/users.seed';
import type {
  CorporateDetails,
  CoverImageResult,
  ProfileViewUser,
} from './profile-view-users.model';

export interface GenerateProfileViewUsersOptions {
  readonly users: readonly User[];
  readonly currencyCode?: CurrencyCode;
  readonly location?: Location;
}

export function generateProfileViewUserObjects({
  users,
  currencyCode = CurrencyCode.USD,
  location,
}: GenerateProfileViewUsersOptions): readonly ProfileViewUser[] {
  const reputationObjects = generateFreelancerReputationObjects({
    userIds: users.map(user => user.id),
  });

  return users.map((user, index) => ({
    ...generateProfileViewUserObject({
      user,
      currencyCode,
      reputation: reputationObjects[index],
      location,
    }),
  }));
}

export interface GenerateProfileViewUserOptions {
  readonly user: User;
  readonly currencyCode?: CurrencyCode;
  readonly skills?: readonly Skill[];
  readonly location?: Location;
  readonly preferredFreelancer?: boolean;
  readonly registrationDate?: number;
  readonly reputation?: Reputation;
  readonly status?: UserStatus;
  readonly profileDescription?: string;
  readonly tagLine?: string;
  readonly hourlyRate?: number;
  readonly corporate?: CorporateDetails;
  readonly documentSubmissions?: readonly UserDocumentSubmission[];
  readonly membershipPackage?: MembershipPackage;
  readonly isRisingStar?: boolean;
  readonly isStaff?: boolean;
  readonly qualifications?: readonly Qualification[];
  readonly recommendations?: number;
  readonly coverImage?: CoverImageResult;
}

export function generateProfileViewUserObject({
  user,
  currencyCode = CurrencyCode.USD,
  skills = generateSkills(),
  location = getProjectViewUserLocations(LocationPreset.SEATTLE),
  preferredFreelancer = false,
  registrationDate = Date.now(),
  profileDescription = 'This is my profile description.',
  tagLine = 'This is my tagline.',
  hourlyRate = 40,
  corporate,
  documentSubmissions,
  membershipPackage = generateFreeMembershipPackage(),
  isRisingStar = false,
  qualifications = [],
  recommendations,
  isStaff = false,
  coverImage = undefined,
}: GenerateProfileViewUserOptions): ProfileViewUser {
  const {
    id,
    username,
    displayName,
    role,
    chosenRole,
    status,
    enterpriseIds,
    supportStatus,
  } = user;
  return {
    ...generateUserObject({
      userId: id,
      username,
      displayName,
      enterpriseIds,
      role,
      chosenRole,
      currencyCode,
      userStatus: status,
      supportStatus,
      isStaff,
    }),

    // Shared fields with ProjectViewUser
    skills,
    preferredFreelancer,
    registrationDate,
    qualifications,
    tagLine,
    location,
    hourlyRate,
    status: status || unverifiedUser().userStatus,
    membershipPackage,

    // Fields specific to ProfileViewUser
    coverImage,
    corporate,
    timezone: {
      // TODO: T267853 - Make dependent on location/country
      id: 422,
      country: 'AU',
      timezoneName: 'Australia/Sydney',
      offset: 'GMT+11:00',
    },
    portfolioCount: 0,
    profileDescription,
    primaryCurrency: generateCurrencyObject(currencyCode),
    publicName: displayName, // FIXME: T267853 - computed field, see transformer,
    recommendations,
    isProfileVisible: true,
    documentSubmissions,
    isRisingStar,
  } as ProfileViewUser;
}

export function activeCorporation(): CorporateDetails {
  return {
    status: CorporateStatusApi.ACTIVE,
    founderId: 1,
  };
}

export function withPhpExam(): Pick<
  GenerateProfileViewUserOptions,
  'qualifications'
> {
  return {
    qualifications: [
      {
        id: 8,
        level: 1,
        name: 'PHP - Level 1',
        scorePercentage: 100,
        type: 'PHP',
      },
    ],
  };
}

export function withoutSkills(): Pick<
  GenerateProfileViewUserOptions,
  'skills'
> {
  return { skills: [] };
}
