import type {
  AdminEnterpriseModel,
  AdminEnterprisesCollection,
  AiPrompt,
  CustomFieldValueForSearch,
  DeliveryItem,
  Group,
  GroupsCollection,
  GroupsSelfCollection,
  MembershipSubscriptionHistory,
  MembershipSubscriptionHistoryCollection,
  Profile,
  ProfilesCollection,
  ProjectBudget,
  ProjectViewUser,
  ProjectViewUsersCollection,
  SearchActiveContest,
  SearchActiveContestsCollection,
  SearchActiveLoad,
  SearchActiveLoadsCollection,
  SearchActiveProject,
  SearchActiveProjectsCollection,
  SearchUser,
  SearchUsersCollection,
  SuperUserAllProjects,
  SuperuserGroupsCollection,
  SuperuserProjectViewAllProjectsCollection,
  AiPromptsCollection,
  ServiceOfferingShopsCollection,
  ServiceOfferingShop,
  ServiceOfferingShopCategory,
  ServiceOfferingShopCategoriesCollection,
} from '@freelancer/datastore/collections';
import {
  FieldType,
  MembershipCategory,
} from '@freelancer/datastore/collections';
import type {
  MapCoordinates,
  Ordering,
  SearchQueryParams,
} from '@freelancer/datastore/core';
import { compareMultipleFields } from '@freelancer/datastore/core';
import { assertNever, isArray, isDefined, toNumber } from '@freelancer/utils';
import { ProjectTypeApi } from 'api-typings/projects/projects';
import { addSearchTransformer } from './document-creators';

export function addSearchTransformers(): void {
  addSearchTransformer<SearchUsersCollection>('searchUsers', searchUsersFilter);
  addSearchTransformer<SearchActiveProjectsCollection>(
    'searchActiveProjects',
    searchActiveProjectsFilter,
  );
  addSearchTransformer<SearchActiveContestsCollection>(
    'searchActiveContests',
    searchActiveContestsFilter,
  );
  addSearchTransformer<SearchActiveLoadsCollection>(
    'searchActiveLoads',
    searchActiveLoadsFilter,
  );
  addSearchTransformer<AdminEnterprisesCollection>(
    'adminEnterprises',
    searchAdminEnterprisesFilter,
  );
  addSearchTransformer<ProjectViewUsersCollection>(
    'projectViewUsers',
    searchProjectViewUserFilter,
  );
  addSearchTransformer<MembershipSubscriptionHistoryCollection>(
    'membershipSubscriptionHistory',
    searchMembershipSubscriptionHistory,
  );
  addSearchTransformer<GroupsCollection>('groups', searchGroupsFilter);
  addSearchTransformer<SuperuserGroupsCollection>(
    'superuserGroups',
    searchGroupsFilter,
  );
  addSearchTransformer<GroupsSelfCollection>('groupsSelf', searchGroupsFilter);
  addSearchTransformer<SuperuserProjectViewAllProjectsCollection>(
    'superuserProjectViewAllProjects',
    searchSuperuserViewAllProjectsFilter,
  );
  addSearchTransformer<ProfilesCollection>('profiles', searchProfilesFilter);
  addSearchTransformer<AiPromptsCollection>('aiPrompts', searchAiPromptsFilter);
  addSearchTransformer<ServiceOfferingShopsCollection>(
    'serviceOfferingShops',
    searchServiceOfferingShopsFilter,
  );
  addSearchTransformer<ServiceOfferingShopCategoriesCollection>(
    'serviceOfferingShopCategories',
    searchServiceOfferingShopCategoriesFilter,
  );
}

function searchAiPromptsFilter(
  documents: readonly AiPrompt[],
  searchParams: SearchQueryParams,
): readonly AiPrompt[] {
  const { query } = searchParams;
  let filteredDocs = documents;

  if (isDefined(query)) {
    if (!(typeof query === 'string')) {
      throw new Error("'query' field in search query params must be a string.");
    }

    filteredDocs = filteredDocs.filter(prompt => {
      return prompt.name.includes(query);
    });
  }

  return filteredDocs;
}

function searchProfilesFilter(
  documents: readonly Profile[],
  searchParams: SearchQueryParams,
): readonly Profile[] {
  const { seoUrl } = searchParams;
  let filteredDocs = documents;

  if (seoUrl) {
    filteredDocs = filteredDocs.filter(profile => {
      return profile.seoUrl === seoUrl;
    });
  }

  return filteredDocs;
}

/**
 * This ensures that searching via seoUrl only returns exact matches. Without this search queries were returning incorrect
 *  documents from the datastore. This also allows group searching by name to work for ui tests.
 */
function searchGroupsFilter(
  documents: readonly Group[],
  searchParams: SearchQueryParams,
): readonly Group[] {
  const { seoUrl, name } = searchParams;
  let filteredDocs = documents;

  if (seoUrl) {
    filteredDocs = filteredDocs.filter(group => {
      return group.seoUrl === seoUrl;
    });
  }

  if (name) {
    filteredDocs = filteredDocs.filter(group => {
      return group.name === name;
    });
  }

  return filteredDocs;
}

function searchUsersFilter(
  documents: readonly SearchUser[],
  { query }: SearchQueryParams,
  order?: Ordering<SearchUsersCollection>,
): readonly SearchUser[] {
  const filteredDocuments = documents.filter(user => {
    let result = true;

    if (isDefined(query)) {
      if (!(typeof query === 'string')) {
        throw new Error();
      }

      result =
        result &&
        (user.profileDescription?.includes(query) ||
          user.publicName.includes(query) ||
          user.skills.some(skill => skill.name.includes(query)) ||
          user.tagLine?.includes(query) ||
          user.username.includes(query));
    }

    return result;
  });

  return order
    ? filteredDocuments.sort((document1, document2) =>
        compareMultipleFields<SearchUsersCollection['DocumentType']>(
          Array.isArray(order) ? order : [order],
        )(document1, document2),
      )
    : filteredDocuments;
}

function searchSuperuserViewAllProjectsFilter(
  documents: readonly SuperUserAllProjects[],
  searchQuery: SearchQueryParams,
): readonly SuperUserAllProjects[] {
  const { customFieldValues } = searchQuery;
  return documents.filter(project => {
    const projectCustomFieldValues = project.customFieldValues;
    if (isDefined(customFieldValues)) {
      const filters =
        customFieldValues as unknown as CustomFieldValueForSearch[];

      // If project has custom fields that matches all custom field filters,
      // project should be returned
      return filters.every(filter => {
        return projectCustomFieldValues.some(value => {
          const { type } = filter;
          switch (type) {
            case FieldType.LOCATION: {
              const projectCustomFieldValue = value.value as MapCoordinates;
              return (
                filter?.customFieldInfoConfigurationId ===
                  value.customFieldInfoConfigurationId &&
                filter.value.latitude === projectCustomFieldValue?.latitude &&
                filter.value.longitude === projectCustomFieldValue?.longitude
              );
            }
            case FieldType.BOOLEAN:
            case FieldType.FLOAT:
            case FieldType.INTEGER:
            case FieldType.TIMESTAMP:
            case FieldType.STRING:
              return (
                filter.customFieldInfoConfigurationId ===
                  value.customFieldInfoConfigurationId &&
                filter.value === value.value
              );
            default: {
              return false;
            }
          }
        });
      });
    }

    // Required by project tracker data service to get all projects
    const { enterprise_ids } = searchQuery;
    if (isDefined(enterprise_ids)) {
      if (!isArray(enterprise_ids)) {
        return false;
      }
      const searchEnterpriseIds = enterprise_ids.map(toNumber);
      return project.enterpriseIds?.some(enterpriseId =>
        searchEnterpriseIds.includes(enterpriseId),
      );
    }

    return false;
  });
}

function searchActiveProjectsFilter(
  documents: readonly SearchActiveProject[],
  {
    query,
    latitude,
    longitude,
    minHourlyRate,
    maxHourlyRate,
    minFixedPrice,
    maxFixedPrice,
    countries,
  }: SearchQueryParams,
): readonly SearchActiveProject[] {
  return documents.filter(project => {
    let result = true;

    // Find the query keywords from project title and description.
    if (isDefined(query)) {
      if (!(typeof query === 'string')) {
        throw new Error(
          "'query' field in search query params must be a string.",
        );
      }
      result =
        result &&
        (project.title.includes(query) || project.description.includes(query));
    }

    // Filter out project in the budget range.
    // When we are unsure about the project currency exchange rate, let's assume it as USD.
    switch (project.type) {
      case ProjectTypeApi.HOURLY:
        result =
          result &&
          isProjectWithinBudget(
            project.budget,
            !isArray(minHourlyRate) ? toNumber(minHourlyRate) : undefined,
            !isArray(maxHourlyRate) ? toNumber(maxHourlyRate) : undefined,
          );
        break;

      case ProjectTypeApi.FIXED:
        result =
          result &&
          isProjectWithinBudget(
            project.budget,
            !isArray(minFixedPrice) ? toNumber(minFixedPrice) : undefined,
            !isArray(maxFixedPrice) ? toNumber(maxFixedPrice) : undefined,
          );
        break;

      default:
        assertNever(project.type);
    }

    // Filter out project by selected client countries
    if (isDefined(project.clientCountry) && isDefined(countries)) {
      if (isArray(countries)) {
        result =
          result && (countries as string[]).includes(project.clientCountry);
      } else {
        throw new Error(
          "'countries' field in search query params must be an array of string country codes",
        );
      }
    }

    // Filter the project by location search coordinates.
    // Projects beyond 150km from the search coordinates are filtered out by the backend.
    if (
      isDefined(project.searchCoordinates) &&
      typeof latitude === 'number' &&
      typeof longitude === 'number'
    ) {
      const SEARCH_RADIUS = 150;
      const distanceBetweenProjectAndSearchCoordinates =
        getDistanceBetweenTwoMapCoordinates(
          { latitude, longitude },
          project.searchCoordinates,
        );

      result =
        result && distanceBetweenProjectAndSearchCoordinates <= SEARCH_RADIUS;
    }
    return result;
  });
}

function searchActiveContestsFilter(
  documents: readonly SearchActiveContest[],
  { query }: SearchQueryParams,
): readonly SearchActiveContest[] {
  return documents.filter(contest => {
    let result = true;

    // Find the query keywords from contest title and description.
    if (isDefined(query)) {
      if (!(typeof query === 'string')) {
        throw new Error(
          "'query' field in search query params must be a string.",
        );
      }
      result =
        result &&
        (contest.title.includes(query) || contest.description.includes(query));
    }

    return result;
  });
}

function searchActiveLoadsFilter(
  documents: readonly SearchActiveLoad[],
  {
    query,
    maxWidth,
    maxLength,
    minWeight,
    maxWeight,
    startOperatingAreaIds,
    endOperatingAreaIds,
  }: SearchQueryParams,
  order?: Ordering<SearchActiveLoadsCollection>,
): readonly SearchActiveLoad[] {
  const filteredDocuments = documents.filter(load => {
    let result = true;

    // Find the query keywords from load title.
    if (isDefined(query)) {
      if (!(typeof query === 'string')) {
        throw new Error(
          "'query' field in search query params must be a string.",
        );
      }
      result =
        (result && load.title.includes(query)) ||
        load.id.toString().includes(query) ||
        (isDefined(load.localDetails) &&
          isDefined(load.localDetails.displayEndLocation) &&
          load.localDetails.displayEndLocation.includes(query)) ||
        (isDefined(load.displayStartLocation) &&
          load.displayStartLocation.includes(query));
    }

    /**
     * Filter projects with at least one delivery item that
     * fulfill dimensions filters.
     */
    if (
      isDefined(load.localDetails) &&
      isDefined(load.localDetails.deliveryItems)
    ) {
      const matchesDimensionFilters = hasDeliveryItemMatchingDimensions(
        load.localDetails.deliveryItems,
        isArray(maxWidth) ? undefined : toNumber(maxWidth),
        isArray(maxLength) ? undefined : toNumber(maxLength),
        isArray(minWeight) ? undefined : toNumber(minWeight),
        isArray(maxWeight) ? undefined : toNumber(maxWeight),
      );

      result = result && matchesDimensionFilters;
    }

    if (isArray(endOperatingAreaIds) && isArray(load.endOperatingAreaIds)) {
      const matchesEndOperatingAreaFilter = load.endOperatingAreaIds.some(
        endOperatingAreaId =>
          endOperatingAreaIds.map(toNumber).includes(endOperatingAreaId),
      );

      result = result && matchesEndOperatingAreaFilter;
    }

    if (isArray(startOperatingAreaIds) && isArray(load.startOperatingAreaIds)) {
      const matchesStartOperatingAreaFilter = load.startOperatingAreaIds.some(
        startOperatingAreaId =>
          startOperatingAreaIds.map(toNumber).includes(startOperatingAreaId),
      );

      result = result && matchesStartOperatingAreaFilter;
    }

    return result;
  });

  return order
    ? filteredDocuments.sort((document1, document2) =>
        compareMultipleFields<SearchActiveLoadsCollection['DocumentType']>(
          Array.isArray(order) ? order : [order],
        )(document1, document2),
      )
    : filteredDocuments;
}

function searchAdminEnterprisesFilter(
  documents: readonly AdminEnterpriseModel[],
  { query }: SearchQueryParams,
): readonly AdminEnterpriseModel[] {
  if (isDefined(query) && typeof query === 'string') {
    return documents.filter(
      enterprise =>
        enterprise.seoUrl?.includes(query) || enterprise.name.includes(query),
    );
  }
  return documents;
}

function searchProjectViewUserFilter(
  documents: readonly ProjectViewUser[],
  { query }: SearchQueryParams,
): readonly ProjectViewUser[] {
  if (isDefined(query) && typeof query === 'string') {
    return documents.filter(user => user.username.includes(query));
  }
  return documents;
}

function getDistanceBetweenTwoMapCoordinates(
  firstSearchCoordinates: MapCoordinates,
  secondSearchCoordinates: MapCoordinates,
): number {
  const APPROXIMATE_DEG_TO_KM_CONVERSION = 111;

  // We use a straight line formula instead of calculating the haversine distance for simplicity purposes
  return (
    Math.sqrt(
      (firstSearchCoordinates.latitude - secondSearchCoordinates.latitude) **
        2 +
        (firstSearchCoordinates.longitude -
          secondSearchCoordinates.longitude) **
          2,
    ) * APPROXIMATE_DEG_TO_KM_CONVERSION
  );
}

function searchMembershipSubscriptionHistory(
  documents: readonly MembershipSubscriptionHistory[],
  { categoryNames }: SearchQueryParams,
): readonly MembershipSubscriptionHistory[] {
  const membershipCategories = {
    [MembershipCategory.LEGACY]: 1,
    [MembershipCategory.CORPORATE]: 3,
    [MembershipCategory.TEAM]: 4,
    [MembershipCategory.LOADSHIFT]: 7,
  };

  if (isDefined(categoryNames)) {
    const filters = categoryNames as readonly MembershipCategory[];
    const categoryIds = filters.map(category => membershipCategories[category]);

    return documents.filter(subscriptionHistory =>
      categoryIds.includes(subscriptionHistory.package.categoryId),
    );
  }

  return documents;
}

/**
 * Based on the backend project budget filtering logic in
 * public/util/classes/project/BrowseProjectAndContestElasticaParam.php createBudgetRangeFilter()
 *
 * If a project has both minimum and maximum budget, it is considered within budget as long as the
 * budget range intersects with the filter range.
 *
 * If only the project's minimum budget exists, the project is considered within budget if its minimum
 * budget is less than the maximum filter since the minimum filter doesn't have an effect on the matched
 * projects.
 */
function isProjectWithinBudget(
  budget: ProjectBudget,
  minBudgetFilter?: number,
  maxBudgetFilter?: number,
): boolean {
  if (!isDefined(budget.maximum)) {
    return !isDefined(maxBudgetFilter) || budget.minimum <= maxBudgetFilter;
  }

  return (
    (!isDefined(maxBudgetFilter) ||
      budget.minimum <= maxBudgetFilter ||
      budget.maximum <= maxBudgetFilter) &&
    (!isDefined(minBudgetFilter) ||
      budget.minimum >= minBudgetFilter ||
      budget.maximum >= minBudgetFilter)
  );
}

/**
 * If a project has delivery items, it is returned if at least
 * one delivery item matches the dimension filters.
 */
function hasDeliveryItemMatchingDimensions(
  deliveryItems: readonly DeliveryItem[],
  maxWidthFilter?: number,
  maxLengthFilter?: number,
  minWeightFilter?: number,
  maxWeightfilter?: number,
): boolean {
  return deliveryItems.some(item => {
    return (
      (item.width && maxWidthFilter ? item.width <= maxWidthFilter : true) &&
      (item.length && maxLengthFilter
        ? item.length <= maxLengthFilter
        : true) &&
      (item.weight && minWeightFilter
        ? item.weight >= minWeightFilter
        : true) &&
      (item.weight && maxWeightfilter ? item.weight <= maxWeightfilter : true)
    );
  });
}

function searchServiceOfferingShopsFilter(
  documents: readonly ServiceOfferingShop[],
  { seoUrl }: SearchQueryParams,
  order?: Ordering<ServiceOfferingShopsCollection> | undefined,
): readonly ServiceOfferingShop[] {
  if (typeof seoUrl === 'string') {
    return documents.filter(document => document.seoUrl?.includes(seoUrl));
  }
  return documents;
}

function searchServiceOfferingShopCategoriesFilter(
  documents: readonly ServiceOfferingShopCategory[],
  { categorySeoUrl }: SearchQueryParams,
  order?: Ordering<ServiceOfferingShopCategoriesCollection> | undefined,
): readonly ServiceOfferingShopCategory[] {
  if (typeof categorySeoUrl === 'string') {
    return documents.filter(document =>
      document.seoUrl?.includes(categorySeoUrl),
    );
  }
  return documents;
}
