import type { AfterContentInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  CalloutColor,
  CalloutComponent,
  CalloutPlacement,
} from '@freelancer/ui/callout';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor, LinkUnderline, QueryParams } from '@freelancer/ui/link';
import { FontWeight } from '@freelancer/ui/text';
import type { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  MoreOptionsIconColor,
  MoreOptionsIconSize,
  MoreOptionsIconType,
} from './more-options.types';

@Component({
  selector: `fl-more-options-item`,
  template: `
    <fl-link
      *ngIf="!iconName"
      class="ItemLink"
      [color]="LinkColor.INHERIT"
      [link]="link ? link : undefined"
      [queryParams]="queryParams"
      [newTab]="newTab"
      [underline]="LinkUnderline.NEVER"
      (click)="handleClick()"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </fl-link>

    <div
      *ngIf="!!iconName"
      class="ItemLinkWithIcon"
      [attr.data-row-hover]="true"
      (click)="handleClick()"
    >
      <fl-icon
        [useIconFont]="true"
        [fillIconFont]="false"
        [name]="iconName"
        [size]="iconSize"
        [color]="iconColor"
      ></fl-icon>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <ng-template #contentTemplate>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./more-options-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreOptionsItemComponent {
  LinkColor = LinkColor;
  LinkUnderline = LinkUnderline;

  @Input() link?: string;
  @Input() newTab?: boolean;
  @Input() queryParams?: QueryParams;
  @Input() iconName?: string;
  @Input() iconSize: IconSize = IconSize.MID;
  @Input() iconColor: IconColor = IconColor.FOREGROUND;
  @Input() disableCalloutClose = false;

  // FIXME: T300116 Enabling hydration errors on the webapp server
  // TypeError: Cannot read properties of null (reading 'parentElement')
  @HostBinding('attr.ngSkipHydration') skipHydration = 'true';

  @Output() itemSelected = new EventEmitter<void>();

  handleClick(): void {
    if (!this.disableCalloutClose) {
      this.itemSelected.emit();
    }
  }
}

@Component({
  selector: 'fl-more-options',
  template: `
    <fl-callout
      class="MoreOptions"
      [color]="calloutColor"
      [edgeToEdge]="true"
      [hideArrow]="true"
      [hideCloseButton]="true"
      [hover]="hover"
      [placement]="calloutPlacement"
      [mobileCloseButton]="false"
      [mobileDropdown]="mobileDropdown"
      (calloutOpen)="toggleState()"
      (calloutClose)="toggleState()"
    >
      <fl-callout-trigger>
        <fl-icon
          class="MoreOptions-trigger"
          title="More Options"
          i18n-title="More Options"
          [class.IsActive]="isActive"
          [class.IsDark]="iconColor === IconColor.FOREGROUND"
          [attr.data-trigger-size]="size"
          [clickable]="true"
          [name]="iconName"
          [size]="size"
          [color]="iconColor"
          [weight]="FontWeight.LIGHT"
          [useIconFont]="useIconFont"
        ></fl-icon>
      </fl-callout-trigger>

      <fl-callout-content>
        <div
          class="MoreOptions-content"
          [attr.data-color]="calloutColor"
        >
          <ng-content></ng-content>
        </div>
      </fl-callout-content>
    </fl-callout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./more-options.component.scss'],
})
export class MoreOptionsComponent implements AfterContentInit, OnDestroy {
  CalloutPlacement = CalloutPlacement;
  IconSize = IconSize;
  IconColor = IconColor;
  MoreOptionsIconType = MoreOptionsIconType;
  FontWeight = FontWeight;

  iconName = 'ui-show-more-h';
  isActive = false;

  private subscriptions: readonly Subscription[] = [];
  private itemsSubscription?: Subscription;

  @Input() iconColor: MoreOptionsIconColor = IconColor.FOREGROUND;
  @Input() calloutColor = CalloutColor.LIGHT;
  @Input() calloutPlacement = CalloutPlacement.BOTTOM;
  @Input() disableCalloutClose = false;
  @Input() hover = false;
  @Input() size: MoreOptionsIconSize = IconSize.MID;
  @Input() mobileDropdown = false;
  @Input() useIconFont = true;

  @Input() set iconType(type: MoreOptionsIconType) {
    this.iconName =
      type === MoreOptionsIconType.COG
        ? 'ui-cog-v2'
        : type === MoreOptionsIconType.MORE_VERT
        ? 'ui-more-vert'
        : 'ui-show-more-h';
  }

  @Output() calloutToggled = new EventEmitter<boolean>();

  @ViewChild(CalloutComponent)
  calloutComponent: CalloutComponent;

  @ContentChildren(MoreOptionsItemComponent)
  items: QueryList<MoreOptionsItemComponent>;

  // FIXME: T300116 Enabling hydration errors on the webapp server
  // TypeError: Cannot read properties of null (reading 'parentElement')
  @HostBinding('attr.ngSkipHydration') skipHydration = 'true';

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    // Keep track of the changes to update subscription list
    this.itemsSubscription = this.items.changes
      .pipe(startWith(this.items.toArray()))
      .subscribe(() => {
        // Clear out all subscriptions to avoid duplicates
        this.clearSubscriptions();

        this.subscriptions = this.items.map((item: MoreOptionsItemComponent) =>
          // FIXME: T267853 -
          // eslint-disable-next-line rxjs/no-nested-subscribe
          item.itemSelected.subscribe(() => {
            this.closeCallout();
          }),
        );
      });
  }

  toggleState(): void {
    this.isActive = !this.isActive;
    this.calloutToggled.emit(this.isActive);
  }

  public closeCallout(): void {
    if (!this.disableCalloutClose && this.calloutComponent?.calloutIsOpen) {
      this.calloutComponent.close();
    }
  }

  private clearSubscriptions(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();

    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }
}
