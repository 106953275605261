import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  blockInitialRenderAnimation,
  listAnimation,
  shakeAnimation,
} from '@freelancer/animations';
import { Assets } from '@freelancer/ui/assets';
import { ButtonColor } from '@freelancer/ui/button';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { trackById } from '@freelancer/ui/helpers';
import { TagStatusColor } from '@freelancer/ui/tag-status';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';
import type { DynamicDemoConfig } from '../dynamic-demo/dynamic-demo.component';

interface User {
  avatar: string;
  id: number;
  username: string;
}

@Component({
  selector: 'app-static-attention-seeker',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <div [flMarginBottom]="'small'">
          <fl-tag-status [color]="TagStatusColor.BLUE">Beta</fl-tag-status>
        </div>

        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.XLARGE"
          [flMarginBottom]="'mid'"
        >
          Attention Seeker
        </fl-heading>

        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.LARGE"
          [weight]="HeadingWeight.NORMAL"
          [flMarginBottom]="'xsmall'"
        >
          Animating List
        </fl-heading>
        <fl-text
          [size]="TextSize.SMALL"
          [flMarginBottom]="'mid'"
        >
          Animate addition and removal of items in a list to features like
          notifications where it is important for users to know events that
          happen in real time. This is built mainly for contiguous lists.
        </fl-text>

        <app-dynamic-demo
          [config]="animatingListConfig"
          [flMarginBottom]="'mid'"
        >
          <app-dynamic-demo-content>
            <fl-list
              @blockInitialRenderAnimation
              [transparent]="true"
            >
              <fl-list-item
                *ngFor="let item of options; trackBy: trackById"
                [@listAnimation]="{
                  value: undefined,
                  params: {
                    duration: animatingListConfig.duration?.value,
                    delay: animatingListConfig.delay?.value,
                    easing: animatingListConfig.easing?.value,
                    highlightColor:
                      animatingListConfig.custom?.highlightColor?.value
                  }
                }"
              >
                <div class="MediaContent">
                  <div class="MediaContent-avatar">
                    <fl-user-avatar
                      [flMarginRight]="'small'"
                      [users]="[
                        {
                          username: item.username,
                          avatar: item.avatar
                        }
                      ]"
                    ></fl-user-avatar>
                  </div>
                  <div>
                    <fl-text>
                      Maggie F. created a
                      {{ 10 + item.id | flCurrency : 'USD' }} milestone payment
                      for project Design me a logo for a brewing company.
                    </fl-text>
                    <fl-text [size]="TextSize.XXSMALL">
                      About 3 hours ago
                    </fl-text>
                  </div>
                </div>
              </fl-list-item>
            </fl-list>
          </app-dynamic-demo-content>
          <app-dynamic-demo-action>
            <fl-button
              [color]="ButtonColor.TRANSPARENT_DARK"
              [flMarginRight]="'xsmall'"
              (click)="addItem()"
            >
              Add
            </fl-button>
            <fl-button
              [color]="ButtonColor.TRANSPARENT_DARK"
              (click)="removeItem()"
            >
              Remove
            </fl-button>
          </app-dynamic-demo-action>
        </app-dynamic-demo>

        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.LARGE"
          [weight]="HeadingWeight.NORMAL"
          [flMarginBottom]="'xsmall'"
        >
          Shake
        </fl-heading>
        <fl-text
          [size]="TextSize.SMALL"
          [flMarginBottom]="'mid'"
        >
          Shake container when you want to draw user's attention to an important
          step of the user story.
        </fl-text>

        <app-dynamic-demo [config]="shakeConfig">
          <app-dynamic-demo-content>
            <fl-card
              class="ShakeCard"
              [@shakeAnimation]="{
                value: shakeState,
                params: {
                  duration: shakeConfig.duration?.value,
                  delay: shakeConfig.delay?.value,
                  translateX: shakeConfig.translateX?.value
                }
              }"
              (@shakeAnimation.done)="shakeDone()"
            >
              <fl-text
                [size]="TextSize.MID"
                [weight]="FontWeight.BOLD"
              >
                Warning!
              </fl-text>
              <fl-text [flMarginBottom]="'xxlarge'">
                This container will shake.
              </fl-text>
              <fl-button
                [color]="ButtonColor.PRIMARY"
                (click)="shake()"
              >
                Trigger shake
              </fl-button>
            </fl-card>
          </app-dynamic-demo-content>
        </app-dynamic-demo>
      </fl-card>
    </div>
  `,
  styleUrls: [
    '../../static-pages.component.scss',
    './attention-seeker.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listAnimation, blockInitialRenderAnimation, shakeAnimation],
})
export class AttentionSeekerComponent {
  ButtonColor = ButtonColor;
  FontColor = FontColor;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  TagStatusColor = TagStatusColor;
  TextSize = TextSize;
  trackById = trackById;

  defaultAvatar = this.assets.getUrl('bits/avatars/32-5.jpg');
  defaultUser = {
    id: 1,
    avatar: this.defaultAvatar,
    username: 'user1',
  };

  // Animating List
  options: readonly User[] = [this.defaultUser];
  animatingListConfig: DynamicDemoConfig = {
    custom: {
      highlightColor: new FormControl('#dbf4ff', { nonNullable: true }),
    },
    duration: new FormControl(1000, { nonNullable: true }),
    delay: new FormControl(0, { nonNullable: true }),
    easing: new FormControl('ease-out', { nonNullable: true }),
  };

  // Shake
  shakeState = 'void';
  shakeConfig: DynamicDemoConfig = {
    duration: new FormControl(700, { nonNullable: true }),
    delay: new FormControl(0, { nonNullable: true }),
    translateX: new FormControl('6px', { nonNullable: true }),
  };

  constructor(private assets: Assets) {}

  addItem(): void {
    const id = this.options.length + 1;
    this.options = [
      ...this.options,
      {
        id,
        avatar: this.defaultAvatar,
        username: `user${id}`,
      },
    ];
  }

  removeItem(): void {
    const index = this.options.length - 1;
    this.options = this.options.slice(0, index);
  }

  shake(): void {
    this.shakeState = 'active';
  }

  shakeDone(): void {
    this.shakeState = 'void';
  }
}
