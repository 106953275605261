import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserCartRequiresEmailVerification } from './user-cart-requires-email-verification.model';

export interface GenerateUserCartRequiresEmailVerificationOptions {
  readonly id?: string;
  readonly userCartRequiresEmailVerification?: boolean;
}

export function generateUserCartRequiresEmailVerificationObject({
  id = generateId().toString(),
  userCartRequiresEmailVerification = false,
}: GenerateUserCartRequiresEmailVerificationOptions = {}): UserCartRequiresEmailVerification {
  return {
    id,
    userCartRequiresEmailVerification,
  };
}
