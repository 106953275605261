import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Environment, ENVIRONMENT_NAME } from '@freelancer/config';
import { Seo } from '@freelancer/seo';
import { isDefined } from '@freelancer/utils';
import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-base',
  template: `
    <app-nav
      (menuButtonClicked)="toggleMobileSidebar()"
      [hideInternalPages]="hideInternalPages"
    ></app-nav>

    <div class="MainContainer">
      <div
        *ngIf="(storyOnly$ | flAsync) === false"
        class="MainSidebar"
      >
        <div
          class="MainSidebar-backdrop"
          [attr.data-sidebar-open]="sidebarOpen"
          [attr.tabindex]="0"
          [flHideDesktop]="true"
          (click)="toggleMobileSidebar()"
        ></div>
        <app-sidebar
          class="MainSidebar-content"
          [attr.data-sidebar-open]="sidebarOpen"
          [hideInternalPages]="hideInternalPages"
          (closeSidebar)="toggleMobileSidebar()"
        ></app-sidebar>
      </div>
      <div
        class="MainContent"
        [@.disabled]="storyOnly$ | flAsync"
      >
        <div
          class="MainOutlet"
          cdkScrollable
        >
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit, OnDestroy {
  sidebarOpen = false;
  hideInternalPages = true;
  storyOnly$: Observable<boolean>;

  private subscriptions = new Subscription();

  constructor(
    private seo: Seo,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    @Inject(ENVIRONMENT_NAME) private environment: Environment,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.renderer.removeStyle(this.document.body, 'overflow');

    this.seo.setPageTags({
      title: 'Bits',
      description: `A live showcase of Freelancer.com's official design system, Bits`,
    });

    this.storyOnly$ = this.activatedRoute.queryParamMap.pipe(
      map(params => isDefined(params.get('story_only'))),
    );

    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.sidebarOpen = false;
          this.togglePageScroll();
        }),
    );

    this.hideInternalPages = this.environment === 'prod';
  }

  toggleMobileSidebar(): void {
    this.sidebarOpen = !this.sidebarOpen;
    this.togglePageScroll();
  }

  togglePageScroll(): void {
    if (this.sidebarOpen) {
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    } else {
      this.renderer.removeStyle(this.document.body, 'overflow');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
