import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-how-to-contribute',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Contribute
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          Thank you for your interest in contributing to Bits. We welcome all
          feedback for design, guidelines or implementation to improve our
          design system. Follow our guidelines on how to contribute.
        </fl-text>
      </fl-card>

      <fl-card
        [edgeToEdge]="true"
        [flMarginBottom]="'small'"
      >
        <fl-tabs>
          <fl-tab-item
            titleText="Develop"
            iconName="ui-computer"
            link="/info/how-to-contribute/develop"
          ></fl-tab-item>
          <fl-tab-item
            titleText="Design"
            iconName="ui-penbrush"
            link="/info/how-to-contribute/design"
          ></fl-tab-item>
        </fl-tabs>
      </fl-card>

      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './how-to-contribute.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowToContributeComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
}
