import { generateId } from '@freelancer/datastore/testing/helpers';
import type { PoolModel } from './pools.model';

export interface GeneratePoolOptions {
  readonly id?: number;
  readonly name: string;
  readonly displayName: string;
  readonly isTalentNetwork?: boolean;
  readonly isTestPool?: boolean;
  readonly dateCreated?: number;
  readonly isInsourceEnabled?: boolean;
  readonly isCurrencyEnabled?: boolean;
  readonly seoUrl: string;
}

export function generatePoolObject({
  id,
  name,
  displayName,
  isTalentNetwork = false,
  isTestPool = false,
  dateCreated = Date.now(),
  isInsourceEnabled = false,
  isCurrencyEnabled = true,
  seoUrl,
}: GeneratePoolOptions): PoolModel {
  return {
    id: id ?? generateId(),
    name,
    displayName,
    isTalentNetwork,
    isTestPool,
    dateCreated,
    isInsourceEnabled,
    isCurrencyEnabled,
    seoUrl,
  };
}

export function generatePoolObjects(
  pools: readonly GeneratePoolOptions[],
): readonly PoolModel[] {
  return pools.map(generatePoolObject);
}

export function freelancerPool(): Required<GeneratePoolOptions> {
  return {
    id: 1,
    name: 'freelancer',
    displayName: 'Freelancer',
    isTalentNetwork: false,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'freelancer',
  };
}

export function deloittePool(): Required<GeneratePoolOptions> {
  return {
    id: 2,
    name: 'deloitte_dc',
    displayName: 'Deloitte',
    isTalentNetwork: false,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: true,
    isCurrencyEnabled: false,
    seoUrl: 'deloitte-dc',
  };
}

export function facebookPool(): Required<GeneratePoolOptions> {
  return {
    id: 4,
    name: 'facebook',
    displayName: 'Facebook',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'facebook',
  };
}

export function talentNetworkPool(): Required<GeneratePoolOptions> {
  return {
    id: 11,
    name: 'big_talent_marketplace',
    displayName: 'Big Talent Marketplace',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'big-talent-marketplace',
  };
}

export function deloitteFtnPool(): Required<GeneratePoolOptions> {
  return {
    id: 89,
    name: 'deloitte_ftn',
    displayName: 'Freelancer Talent Network',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'deloitte-ftn',
  };
}

export function anotherTalentNetworkPool(): Required<GeneratePoolOptions> {
  return {
    id: 12,
    name: 'spectacular_seeds',
    displayName: 'Spectacular Seeds Ltd',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'spectacular-seeds',
  };
}

export function hpPool(): Required<GeneratePoolOptions> {
  return {
    id: 27,
    name: 'hewlett_packard',
    displayName: 'HP',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'hewlett_packard',
  };
}

export function insourceOnlyPool(): Required<GeneratePoolOptions> {
  return {
    id: 55,
    name: 'insource_only_pool',
    displayName: 'Insource-Only Pool',
    isTalentNetwork: false,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: true,
    isCurrencyEnabled: false,
    seoUrl: 'insource-only-pool',
  };
}

export function freightlancerPool(): Required<GeneratePoolOptions> {
  return {
    id: 123,
    name: 'freightlancer',
    displayName: 'Freightlancer',
    isTalentNetwork: false,
    isTestPool: false,
    dateCreated: new Date(2021, 2, 16).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'freightlancer',
  };
}

export function heygenPool(): Required<GeneratePoolOptions> {
  return {
    id: 99,
    name: 'heygen',
    displayName: 'HeyGen Developer Community',
    isTalentNetwork: true,
    isTestPool: false,
    dateCreated: new Date(2024, 7, 26).getTime(),
    isInsourceEnabled: false,
    isCurrencyEnabled: true,
    seoUrl: 'heygen',
  };
}
