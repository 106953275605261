import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import {
  FontColor,
  FontStyle,
  FontType,
  FontWeight,
  TextSize,
} from '@freelancer/ui/text';
import { colorMap } from './colors-map';

export interface Shade {
  name: string;
  hex: string;
  textColor: 'light' | 'dark';
}

export interface FontShade {
  name: string;
  hex: string;
  textColor: FontColor;
}

interface Color {
  displayName: string;
  name: string;
}

@Component({
  selector: 'app-static-colors',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Colors
        </fl-heading>
        <fl-text
          class="MainContainer-card-content"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Freelancer uses colors purposefully to communicate how things function
          in the interface. This helps us create visual patterns that can make
          interacting with our product easier and more predictable for Employers
          and Freelancers.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Color Palette
        </fl-heading>
        <fl-text
          class="MainContainer-card-content"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'large'"
        >
          All the colour choices we make should take into consideration those
          users who are colour blind or have low visual acuity. Our color
          palette is built with our core principles and guidelines as its
          foundation. This palette will tell you what text colour to use with
          each background.
        </fl-text>
        <div class="ColorsContainer">
          <fl-card
            class="ColorsCard"
            *ngFor="let color of colors; trackBy: trackByColorName"
            [edgeToEdge]="true"
            [flMarginRight]="'mid'"
            [flMarginBottom]="'mid'"
          >
            <ng-container
              *ngFor="
                let shade of colorsMap[color.name];
                let i = index;
                trackBy: trackByShadeName
              "
            >
              <div
                class="ColorsCard-item--title"
                *ngIf="i === 0"
                [ngStyle]="{
                  background: shade.hex
                }"
              >
                <fl-text
                  [size]="TextSize.SMALL"
                  [weight]="FontWeight.BOLD"
                  [color]="shade.textColor"
                >
                  {{ color.displayName }}
                </fl-text>
              </div>
              <div
                class="ColorsCard-item"
                [ngStyle]="{ background: shade.hex }"
              >
                <fl-text [color]="shade.textColor"> {{ shade.name }} </fl-text>
                <fl-text [color]="shade.textColor">
                  {{ shade.hex }}
                </fl-text>
              </div>
            </ng-container>
          </fl-card>
        </div>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss', './colors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsComponent implements OnInit {
  FontColor = FontColor;
  TextSize = TextSize;
  FontStyle = FontStyle;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;

  colorsMap: { [k: string]: readonly FontShade[] };
  colors: readonly Color[];

  ngOnInit(): void {
    // FIXME: T225500 - remove FontShare once T225500 is out.
    // Introduced FontShare object similar to Shade but having textColor as
    // FontColor. This prevents type conflict between bits generated
    // 'light' | 'dark' and FontColor.
    this.colorsMap = colorMap as { [k: string]: readonly FontShade[] };
    this.colors = Object.keys(this.colorsMap).map(color => ({
      name: color,
      displayName:
        color.substring(1).charAt(0).toUpperCase() + color.substring(2),
    }));
  }

  trackByColorName(_: number, color: Color): string {
    return color.name;
  }

  trackByShadeName(_: number, shade: FontShade): string {
    return shade.name;
  }
}
