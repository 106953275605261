import { generateId } from '@freelancer/datastore/testing/helpers';
import type { VerificationAddressDocumentType } from '../verification-address-document-types';
import { generateVerificationAddressDocumentTypesObjects } from '../verification-address-document-types';
import type { VerificationIdType } from '../verification-id-types';
import { generateVerificationIdTypesObjects } from '../verification-id-types';
import type {
  DateLabel,
  KYCRequest,
  RejectReason,
  VerificationAddressDetails,
  VerificationIDDetails,
  VerificationKeycodeDetails,
  VerificationRequest,
} from './verification-request.model';
import {
  VerificationDetailStatus,
  VerificationRequestStatus,
} from './verification-request.model';

export interface GenerateVerificationRequestOptions {
  readonly id?: number;
  readonly userId: number;
  readonly status?: VerificationRequestStatus;
  readonly details?: KYCRequest;
}

interface GenerateKYCRequestOptions {
  readonly idDetails?: VerificationIDDetails;
  readonly keycodeDetails?: VerificationKeycodeDetails;
  readonly addressDetails?: VerificationAddressDetails;
  readonly countryCode?: string;
}

interface GenerateIDDetailsOptions {
  readonly birthDate?: DateLabel;
  readonly firstName?: string;
  readonly idNumber?: string;
  readonly idIssuingCountry?: string;
  readonly idExpiryDate?: DateLabel;
  readonly idType?: VerificationIdType;
  readonly lastName?: string;
  readonly rejectReasons?: readonly RejectReason[];
  readonly status?: VerificationDetailStatus;
}

interface GenerateKeycodeDetailsOptions {
  readonly keycode?: string;
  readonly rejectReasons?: readonly RejectReason[];
  readonly status?: VerificationDetailStatus;
}

interface GenerateAddressDetailsOptions {
  readonly addressOne?: string;
  readonly addressTwo?: string;
  readonly city?: string;
  readonly dateIssued?: DateLabel;
  readonly country?: string;
  readonly institutionName?: string;
  readonly documentType?: VerificationAddressDocumentType;
  readonly postalCode?: string;
  readonly rejectReasons?: readonly RejectReason[];
  readonly state?: string;
  readonly status?: VerificationDetailStatus;
}

export function generateVerificationRequestObject({
  id = generateId(),
  userId,
  status = VerificationRequestStatus.STATUS_KYC_PENDING,
  details = generateKYCRequest(),
}: GenerateVerificationRequestOptions): VerificationRequest {
  return {
    id,
    userId,
    status,
    details,
  };
}

function generateKYCRequest({
  idDetails = generateIdDetails(),
  keycodeDetails = generateKeycodeDetails(),
  addressDetails = generateAddressDetails(),
  countryCode = 'SG',
}: GenerateKYCRequestOptions = {}): KYCRequest {
  return {
    idDetails,
    keycodeDetails,
    addressDetails,
    countryCode,
  };
}

function generateIdDetails({
  birthDate = getBirthDate(),
  firstName = 'Test Firstname',
  idNumber = generateId().toString(),
  idIssuingCountry = 'SG',
  idExpiryDate = getExpiryDate(),
  idType = generateVerificationIdTypesObjects()[0],
  lastName = 'Test Lastname',
  rejectReasons,
  status = VerificationDetailStatus.PENDING,
}: GenerateIDDetailsOptions = {}): VerificationIDDetails {
  return {
    birthDate,
    firstName,
    idNumber,
    idIssuingCountry,
    idExpiryDate,
    idType,
    lastName,
    rejectReasons,
    status,
  };
}

function getBirthDate(): DateLabel {
  // Return mock birthdate
  return {
    year: 1997,
    month: 4,
    day: 1,
  };
}

function getIssueDate(): DateLabel {
  // Issue date should be earlier or equal to the current date
  const issueDate = new Date(Date.now() - 48 * 60 * 60 * 1000);

  return {
    year: issueDate.getFullYear(),
    month: issueDate.getMonth(),
    day: issueDate.getDate(),
  };
}

function getExpiryDate(): DateLabel {
  // Expiry date should be later than the current date
  const expiryDate = new Date(Date.now() + 48 * 60 * 60 * 1000);

  return {
    year: expiryDate.getFullYear(),
    month: expiryDate.getMonth(),
    day: expiryDate.getDate(),
  };
}

function getKeycode(): string {
  // Return mock keycode
  return 'D62EA9AL';
}

function generateKeycodeDetails({
  keycode = getKeycode(),
  rejectReasons,
  status = VerificationDetailStatus.PENDING,
}: GenerateKeycodeDetailsOptions = {}): VerificationKeycodeDetails {
  return {
    keycode,
    rejectReasons,
    status,
  };
}

function generateAddressDetails({
  addressOne = 'Test Address One',
  addressTwo = 'Test Address Two',
  city = 'Test City',
  country = 'Singapore',
  dateIssued = undefined,
  documentType = undefined,
  institutionName = 'Test Institution Name',
  postalCode = '1111',
  rejectReasons,
  state = 'Test State',
  status = VerificationDetailStatus.PENDING,
}: GenerateAddressDetailsOptions = {}): VerificationAddressDetails {
  return {
    addressOne,
    addressTwo,
    city,
    country,
    dateIssued,
    documentType,
    institutionName,
    postalCode,
    rejectReasons,
    state,
    status,
  };
}

function generateIdDetailsRejectReasons(): readonly RejectReason[] {
  const docSubmissionId = generateId();
  return [
    {
      id: generateId(),
      description:
        'The image quality of the documents you uploaded does not meet our minimum standard. Please resubmit using higher quality images and make sure that its details can properly be read.',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 2,
      name: 'Document Quality',
      isCustom: false,
    },
    {
      id: generateId(),
      description:
        'Please have RANDOM_NAME submit their own Proof of Identity and verify this account. Please note that failure to comply will lead to the closure of this account. This is your final warning.',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 86,
      name: 'Another Person is Verifying the account',
      isCustom: true,
    },
  ];
}

function generateKeycodeDetailsRejectReasons(): readonly RejectReason[] {
  const docSubmissionId = generateId();
  return [
    {
      id: generateId(),
      description:
        'Please submit a photo of yourself holding a valid government- issued ID and a piece of paper with the correct unique keycode. Make sure that your face, the keycode, and the details of your ID are clearly visible. Refer to this image: https://prnt.sc/eik5vw',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 29,
      name: 'Invalid ID on KV',
      isCustom: false,
    },
    {
      id: generateId(),
      description:
        'This is a semi-custom reason with a similar name to the first reason.',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 162,
      name: 'Invalid ID on KV',
      isCustom: true,
    },
  ];
}

function generateAddressDetailsRejectReasons(): readonly RejectReason[] {
  const docSubmissionId = generateId();
  return [
    {
      id: generateId(),
      description:
        'Please submit a scanned or digital copy of your bank statement or utility bill that would have both your name and address issued within the last three months.',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 15,
      name: 'No POA',
      isCustom: false,
    },
    {
      id: generateId(),
      description:
        'You have submitted a document that was already rejected. *insert custom message here*',
      limitAccountDocSubmissionId: docSubmissionId,
      limitAccountRejectReasonTagId: 149,
      name: 'User resubmits the same document that was rejected',
      isCustom: true,
    },
  ];
}

// Mixins

export function approvedKycRequest(): Pick<
  GenerateVerificationRequestOptions,
  'status' | 'details'
> {
  return {
    status: VerificationRequestStatus.STATUS_KYC_APPROVE,
    details: {
      countryCode: 'SG',
      idDetails: generateIdDetails({
        status: VerificationDetailStatus.APPROVED,
      }),
      keycodeDetails: generateKeycodeDetails({
        status: VerificationDetailStatus.APPROVED,
      }),
      addressDetails: generateAddressDetails({
        status: VerificationDetailStatus.APPROVED,
      }),
    },
  };
}

export function rejectedKycRequest(): Pick<
  GenerateVerificationRequestOptions,
  'status' | 'details'
> {
  return {
    status: VerificationRequestStatus.STATUS_KYC_REQUEST,
    details: {
      countryCode: 'SG',
      idDetails: generateIdDetails({
        status: VerificationDetailStatus.REJECTED,
        rejectReasons: generateIdDetailsRejectReasons(),
      }),
      keycodeDetails: generateKeycodeDetails({
        status: VerificationDetailStatus.REJECTED,
        rejectReasons: generateKeycodeDetailsRejectReasons(),
      }),
      addressDetails: generateAddressDetails({
        status: VerificationDetailStatus.REJECTED,
        rejectReasons: generateAddressDetailsRejectReasons(),
      }),
    },
  };
}

export function incompleteKycRequest(): Pick<
  GenerateVerificationRequestOptions,
  'status' | 'details'
> {
  return {
    status: VerificationRequestStatus.STATUS_KYC_REQUEST,
    details: {
      countryCode: 'SG',
      idDetails: {
        status: VerificationDetailStatus.REQUEST,
      },
      keycodeDetails: {
        status: VerificationDetailStatus.REQUEST,
        keycode: getKeycode(),
      },
      addressDetails: {
        status: VerificationDetailStatus.REQUEST,
      },
    },
  };
}

export function unsubmittedKycRequestDetails(): Pick<
  GenerateVerificationRequestOptions,
  'status' | 'details'
> {
  return {
    status: VerificationRequestStatus.STATUS_KYC_REQUEST,
    details: generateKYCRequest({
      countryCode: 'SG',
      idDetails: generateIdDetails({
        status: VerificationDetailStatus.REQUEST,
      }),
      keycodeDetails: generateKeycodeDetails({
        status: VerificationDetailStatus.REQUEST,
      }),
      addressDetails: generateAddressDetails({
        dateIssued: getIssueDate(),
        documentType: generateVerificationAddressDocumentTypesObjects()[0],
        status: VerificationDetailStatus.REQUEST,
      }),
    }),
  };
}

export function pendingKycRequestWithAddressDocument(): Pick<
  GenerateVerificationRequestOptions,
  'status' | 'details'
> {
  return {
    status: VerificationRequestStatus.STATUS_KYC_PENDING,
    details: {
      countryCode: 'SG',
      idDetails: generateIdDetails({ idIssuingCountry: 'PH' }),
      keycodeDetails: generateKeycodeDetails(),
      addressDetails: generateAddressDetails({
        dateIssued: getIssueDate(),
        documentType: generateVerificationAddressDocumentTypesObjects()[0],
      }),
    },
  };
}

export function idDetailsOnly(): Pick<
  GenerateVerificationRequestOptions,
  'details'
> {
  return {
    details: {
      countryCode: 'SG',
      idDetails: generateIdDetails(),
    },
  };
}

export function specificDetails({
  id = true,
  keycode = true,
  address = true,
}): Pick<GenerateVerificationRequestOptions, 'details'> {
  return {
    details: {
      countryCode: 'SG',
      idDetails: id ? generateIdDetails({}) : undefined,
      keycodeDetails: keycode ? generateKeycodeDetails() : undefined,
      addressDetails: address
        ? generateAddressDetails({
            dateIssued: getIssueDate(),
            documentType: generateVerificationAddressDocumentTypesObjects()[0],
          })
        : undefined,
    },
  };
}
