import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { FlagSize } from '@freelancer/ui/flag';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { PictureDisplay, PictureImage } from '@freelancer/ui/picture';
import { FontColor, TextSize } from '@freelancer/ui/text';
import { isDefined, isEqual } from '@freelancer/utils';
import { Subscription } from 'rxjs';
import { generateUniqueID } from '../helpers/helpers';
import { isSelectGroups, isSelectItem } from './select.helpers';
import type {
  SelectAcceptedType,
  SelectGroups,
  SelectItem,
} from './select.types';
import { SelectSize, SelectTextColor } from './select.types';

@Component({
  selector: 'fl-select',
  template: `
    <div
      class="InputContainer"
      [class.Error]="control.invalid && control.dirty"
      [flMarginBottom]="
        control.invalid && control.dirty ? 'xxxsmall' : undefined
      "
      [attr.data-focus]="attrFocus"
      [attr.disabled]="attrDisabled"
      [attr.data-flag]="flagStartCountryCode || imageStart ? true : false"
      [attr.data-size]="size"
      [attr.data-borderless]="borderless"
      [attr.data-transparent-background]="transparentBackground"
    >
      <div
        class="InputFigure"
        *ngIf="flagStartCountryCode || imageStart"
      >
        <fl-flag
          *ngIf="flagStartCountryCode && !imageStart"
          [countryCode]="flagStartCountryCode"
          [flMarginRight]="'xxsmall'"
          [size]="FlagSize.SMALL"
        ></fl-flag>
        <fl-picture
          *ngIf="imageStart && !flagStartCountryCode"
          [src]="imageStart?.src"
          [alt]="imageStart?.alt"
          [boundedWidth]="true"
          [flMarginRight]="'xxsmall'"
          [display]="PictureDisplay.INLINE"
        ></fl-picture>
      </div>
      <select
        #nativeElement
        class="NativeElement"
        [class.IsPlaceholder]="
          (isControlValueString(control.value) && control.value === '') ||
          !isDefined(control.value)
        "
        [attr.id]="id"
        [attr.data-text-color]="textColor"
        [attr.disabled]="attrDisabled"
        [attr.data-size]="size"
        [attr.data-flag]="flagStartCountryCode || imageStart ? true : false"
        [attr.aria-invalid]="control.invalid"
        [attr.errormessage]="errorId"
        [attr.aria-label]="label"
        [attr.data-transparent-background]="transparentBackground"
        [formControl]="control"
        [compareWith]="deepCompare"
        (focus)="nativeElementOnFocus($event)"
        (blur)="nativeElementOnBlur($event)"
      >
        <ng-container
          *ngFor="let option of attrOptions; trackBy: trackBySelectItem"
        >
          <optgroup
            *ngIf="isSelectGroups(option)"
            class="OptionText"
            [label]="option.groupName"
          >
            <option
              *ngFor="let item of option.options; trackBy: trackBySelectItem"
              class="OptionText"
              [ngValue]="item.value"
              [disabled]="item.disabled"
            >
              {{
                item.displayText.length === 0 && placeholder
                  ? placeholder
                  : item.displayText
              }}
            </option>
          </optgroup>
          <option
            *ngIf="isSelectItem(option)"
            class="OptionText"
            [ngValue]="option.value"
            [disabled]="option.disabled"
          >
            {{
              option.displayText.length === 0 && placeholder
                ? placeholder
                : option.displayText
            }}
          </option>
          <option
            *ngIf="isString(option)"
            class="OptionText"
            [value]="option"
            [disabled]="option.length === 0 && placeholder"
          >
            {{ option.length === 0 && placeholder ? placeholder : option }}
          </option>
        </ng-container>
      </select>
      <fl-icon
        class="SelectIcon"
        [color]="
          textColor === 'foreground' ? IconColor.FOREGROUND : IconColor.LIGHT
        "
        [name]="'ui-chevron-down'"
        [size]="IconSize.XSMALL"
      ></fl-icon>
    </div>
    <fl-validation-error
      [id]="errorId"
      [control]="control"
    ></fl-validation-error>
  `,
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> implements OnChanges, OnDestroy {
  FlagSize = FlagSize;
  FontColor = FontColor;
  IconColor = IconColor;
  IconSize = IconSize;
  PictureDisplay = PictureDisplay;
  TextSize = TextSize;

  isDefined = isDefined;
  isSelectItem = isSelectItem;
  isSelectGroups = isSelectGroups;

  attrFocus?: true;
  attrDisabled?: true;
  attrOptions: readonly SelectAcceptedType<T>[] = [];
  @RepetitiveSubscription()
  private errorSubscription?: Subscription;
  @ViewChild('nativeElement') nativeElement: ElementRef<HTMLSelectElement>;

  errorId: string;

  @Input() set disabled(value: boolean) {
    this.attrDisabled = value ? true : undefined;
  }

  @Input() id: string;
  @Input() size = SelectSize.MID;
  @Input() placeholder?: string;
  @Input() control: FormControl<T>;
  @Input() borderless = false;
  @Input() label?: string;
  @Input() transparentBackground = false;
  @Input() textColor: SelectTextColor = 'foreground';

  // Allow `string` to be specified without being wrapped in a
  // SelectItem or SelectGroups when control accepts `string`
  @Input() set options(
    value: readonly (
      | (T extends string ? string : never)
      | SelectItem<T>
      | SelectGroups<T>
    )[],
  ) {
    this.attrOptions = value ?? [];
    if (this.attrOptions.length > 500) {
      const error = new Error(
        `Loading too many options to select component. this.attrOptions.length: ${this.attrOptions.length}`,
      );
      console.error(error);
      this.errorHandler.handleError(error);
    }
  }
  @Input() flagStartCountryCode?: string;
  /** Image to put in the left side of the select component */
  @Input() imageStart?: PictureImage;

  @Output() readonly onFocus = new EventEmitter<void>();
  @Output() readonly onBlur = new EventEmitter<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private errorHandler: ErrorHandler,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      if (this.errorSubscription) {
        this.errorSubscription.unsubscribe();
      }
      this.errorSubscription = this.control.statusChanges.subscribe(() => {
        this.cd.markForCheck();
      });
    }

    if ('id' in changes) {
      const id = this.id ?? generateUniqueID();
      this.errorId = `${id}-error`;
    }
  }

  ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  isString(option: SelectAcceptedType<T>): option is string {
    return typeof option === 'string';
  }

  isControlValueString(value: T | string): value is string {
    return typeof value === 'string';
  }

  deepCompare(option: any, controlValue: any): boolean {
    return isEqual(option, controlValue);
  }

  nativeElementOnFocus(event: FocusEvent): void {
    this.attrFocus = true;
    this.onFocus.emit();
  }

  nativeElementOnBlur(event: FocusEvent): void {
    this.attrFocus = undefined;
    this.onBlur.emit();
  }

  trackBySelectItem(_: number, item: string): string;
  trackBySelectItem(_: number, item: SelectItem<T> | SelectGroups<T>): T;
  trackBySelectItem(
    _: number,
    item: string | SelectItem<T> | SelectGroups<T>,
  ): string | T {
    if (typeof item === 'string') {
      return item;
    }

    if (isSelectItem(item)) {
      return item.value;
    }

    if (isSelectGroups(item)) {
      return item.groupName;
    }

    return item;
  }
}
