import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-bugs',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Report Bugs
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
          [flMarginBottom]="'small'"
        >
          <fl-text [size]="TextSize.INHERIT">
            We are using
            <fl-link
              [size]="TextSize.INHERIT"
              link="https://phabricator.tools.flnltd.com/project/board/329/query/5nDr_gPlQSHA/"
              >Phabricator</fl-link
            >
            for bug tracking where all known issues are found. If a bug needs to
            be fixed immediately, don’t hesitate to bump the ticket or leave a
            message through #ui-engineering rocket channel. If you are planning
            to work on an issue, please communicate with UI Engineering. Before
            creating a bug report, please make sure you have searched existing
            tickets in our board, and read
            <fl-link
              [size]="TextSize.INHERIT"
              link="/info/faq"
              >our FAQ</fl-link
            >.
          </fl-text>
        </fl-text>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BugsComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
