import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { EmptyComponent } from './base/empty.component';
import { NotFoundComponent } from './base/not-found/not-found.component';
import { AboutComponent } from './base/static-pages/about/about.component';
import { AccessibilityComponent } from './base/static-pages/accessibility/accessibility.component';
import { BrandStyleGuideComponent } from './base/static-pages/brand/brand-style-guide.component';
import { BugsComponent } from './base/static-pages/bugs/bugs.component';
import { ColorsComponent } from './base/static-pages/colors/colors.component';
import { CopywritingComponent } from './base/static-pages/copywriting/copywriting.component';
import { ErrorHandlingComponent } from './base/static-pages/error-handling/error-handling.component';
import { FAQComponent } from './base/static-pages/faq/faq.component';
import { FormsComponent } from './base/static-pages/forms/forms.component';
import { GeneralRulesComponent } from './base/static-pages/general-rules/general-rules.component';
import { GetStartedComponent } from './base/static-pages/get-started/get-started.component';
import { GridComponent } from './base/static-pages/grid/grid.component';
import { DesignContributionComponent } from './base/static-pages/how-to-contribute/design-contribution.component';
import { DeveloperContributionComponent } from './base/static-pages/how-to-contribute/developer-contribution.component';
import { HowToContributeComponent } from './base/static-pages/how-to-contribute/how-to-contribute.component';
import { IllustrationsComponent } from './base/static-pages/illustrations/illustrations.component';
import { LayoutsComponent } from './base/static-pages/layouts/layouts.component';
import { ModalsComponent } from './base/static-pages/modals/modals.component';
import { AttentionSeekerComponent } from './base/static-pages/motion-system/attention-seeker/attention-seeker.component';
import { FadeComponent } from './base/static-pages/motion-system/fade/fade.component';
import { GettingStartedComponent } from './base/static-pages/motion-system/getting-started.component';
import { SlideComponent } from './base/static-pages/motion-system/slide/slide.component';
import { SpacingComponent } from './base/static-pages/spacing/spacing.component';
import { TypographyComponent } from './base/static-pages/typography/typography.component';
import { BitsGuard } from './bits-guard.service';
import { componentRoutes } from './generated/component.routes';
import { directiveRoutes } from './generated/directive.routes';
import { patternRoutes } from './generated/patterns.routes';
import { pipeRoutes } from './generated/pipe.routes';
import { HomeComponent } from './home/home.component';

// WARNING: Non lazy-loaded components are stricly forbidden here: you MUST use
// loadChildren if you want to add a new top-level route.
export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '',
    children: [
      {
        path: 'info',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: 'introduction',
            pathMatch: 'full',
          },
          {
            path: 'introduction',
            component: AboutComponent,
          },
          {
            path: 'getting-started',
            component: GetStartedComponent,
          },
          {
            path: 'faq',
            component: FAQComponent,
          },
          {
            path: 'how-to-contribute',
            component: HowToContributeComponent,
            canActivate: [BitsGuard],
            data: {
              internalOnly: true,
            },
            children: [
              {
                path: '',
                redirectTo: 'develop',
                pathMatch: 'full',
              },
              {
                path: 'design',
                component: DesignContributionComponent,
              },
              {
                path: 'develop',
                component: DeveloperContributionComponent,
              },
            ],
          },
          {
            path: 'report-bugs',
            component: BugsComponent,
            canActivate: [BitsGuard],
            data: {
              internalOnly: true,
            },
          },
          {
            path: 'modals',
            component: ModalsComponent,
          },
          {
            path: 'general-rules',
            component: GeneralRulesComponent,
          },
        ],
      },
      {
        path: 'foundations',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: 'accessibility',
            pathMatch: 'full',
          },
          {
            path: 'accessibility',
            component: AccessibilityComponent,
          },
          {
            path: 'colors',
            component: ColorsComponent,
          },
          {
            path: 'copywriting',
            component: CopywritingComponent,
          },
          {
            path: 'error-handling',
            component: ErrorHandlingComponent,
          },
          {
            path: 'grid',
            component: GridComponent,
          },
          {
            path: 'illustrations',
            component: IllustrationsComponent,
          },
          {
            path: 'layouts',
            component: LayoutsComponent,
          },
          {
            path: 'spacing',
            component: SpacingComponent,
          },
          {
            path: 'typography',
            component: TypographyComponent,
          },
          {
            path: 'forms',
            component: FormsComponent,
          },
        ],
      },
      {
        path: 'components',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: componentRoutes[0].path,
            pathMatch: 'full',
          },
          ...componentRoutes,
        ],
      },
      {
        path: 'patterns',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: patternRoutes[0].path,
            pathMatch: 'full',
          },
          ...patternRoutes,
        ],
      },
      {
        path: 'directives',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: directiveRoutes[0].path,
            pathMatch: 'full',
          },
          ...directiveRoutes,
        ],
      },
      {
        path: 'pipes',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: pipeRoutes[0].path,
            pathMatch: 'full',
          },
          ...pipeRoutes,
        ],
      },
      {
        path: 'motion',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: 'getting-started',
            pathMatch: 'full',
          },
          {
            path: 'getting-started',
            component: GettingStartedComponent,
          },
          {
            path: 'attention-seeker',
            component: AttentionSeekerComponent,
          },
          {
            path: 'fade',
            component: FadeComponent,
          },
          {
            path: 'slide',
            component: SlideComponent,
          },
        ],
      },
      {
        path: 'brand',
        component: BaseComponent,
        children: [
          {
            path: '',
            redirectTo: 'brand-style-guide',
            pathMatch: 'full',
          },
          {
            path: 'brand-style-guide',
            component: BrandStyleGuideComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'skits',
    loadChildren: () =>
      import('bits/skits/skits.module').then(m => m.SkitsModule),
  },
  {
    path: 'internal/blank',
    component: EmptyComponent,
  },
  {
    path: '',
    children: [
      {
        path: 'internal/404',
        component: NotFoundComponent,
      },
      {
        path: 'internal',
        loadChildren: () =>
          import('bits/internal/internal.module').then(m => m.InternalModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      urlUpdateStrategy: 'eager',
    }),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
