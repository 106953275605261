import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-forms',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Forms
        </fl-heading>

        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-text
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'small'"
          >
            Forms are used to get and collect input from users. They usually
            consist of form elements like input fields, radio boxes, checkboxes,
            etc.
          </fl-text>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxsmall'"
          >
            General
          </fl-heading>
          <fl-list
            class="List"
            [type]="ListItemType.ORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Optional fields - include "(Optional)" texts in the label for
              fields that are not required.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <div>
                Required fields
                <fl-list
                  class="List"
                  [type]="ListItemType.UNORDERED"
                >
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Required fields do not need asterisks within the label.
                  </fl-list-item>
                  <fl-list-item>
                    Do not validate if the user is still typing.
                  </fl-list-item>
                </fl-list>
              </div>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <div>
                Form validations
                <fl-list
                  class="List"
                  [type]="ListItemType.UNORDERED"
                >
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Display clearly that there is an error.
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Highlight the fields that have an error.
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Provide information what needs to be done.
                  </fl-list-item>
                  <fl-list-item [flMarginBottom]="'xxxsmall'">
                    Validate when a user leaves the field.
                  </fl-list-item>
                  <fl-list-item>
                    Don't use tooltips for error message
                  </fl-list-item>
                </fl-list>
              </div>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Group related fields or labels
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Consider translations for placeholders and labels. The length of
              the text can change quite significantly.
            </fl-list-item>
          </fl-list>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxsmall'"
          >
            Placeholders
          </fl-heading>
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Placeholders shouldn't be truncated.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Placeholders should be a specific example of the intended input
              you expect from the user, not a repeat of the input label.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Fields should always have placeholders.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <div>
                See
                <fl-link
                  link="/foundations/copywriting"
                  [size]="TextSize.INHERIT"
                >
                  guidelines
                </fl-link>
                for writing copies.
              </div>
            </fl-list-item>
          </fl-list>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.INHERIT"
            [flMarginBottom]="'xxsmall'"
          >
            Writing Error Messages
          </fl-heading>
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Error messages should be easy to understand.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Any incorrect fields should be easy to locate
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Users shouldn't have to memorize the instructions for fixing the
              error.
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss', './forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsComponent {
  TextSize = TextSize;
  FontType = FontType;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  ListItemType = ListItemType;
}
