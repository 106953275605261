import { generateId } from '@freelancer/datastore/testing/helpers';
import type { FlareActionMap } from './flare-action-map.model';

export interface GenerateFlareActionMap {
  readonly id?: string;
  readonly flareCheckpointId: number;
  readonly actions: { readonly [key: string]: boolean };
}

/**
 * Create a Freelancer Automation Rule Engine (FLARE) action map object
 */
export function generateFlareActionMapObject({
  id = generateId().toString(),
  flareCheckpointId,
  actions,
}: GenerateFlareActionMap): FlareActionMap {
  return {
    id,
    flareCheckpointId,
    actions,
  };
}
