import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { FontColor, FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-illustrations',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Illustrations
        </fl-heading>

        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
        >
          We currently have the following illustrations in
          webapp/src/illustrations folder.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
        >
          If new illustrations will be added, they should be 128px width by
          128px height.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
          [flMarginBottom]="'small'"
        >
          To use this illustrations, they should be added with fl-picture
          component.
        </fl-text>
        <fl-text
          [color]="FontColor.ERROR"
          [flMarginBottom]="'small'"
          [weight]="FontWeight.BOLD"
        >
          Click Illustration to copy name to clipboard
        </fl-text>
        <app-static-illustrations-list
          titleText="Alerts"
          [sources]="alertPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Nav"
          [sources]="navPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Location"
          [sources]="locationPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Tech"
          [sources]="techPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Email"
          [sources]="emailPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Mobile"
          [sources]="mobilePictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Comms"
          [sources]="commsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Membership"
          [sources]="membershipPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Payment"
          [sources]="paymentPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Verify"
          [sources]="verifyPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Project"
          [sources]="projectPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Job"
          [sources]="jobPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Contest"
          [sources]="contestPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Milestone"
          [sources]="milestonesPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Time"
          [sources]="timePictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Freelancer"
          [sources]="freelancerPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Employer"
          [sources]="employerPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Document"
          [sources]="documentsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Bids"
          [sources]="bidsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Portfolio"
          [sources]="portfolioPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Skills"
          [sources]="skillsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Quality Rewards"
          [sources]="qualityRewardsPictureSrcs"
        ></app-static-illustrations-list>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationsComponent {
  FontColor = FontColor;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  TextSize = TextSize;

  alertPictureSrcs: readonly string[] = [
    'illustrations/alerts/empty-state.svg',
    'illustrations/alerts/sad-face.svg',
    'illustrations/alerts/search.svg',
    'illustrations/alerts/warning-message.svg',
  ];

  navPictureSrcs: readonly string[] = [
    'illustrations/nav/how-it-works.svg',
    'illustrations/nav/introduction.svg',
    'illustrations/nav/what-is-the-process.svg',
  ];

  locationPictureSrcs: readonly string[] = [
    'illustrations/location/international-or-local.svg',
    'illustrations/location/language.svg',
    'illustrations/location/location.svg',
    'illustrations/location/translation.svg',
  ];

  techPictureSrcs: readonly string[] = [
    'illustrations/tech/automate-workflow.svg',
    'illustrations/tech/web-and-mobile.svg',
  ];

  emailPictureSrcs: readonly string[] = [
    'illustrations/email/check-email.svg',
    'illustrations/email/email-verify.svg',
    'illustrations/email/send.svg',
  ];

  mobilePictureSrcs: readonly string[] = [
    'illustrations/mobile/mobile-code.svg',
    'illustrations/mobile/mobile-verify.svg',
  ];

  commsPictureSrcs: readonly string[] = [
    'illustrations/comms/live-chat.svg',
    'illustrations/comms/notification.svg',
    'illustrations/comms/support.svg',
  ];

  membershipPictureSrcs: readonly string[] = [
    'illustrations/membership/expert-recruiter.svg',
    'illustrations/membership/free-trial-membership.svg',
    'illustrations/membership/preferred-freelancer.svg',
    'illustrations/membership/reactivate-account.svg',
  ];

  paymentPictureSrcs: readonly string[] = [
    'illustrations/payment/express-withdrawal.svg',
    'illustrations/payment/external-invoicing.svg',
    'illustrations/payment/get-paid.svg',
    'illustrations/payment/get-paid-faster.svg',
    'illustrations/payment/link-accounts.svg',
    'illustrations/payment/make-money.svg',
    'illustrations/payment/pay-safely.svg',
    'illustrations/payment/payment-successful-alt.svg',
    'illustrations/payment/payment-successful.svg',
    'illustrations/payment/security-alt.svg',
    'illustrations/payment/security.svg',
    'illustrations/payment/verify-payment-method.svg',
    'illustrations/payment/wire-transfer-alt.svg',
  ];

  verifyPictureSrcs: readonly string[] = [
    'illustrations/verify/custom-cover-photo.svg',
    'illustrations/verify/identity-verified.svg',
    'illustrations/verify/proof-of-identity.svg',
    'illustrations/verify/tick-confirmation.svg',
    'illustrations/verify/verify-your-identity.svg',
  ];

  projectPictureSrcs: readonly string[] = [
    'illustrations/project/fixed-project.svg',
    'illustrations/project/post-a-project.svg',
    'illustrations/project/standard-project.svg',
  ];

  jobPictureSrcs: readonly string[] = [
    'illustrations/job/job-profeciency.svg',
    'illustrations/job/jobs.svg',
    'illustrations/job/post-a-job.svg',
  ];

  contestPictureSrcs: readonly string[] = [
    'illustrations/contest/contest.svg',
    'illustrations/contest/get-awarded.svg',
  ];

  milestonesPictureSrcs: readonly string[] = [
    'illustrations/milestones/create-milestone.svg',
    'illustrations/milestones/milestone-payment.svg',
    'illustrations/milestones/release-milestone-alt.svg',
    'illustrations/milestones/release-milestone.svg',
    'illustrations/milestones/secure-milestone.svg',
  ];

  timePictureSrcs: readonly string[] = [
    'illustrations/time/time-tracking.svg',
    'illustrations/time/track-progress.svg',
  ];

  freelancerPictureSrcs: readonly string[] = [
    'illustrations/freelancer/about-me.svg',
    'illustrations/freelancer/choose-a-freelancer.svg',
    'illustrations/freelancer/freelancer-requirements.svg',
    'illustrations/freelancer/freelancers.svg',
    'illustrations/freelancer/hire-at-a-fraction-of-the-cost.svg',
    'illustrations/freelancer/work.svg',
  ];

  employerPictureSrcs: readonly string[] = [
    'illustrations/employer/dont-do-it-yourself.svg',
  ];

  documentsPictureSrcs: readonly string[] = [
    'illustrations/documents/content.svg',
    'illustrations/documents/ip-agreement.svg',
    'illustrations/documents/sign.svg',
  ];

  bidsPictureSrcs: readonly string[] = [
    'illustrations/bids/bids-alt.svg',
    'illustrations/bids/bids.svg',
    'illustrations/bids/high-value-bidding.svg',
    'illustrations/bids/place-bids.svg',
  ];

  portfolioPictureSrcs: readonly string[] = [
    'illustrations/portfolio/browse-portfolios.svg',
    'illustrations/portfolio/portfolio.svg',
  ];

  skillsPictureSrcs: readonly string[] = [
    'illustrations/skills/design.svg',
    'illustrations/skills/education.svg',
    'illustrations/skills/graphic-design.svg',
    'illustrations/skills/high-demand-skills.svg',
    'illustrations/skills/skills-ranking.svg',
    'illustrations/skills/skills.svg',
  ];

  qualityRewardsPictureSrcs: readonly string[] = [
    'illustrations/quality-rewards/bookmark.svg',
    'illustrations/quality-rewards/exclusive-invitation.svg',
    'illustrations/quality-rewards/flexibility.svg',
    'illustrations/quality-rewards/get-awarded.svg',
    'illustrations/quality-rewards/quality-work.svg',
    'illustrations/quality-rewards/rank-higher.svg',
    'illustrations/quality-rewards/rewards.svg',
  ];
}
