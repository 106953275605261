import { isPlatformBrowser } from '@angular/common';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';

export function getScreenSize(
  platformId: Object,
): 'desktop' | 'tablet' | 'mobile' | undefined {
  if (isPlatformBrowser(platformId)) {
    return window.innerWidth >=
      parseInt(FreelancerBreakpointValues.DESKTOP_SMALL, 10)
      ? 'desktop'
      : window.innerWidth <
          parseInt(FreelancerBreakpointValues.DESKTOP_SMALL, 10) &&
        window.innerWidth >= parseInt(FreelancerBreakpointValues.TABLET, 10)
      ? 'tablet'
      : 'mobile';
  }
}
