import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-copywriting',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Principles
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          The purpose of these copywriting principles are to serve as a single
          source of truth for all Freelancer staff when writing within the
          Freelancer product. This style guide is a living document, and will
          continue to be updated to reflect new trends, cultural developments
          and use cases.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          The primary goal of product copy is to provide clear information so
          that users can efficiently interact with the product. To achieve this
          in product copy must be:
        </fl-text>
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <div>
              <fl-text
                [size]="TextSize.XSMALL"
                [weight]="FontWeight.BOLD"
                [fontType]="FontType.SPAN"
              >
                Clear.
              </fl-text>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
              >
                Understand the topic you’re writing about. Use simple words and
                sentences. Strip away verbose language and distractions as many
                of our customers don't speak English as a first language.
              </fl-text>
            </div>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <div>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
                [weight]="FontWeight.BOLD"
              >
                Useful.
              </fl-text>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
              >
                Before you start writing, ask yourself: What purpose does this
                serve? Who is going to read it? What do they need to know?
              </fl-text>
            </div>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <div>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
                [weight]="FontWeight.BOLD"
              >
                Friendly.
              </fl-text>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
              >
                Write like a human. All of our content, from splashy homepage
                copy to system alerts, should be warm and human. Use
                contractions and write as though you’re speaking directly to the
                reader.
              </fl-text>
            </div>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <div>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
                [weight]="FontWeight.BOLD"
              >
                Avoid jargon.
              </fl-text>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
              >
                Remove technical terms. “Error code 1234”
              </fl-text>
            </div>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <div>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
                [weight]="FontWeight.BOLD"
              >
                Concise.
              </fl-text>
              <fl-text
                [size]="TextSize.XSMALL"
                [fontType]="FontType.SPAN"
              >
                Use short words and sentences. Avoid unnecessary modifiers. Be
                specific. Avoid vague language. Cut the fluff.
              </fl-text>
            </div>
          </fl-list-item>
        </fl-list>
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          Active voice
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          Use active voice.The typical structure is subject (person/thing
          acting) verb (the action) object (receives the action).
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          In active voice, the subject of the sentence does the action. In
          passive voice, the subject of the sentence has the action done to it.
        </fl-text>
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <fl-text
              [size]="TextSize.XSMALL"
              [fontType]="FontType.SPAN"
            >
              Yes: Marti logged into the account.
            </fl-text>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <fl-text
              [size]="TextSize.XSMALL"
              [fontType]="FontType.SPAN"
            >
              No: The account was logged into by Marti.
            </fl-text>
          </fl-list-item>
        </fl-list>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Words like “was” and “by” may indicate that you’re writing in passive
          voice. Scan for these words and rework sentences where they appear.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Translations
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Freelancer serves millions of users in hundreds of countries and
          territories. It's much easier for a translator to clearly communicate
          ideas written in straightforward, uncomplicated sentences.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Use active voice.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Avoid double negatives.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Use contractions with caution.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Avoid using synonyms for the same word in a single piece of
              writing.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Write briefly, but don’t sacrifice clarity for brevity. You may
              need to repeat or add words to make the meaning of your sentences
              clear to a translator.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Avoid slang, idioms, and cliches.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Avoid unnecessary abbreviations.
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Write in US English
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          We’ve positioned ourselves as a .com, not a .com.au or .co.uk. Our
          users come from all over the world, but write to a US audience. This
          means no OUs, lots of Zs and R after E. It also means you traveled
          illegally instead of travelled illegally, forged a check instead of a
          cheque, faced judgment instead of judgement, got sent to jail instead
          of gaol and ended up in a correctional center, not a correctional
          centre.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Grammar and spelling
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          We adhere to the Associated Press Stylebook for capitalization,
          abbreviations and grammar. We use Merriam-Webster’s Collegiate
          Dictionary for spelling.
        </fl-text>
      </fl-card>

      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Mechanics
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Adhering to certain rules of grammar and mechanics helps us keep our
          writing clear and consistent
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Capitalization
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          We use a few different forms of capitalization, however by default,
          all copy should be written in sentence case, by capitalizing the first
          letter of each sentence. Subsequent words should be written in
          lowercase letters. Proper nouns (companies, products,countries,
          people) are an exception. Never use all capital letters when
          constructing words.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Learn more about Freelancer.com here.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Become a Preferred Freelancer.
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Buttons should be written with the title case with each word
          capitalized, except when words are articles, prepositions, or
          conjunctions.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Email Us
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              My Projects
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Terms and Conditions
            </fl-list-item>
          </fl-list>
        </fl-text>

        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Numbers
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Spell out a number when it begins a sentence. Otherwise, use the
          numeral. This includes ordinals.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Ten new employees started on Monday, and 12 start next week
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              I ate 3 donuts at Coffee Hour
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Meg won 1st place in last year’s Walktober contest
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              We hosted a group of 8th graders who are learning to code
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Numbers over 3 digits get commas:
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 999 </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 1,000 </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 150,000 </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Numbers should be written in full but can be abbreviated if there are
          space restrictions.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              1.2k (lowercase k for thousand)
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              1.2M (capital M for million)
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Using our
          <fl-link
            [size]="TextSize.XSMALL"
            [link]="'https://bits.freelancer.com/pipes/date-range/default'"
          >
            Freelancer date range pipe
          </fl-link>
          will avoid having to format dates manually, however here is an
          overview. For short dates (numeric dates), use a
          <fl-link
            [size]="TextSize.XSMALL"
            [link]="
              'https://angular.io/api/common/DatePipe#pre-defined-format-options'
            "
          >
            pre-defined format as
          </fl-link>
          these will automatically be localised. These are also preferred for
          longer dates due to nicer localisation, but not enforced.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          As a default use the long date format, the 3-letter month abbreviation
          should be used. Use the short date format only if space is an issue.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Long date: Jan 1, 2019
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Time
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Use the 12-hour clock, then a space, followed by am or pm. Don’t use
          minutes for on-the-hour time.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 7 am </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 7:30 pm </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Time zones
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Include a time zone only if it’s necessary, such as for an event
          listing. Use the time zone’s abbreviation following the am or pm, for
          example, 4:00 pm NZT.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Ranges and spans
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Use an en dash with spaces in between (–) for a fixed range of
          numbers. Create the en dash with keystroke option + shift + hyphen (on
          Macs). In most cases, our formatting pipes will automatically manage
          range formatting as well.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              {{ start | dateRange : end }}
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              3 pm – 4:30 pm
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              {{
                amountRange
                  | flCurrency : currency.code : { withPrecision: false }
              }}
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Money
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Using our
          <fl-link
            [size]="TextSize.XSMALL"
            [link]="
              'https://bits.freelancer.com/pipes/freelancer-currency/default'
            "
          >
            Freelancer currency pipe
          </fl-link>
          will avoid having to format currency manually, however here is an
          overview.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          When writing about currency, use the dollar sign before the amount and
          the currency abbreviation (ISO code) after the amount. Include a
          decimal and number of cents if more than 0.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'"> $20 USD </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              $20.50 USD
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              $20 USD / hour
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              $80 – $100 USD
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              $80 – $100 USD / hour
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Always place the negative symbol before the currency and amount
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              -$20 USD
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Abbreviations and acronyms
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          If there’s a chance your reader won’t recognize an abbreviation or
          acronym, spell it out the first time you mention it. Then use the
          short version for all other references in the same page.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              First use: Australian Business Number (ABN)
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Second use: ABN
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          If the abbreviation or acronym is well known (easily found on a search
          engine), like API or HTML, use it instead (and don’t worry about
          spelling it out).
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Emojis
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Emojis should be avoided when writing in product copy.
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Decimals and fractions
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Spell out fractions. Use decimal points when a number can’t be easily
          written out as a fraction, like 1.375 or 47.2.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Yes: two-thirds
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> No: 2/3 </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Exclamation points
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Use exclamation points sparingly, and never more than one at a time.
          They’re like high-fives: A well-timed one is great, but too many can
          be annoying. Never use exclamation points in failure messages or
          alerts. When in doubt, avoid!
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Ampersands (&)
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Don't use ampersands unless one is part of a company or brand name.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Example: Ben & Jerry’s
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          URLs and websites
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Capitalize the names of websites and web publications. Don’t
          italicize. Avoid spelling out URLs, but when you need to, leave off
          the
          <fl-link
            [size]="TextSize.XSMALL"
            [link]="'http://www'"
          >
            http://www
          </fl-link>
          .
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Example: Freelancer.com
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Example: Upwork.com
            </fl-list-item>
          </fl-list>
        </fl-text>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Units of measurement
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          For storage and memory sizes, the unit of measurement should be
          uppercase. For most other units, use lowercase according to ISO
          standards (kg, cm, lbs). Include a space between the number and unit
          of measurement.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 512 kB </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 1 GB </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'"> 1 TB </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-card>
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Freelancer Vocabulary
        </fl-heading>
        <div class="VocabularyTable">
          <div class="VocabularyTable-wrapper">
            <div class="VocabularyTable-leftCol">
              <div class="VocabularyTable-row">
                <fl-text
                  [weight]="FontWeight.BOLD"
                  [size]="TextSize.XSMALL"
                >
                  Do use
                </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL"> milestone payment </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL"> job owner </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL"> freelancer </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL">
                  Freelancer (default) or Freelancer.com (if required)
                </fl-text>
              </div>
            </div>
            <div class="VocabularyTable-rightCol">
              <div class="VocabularyTable-row">
                <fl-text
                  [weight]="FontWeight.BOLD"
                  [size]="TextSize.XSMALL"
                >
                  Don’t use
                </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL"> milestone, escrow </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL">
                  buyer, clients, employer, consumer
                </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL"> seller, worker </fl-text>
              </div>
              <div class="VocabularyTable-row">
                <fl-text [size]="TextSize.XSMALL">
                  Freelancer.com, freelancer.com, FLN, FL
                </fl-text>
              </div>
            </div>
          </div>
        </div>
      </fl-card>
    </div>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopywritingComponent {
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;

  start = new Date('January 1, 2019 00:00:00').getTime();
  end = new Date('January 2, 2019 05:00:00').getTime();

  currency = {
    code: 'USD',
  };

  amountRange = { minimum: 80, maximum: 100 };
}
