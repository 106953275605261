import type {
  CountryCode,
  CustomFieldValue,
  ExperiencesCollection,
  MilestoneRequestsCollection,
  NotificationSettingsCollection,
  ProfileCategoriesCollection,
  ProjectViewBidsCollection,
  ProjectViewProjectsCollection,
  QuotationItemRevisionsCollection,
  ThreadsCollection,
} from '@freelancer/datastore/collections';
import {
  ContestHandoverUpdateAction,
  FieldType,
  MilestoneRequestAction,
  countries,
  withMessage,
  type ContestHandoversCollection,
} from '@freelancer/datastore/collections';
import { deepSpread, uniq } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import { isDefined } from '@freelancer/utils';
import {
  PrivacyLevelApi,
  ThreadTypeApi,
} from 'api-typings/messages/messages_types';
import {
  MilestoneRequestStatusApi,
  ProjectStatusApi,
} from 'api-typings/projects/projects';
import { addUpdateTransformer, createThread } from './document-creators';

export function addUpdateTransformers(): void {
  addUpdateTransformer<ExperiencesCollection>(
    'experiences',
    (_, document, delta) => ({
      ...document,
      ...delta,
      country: delta.countryCode
        ? countries[delta.countryCode as CountryCode].name
        : document.country,
    }),
  );

  addUpdateTransformer<ProjectViewBidsCollection>(
    'projectViewBids',
    (authUid, document, delta) => {
      return {
        ...deepSpread(document, delta),
        shortlisted:
          delta.shortlisted !== undefined &&
          delta.shortlisted !== document.shortlisted
            ? delta.shortlisted
            : document.shortlisted,
      };
    },
  );

  addUpdateTransformer<ProjectViewProjectsCollection>(
    'projectViewProjects',
    (authUid, project, delta) => {
      let deltaWithCustomFieldValues = delta;

      // Update project custom field values
      if (delta.customFieldValues) {
        const customFieldInfoConfigurations = uniq(delta.customFieldValues);

        // Remove all the customFieldValue with the same customFieldInfoConfigurationId as the result
        // because we will overwrite them
        const originalEnterpriseMetadataValues = project.customFieldValues
          ? project.customFieldValues.filter(
              value =>
                !customFieldInfoConfigurations.find(
                  config =>
                    config.customFieldInfoConfigurationId ===
                      value.customFieldInfoConfigurationId &&
                    // If it is a child object field, only filter out the field from the same parent object
                    (config.parentId
                      ? config.parentId === value.parentId
                      : true),
                ),
            )
          : [];

        // Filter for custom fields that have a value and are not an object or
        // object property
        const newCustomFieldWithValue = delta.customFieldValues.filter(
          value =>
            isDefined(value.value) &&
            value.type !== FieldType.OBJECT &&
            !isDefined(value.parentObjectGroup),
        );

        // Filter for only objects and object properties. This also removes
        // requests for deleting objects as that is handled above when removing
        // values to overwrite
        const newCustomFieldObjects = delta.customFieldValues.filter(
          value =>
            isDefined(value.objectGroup) || isDefined(value.parentObjectGroup),
        );

        const customFieldObjects: CustomFieldValue[] = [];
        const customFieldObjectIds: { [key: number]: number } = {};

        // Build custom field objects with object ids
        newCustomFieldObjects.forEach(customField => {
          const newCustomField = { ...customField };
          if (customField.objectGroup) {
            if (!customFieldObjectIds[customField.objectGroup]) {
              customFieldObjectIds[customField.objectGroup] = generateId();
            }
            newCustomField.value =
              customFieldObjectIds[customField.objectGroup];
            customFieldObjects.push(newCustomField as CustomFieldValue);
          } else if (customField.parentObjectGroup) {
            if (!customFieldObjectIds[customField.parentObjectGroup]) {
              customFieldObjectIds[customField.parentObjectGroup] =
                generateId();
            }
            newCustomField.parentId =
              customFieldObjectIds[customField.parentObjectGroup];
            customFieldObjects.push(newCustomField as CustomFieldValue);
          }
        });

        deltaWithCustomFieldValues = {
          ...delta,
          customFieldValues: [
            ...originalEnterpriseMetadataValues,
            ...newCustomFieldWithValue,
            ...customFieldObjects,
          ],
        };
      }

      return {
        ...deepSpread(project, deltaWithCustomFieldValues),
        ...(delta.hiremeOpenedForBidding
          ? { hireme: false, status: ProjectStatusApi.ACTIVE }
          : {}),
      };
    },
  );

  addUpdateTransformer<MilestoneRequestsCollection>(
    'milestoneRequests',
    (authUid, milestoneRequest, delta) => {
      switch (delta.action) {
        case MilestoneRequestAction.REJECT: {
          return {
            ...deepSpread(milestoneRequest, delta),
            status: MilestoneRequestStatusApi.REJECTED,
          };
        }
        case MilestoneRequestAction.ACCEPT: {
          return {
            ...deepSpread(milestoneRequest, delta),
            status: MilestoneRequestStatusApi.CREATED,
          };
        }
        default:
          return deepSpread(milestoneRequest, delta);
      }
    },
  );

  addUpdateTransformer<ThreadsCollection>('threads', (_, document, delta) => {
    // When a user is added to a 1-on-1 thread, a new thread is created instead of updating the members of the 1-on-1 thread
    const willCreateNewThread =
      document.threadType === ThreadTypeApi.PRIVATE_CHAT &&
      delta.members &&
      delta.members.length > document.members.length;
    if (willCreateNewThread && delta.otherMembers) {
      createThread({
        userId: document.owner,
        otherMembers: delta.otherMembers,
        threadType: ThreadTypeApi.GROUP,
        context: document.context,
        ...withMessage('Created group chat', document.owner),
      });
    }

    return {
      ...document,
      ...delta,
      ...(delta.isBlocked !== undefined
        ? {
            writePrivacy: delta.isBlocked
              ? PrivacyLevelApi.NONE
              : PrivacyLevelApi.MEMBERS,
          }
        : {}),
      ...(willCreateNewThread ? { otherMembers: document.otherMembers } : {}),
      ...(willCreateNewThread ? { members: document.members } : {}),
    };
  });

  // The total price needs to be recomputed here, as normally it would be
  // updated in the reducer, but that does not run in ui tests
  addUpdateTransformer<QuotationItemRevisionsCollection>(
    'quotationItemRevisions',
    (_, document, delta) => ({
      ...document,
      ...delta,
      totalPrice: document.quantity * (delta.unitPrice ?? 0),
    }),
  );

  addUpdateTransformer<NotificationSettingsCollection>(
    'notificationSettings',
    (_, document, delta) => ({
      ...document,
      ...delta,
    }),
  );

  addUpdateTransformer<ProfileCategoriesCollection>(
    'profileCategories',
    (_, document, delta) => ({
      ...document,
      ...delta,
      lastUpdated: Date.now(),
      portfolioItemsCount: delta.portfolioItems?.length ?? 0,
    }),
  );

  addUpdateTransformer<ContestHandoversCollection>(
    'contestHandovers',
    (authUid, handover, delta) => {
      // will have to support other actions later
      if (delta.action === ContestHandoverUpdateAction.SIGN_AGREEMENT) {
        if (authUid === handover.buyerId) {
          return {
            ...handover,
            buyerSignedContract: true,
            buyerSignedDate: Date.now(),
          };
        }
        return {
          ...handover,
          sellerSignedContract: true,
          sellerSignedDate: Date.now(),
        };
      }
      // SUBMIT is only for freelancers
      if (delta.action === ContestHandoverUpdateAction.SUBMIT) {
        return {
          ...handover,
          sellerConfirmed: true,
          sellerConfirmedDate: Date.now(),
        };
      }
      return {
        ...handover,
      };
    },
  );
}
