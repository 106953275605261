import { isPlatformBrowser } from '@angular/common';
import type {
  AfterContentInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  PLATFORM_ID,
  QueryList,
  ViewChild,
} from '@angular/core';
import { TimeUtils } from '@freelancer/time-utils';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor } from '@freelancer/ui/link';
import { MoreOptionsComponent } from '@freelancer/ui/more-options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, fromEvent, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TabItemComponent } from './tab-item.component';
import {
  TabsBorder,
  TabsColor,
  TabsDirection,
  TabsSize,
} from './tab-item.types';
import { TabsAlign } from './tabs.types';

@UntilDestroy({ className: 'TabsComponent' })
@Component({
  selector: 'fl-tabs',
  template: `
    <div
      class="Indicator ArrowLeft"
      (click)="onScrollLeft()"
      [attr.data-direction]="direction"
      [attr.data-color]="color"
      [flShowDesktop]="true"
      [ngClass]="{ IsDisabled: !canScrollLeft, IsVisible: showArrows === true }"
    >
      <fl-icon
        class="Arrow"
        [name]="'ui-arrow-left-alt'"
        [size]="IconSize.SMALL"
        [color]="
          color === TabsColor.LIGHT ? IconColor.LIGHT : IconColor.FOREGROUND
        "
      ></fl-icon>
    </div>
    <div
      class="TabList"
      #tablist
      [attr.data-direction]="direction"
      [attr.data-overflow-hidden]="overflowHidden"
      [attr.data-size]="size"
      [attr.data-size-tablet]="sizeTablet"
      [attr.data-size-desktop]="sizeDesktop"
      [attr.data-full-width]="fullWidth"
      [attr.data-truncate]="truncate"
      [attr.data-tab-list]="''"
      [attr.role]="'tablist'"
      [ngClass]="{ HasExtraItems: hasMoreOptions }"
    >
      <div
        #leftTabChild
        class="ObservedElement ObservedElement-left"
        [ngClass]="{ IsHidden: !showArrows }"
      ></div>
      <ng-content select="fl-tab-item"></ng-content>
      <div
        class="TabsExtra"
        *ngIf="hasMoreOptions && direction === TabsDirection.ROW"
        [flMarginRight]="'xsmall'"
        [attr.role]="'tab'"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-color]="color"
      >
        <ng-content select="fl-more-options"></ng-content>
      </div>
      <div
        #rightTabChild
        class="ObservedElement ObservedElement-right"
        [ngClass]="{ IsHidden: !showArrows }"
      ></div>
    </div>
    <div
      class="Indicator ArrowRight"
      (click)="onScrollRight()"
      [attr.data-direction]="direction"
      [attr.data-color]="color"
      [flShowDesktop]="true"
      [ngClass]="{
        IsDisabled: !canScrollRight,
        IsVisible: showArrows === true
      }"
    >
      <fl-icon
        class="Arrow"
        [name]="'ui-arrow-right-alt'"
        [size]="IconSize.SMALL"
        [color]="
          color === TabsColor.LIGHT ? IconColor.LIGHT : IconColor.FOREGROUND
        "
      ></fl-icon>
    </div>
    <div
      class="SpacingElement SpacingElement-right"
      [flHideDesktop]="true"
      [attr.data-color]="color"
      [class.SpacingElementVisible]="
        canScrollRight === true && showArrows === true
      "
    ></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent
  implements AfterContentInit, AfterViewInit, OnChanges, OnDestroy
{
  IconColor = IconColor;
  IconSize = IconSize;
  TabsColor = TabsColor;
  TabsDirection = TabsDirection;
  LinkColor = LinkColor;

  isDropdownActive = false;
  canScrollLeft: boolean;
  canScrollRight: boolean;
  showArrows: boolean;

  leftTabObserver: IntersectionObserver;
  rightTabObserver: IntersectionObserver;

  @Input() border = TabsBorder.NONE;
  @Input() borderPersist = false;
  @Input() color = TabsColor.FOREGROUND;
  @Input() direction = TabsDirection.ROW;
  @Input() size = TabsSize.LARGE;
  @Input() sizeTablet: TabsSize;
  @Input() sizeDesktop: TabsSize;
  @Input() textWrap = false;
  @Input() overflowHidden = false;

  /**
   * It requires the fullWidth property to set true;
   * - otherwise it won't put the ellipses at the end of the text.
   */
  @Input()
  get truncate(): boolean {
    return this._truncate;
  }

  set truncate(truncate: boolean) {
    if (truncate) {
      this.fullWidth = true;
    }
    this._truncate = truncate ?? false;
  }

  // This input is a workaround for the following issue
  // https://github.com/angular/angular/issues/16299
  // And should be used only as a workaround for the edge case having nested
  // <ng-content> objects when ContentChild check doesn't work.
  @Input() hasMoreOptions: boolean;

  @HostBinding('attr.data-full-width')
  @Input()
  fullWidth = false;

  @HostBinding('attr.data-align')
  @Input()
  align = TabsAlign.LEFT;

  @ContentChildren(TabItemComponent)
  tabItemComponents: QueryList<TabItemComponent>;

  private tabItemSubscription?: Subscription;
  private tabItemSelectionSubscriptions?: Subscription;
  private windowResize?: Subscription;
  private _truncate = false;

  @ContentChild(MoreOptionsComponent)
  moreOptionsComponent: MoreOptionsComponent;

  @ViewChild('tablist', { read: ElementRef })
  tabList: ElementRef<HTMLDivElement>;

  @ViewChild('leftTabChild', { read: ElementRef })
  leftTabChild: ElementRef<HTMLDivElement>;

  @ViewChild('rightTabChild', { read: ElementRef })
  rightTabChild: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone,
    private timeUtils: TimeUtils,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    // reapply whenever style inputs change
    this.applyStylesToChildren();
  }

  ngAfterContentInit(): void {
    // re-apply styles whenever the list of child tabs changes
    this.tabItemSubscription = this.tabItemComponents.changes
      .pipe(startWith(this.tabItemComponents.toArray()))
      .subscribe(() => this.applyStylesToChildren());
  }

  ngAfterViewInit(): void {
    this.hasMoreOptions = this.hasMoreOptions ?? !!this.moreOptionsComponent;
    if (isPlatformBrowser(this.platformId)) {
      firstValueFrom(
        this.ngZone.onStable.asObservable().pipe(untilDestroyed(this)),
      ).then(() => {
        this.applyScrollPosition();
        this.loadArrowIndicators();
      });

      this.windowResize = fromEvent(window, 'resize')
        .pipe(this.timeUtils.rxDebounceTime(250))
        .subscribe(() => {
          this.applyScrollPosition();
          this.loadArrowIndicators();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.tabItemSubscription) {
      this.tabItemSubscription.unsubscribe();
    }

    if (this.tabItemSelectionSubscriptions) {
      this.tabItemSelectionSubscriptions.unsubscribe();
    }

    if (this.windowResize) {
      this.windowResize.unsubscribe();
    }

    if (this.leftTabObserver) {
      this.leftTabObserver.disconnect();
    }

    if (this.rightTabObserver) {
      this.rightTabObserver.disconnect();
    }
  }

  // when the style inputs to this component change, we need to update
  // each individual tab item child to reflect these style changes in their
  // own css logic.
  applyStylesToChildren(): void {
    if (this.tabItemComponents) {
      this.tabItemComponents.forEach((component, index, arr) => {
        component.changeDetectorRef.detach();

        component.border = this.border || TabsBorder.NONE;
        component.borderPersist = this.borderPersist;
        component.color = this.color || TabsColor.FOREGROUND;
        component.direction = this.direction || TabsDirection.ROW;
        component.size = this.size || TabsSize.LARGE;
        component.sizeTablet = this.sizeTablet;
        component.sizeDesktop = this.sizeDesktop;
        component.fullWidth = this.fullWidth;
        component.textWrap = this.textWrap;
        component.truncate = this.truncate;

        // reattach and detect changes;
        component.changeDetectorRef.reattach();
        component.changeDetectorRef.detectChanges();

        this.tabItemSelectionSubscriptions?.unsubscribe();
        this.tabItemSelectionSubscriptions = new Subscription();

        if (isPlatformBrowser(this.platformId)) {
          this.tabItemSelectionSubscriptions.add(
            component.onSelected.subscribe((item: ElementRef) =>
              this.showActiveTab(item, true),
            ),
          );
        }
      });
    }
  }

  private applyScrollPosition(): void {
    this.tabItemComponents.forEach((component: TabItemComponent) => {
      if (component.isSelected) {
        this.showActiveTab(component.element, false);
      }
    });
  }

  private showActiveTab(item: ElementRef, animation: boolean): void {
    if (this.direction !== TabsDirection.ROW) {
      return;
    }

    const tabItem = item.nativeElement;
    const tabListElement = this.tabList.nativeElement;
    const scrollTarget =
      parseInt(tabItem.offsetLeft, 10) +
      tabItem.getBoundingClientRect().width / 2 -
      tabListElement.clientWidth / 2;

    tabListElement.scroll({
      top: 0,
      left: scrollTarget,
      behavior: animation ? 'smooth' : 'auto',
    });
  }

  observeFirstItem(): void {
    const leftTabChildElement = this.leftTabChild.nativeElement;
    const tabListElement = this.tabList.nativeElement;
    const config = {
      root: tabListElement,
    };

    // Observer First element
    this.leftTabObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.canScrollLeft = false;
        } else {
          this.canScrollLeft = true;
        }
        this.changeDetectorRef.markForCheck();
      });
    }, config);
    this.leftTabObserver.observe(leftTabChildElement);
  }

  observeLastItem(): void {
    const rightTabChildElement = this.rightTabChild.nativeElement;
    const tabListElement = this.tabList.nativeElement;
    const config = {
      root: tabListElement,
    };

    // Observer Last element
    this.rightTabObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.canScrollRight = false;
        } else {
          this.canScrollRight = true;
        }
        this.changeDetectorRef.markForCheck();
      });
    }, config);
    this.rightTabObserver.observe(rightTabChildElement);
  }

  loadArrowIndicators(): void {
    const tabListElement = this.tabList.nativeElement;

    if (
      tabListElement.offsetWidth + tabListElement.scrollLeft <
        tabListElement.scrollWidth ||
      tabListElement.scrollLeft > 0
    ) {
      this.showArrows = true;
    } else {
      this.showArrows = false;
    }
    this.observeFirstItem();
    this.observeLastItem();
    this.changeDetectorRef.markForCheck();
  }

  onScrollRight(): void {
    const tabListElement = this.tabList.nativeElement;

    tabListElement.scrollTo({
      left: tabListElement.scrollLeft + 65,
      behavior: 'smooth',
    });
  }

  onScrollLeft(): void {
    const tabListElement = this.tabList.nativeElement;

    tabListElement.scrollTo({
      left: tabListElement.scrollLeft - 65,
      behavior: 'smooth',
    });
  }
}
