import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BannerAlertType } from '@freelancer/ui/banner-alert';
import { HeadingType } from '@freelancer/ui/heading';
import { trackByValue } from '@freelancer/ui/helpers';
import { ListItemType } from '@freelancer/ui/list-item';
import {
  FontColor,
  FontStyle,
  FontType,
  FontWeight,
  TextSize,
} from '@freelancer/ui/text';

interface Section {
  heading: string;
  description: string;
  good?: string;
  bad?: string;
}

interface Resource {
  description: string;
  link: string;
}

@Component({
  selector: 'app-static-error-handling',
  template: `
    <div class="MainContainer">
      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Error Handling Guidelines
        </fl-heading>
        <fl-text [size]="TextSize.SMALL">
          We need to keep error messages consistent and user-friendly. When
          handling a new error case, the following guidelines should be
          followed.
        </fl-text>
        <fl-list-item
          [bordered]="false"
          [indent]="true"
          [flMarginBottom]="'small'"
        >
          <fl-text [fontStyle]="FontStyle.ITALIC">
            "Established wisdom holds that good error messages are polite,
            precise, and constructive. The Web brings a few new guidelines: Make
            error messages clearly visible, reduce the work required to fix the
            problem, and educate users along the way." ― Nielsen Norman Group,
            <fl-link
              [link]="
                'https://www.nngroup.com/articles/error-message-guidelines/'
              "
            >
              Error Message Guidelines
            </fl-link>
          </fl-text>
        </fl-list-item>
      </fl-card>

      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Design Principles
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          First and foremost, if a problem occurs, it should fail gracefully if
          possible. This means that the product should not be brittle. Functions
          should be fail-safed by including fall back actions and retries.
          Displaying errors to the user should only happen in exceptional cases
          where the prior actions repeatedly fail.
        </fl-text>
      </fl-card>

      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Purpose
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Error messages should be purposeful, achieving the following goals:
        </fl-text>
        <fl-section-article>
          <fl-col
            *ngFor="let purpose of purposes; trackBy: trackByHeading"
            [col]="12"
            [colTablet]="4"
            [flMarginBottom]="'small'"
          >
            <fl-section-article-entry>
              <fl-section-article-entry-heading>
                {{ purpose.heading }}
              </fl-section-article-entry-heading>
              <fl-section-article-entry-detail>
                {{ purpose.description }}
              </fl-section-article-entry-detail>
            </fl-section-article-entry>
          </fl-col>
        </fl-section-article>
      </fl-card>

      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          User Experience
        </fl-heading>
        <fl-text [size]="TextSize.XSMALL">
          In general, we want to enhance UX where possible. This includes:
        </fl-text>
        <fl-list-item
          *ngFor="let ux of userExperience; trackBy: trackByValue"
          [bordered]="false"
        >
          <fl-text
            class="UserExperience"
            [size]="TextSize.XSMALL"
          >
            <fl-icon
              [name]="'ui-check-circle'"
              [flMarginRight]="'xxxsmall'"
            ></fl-icon>
            {{ ux }}
          </fl-text>
        </fl-list-item>
      </fl-card>

      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Error message copy
        </fl-heading>

        <div [flMarginBottom]="'small'">
          <fl-text
            [size]="TextSize.XSMALL"
            [flMarginBottom]="'small'"
          >
            <fl-text
              [fontType]="FontType.STRONG"
              [size]="TextSize.XSMALL"
            >
              A human description
            </fl-text>
            is a detailed description of what happened and why. It should be:
          </fl-text>
          <div
            *ngFor="
              let errorDescription of errorDescriptions;
              trackBy: trackByHeading
            "
            [flMarginBottom]="'small'"
          >
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.XSMALL"
              [flMarginBottom]="'xxsmall'"
            >
              {{ errorDescription.heading }}
            </fl-heading>
            <fl-text
              [size]="TextSize.XSMALL"
              [flMarginBottom]="'small'"
            >
              {{ errorDescription.description }}
            </fl-text>
            <fl-grid>
              <fl-col
                [col]="12"
                [colTablet]="6"
              >
                <fl-banner-alert
                  bannerTitle="Good"
                  [compact]="true"
                  [type]="BannerAlertType.SUCCESS"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  {{ errorDescription.good }}
                </fl-banner-alert>
              </fl-col>
              <fl-col
                [col]="12"
                [colTablet]="6"
              >
                <fl-banner-alert
                  bannerTitle="Bad"
                  [compact]="true"
                  [type]="BannerAlertType.ERROR"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  {{ errorDescription.bad }}
                </fl-banner-alert>
              </fl-col>
            </fl-grid>
          </div>
        </div>

        <div [flMarginBottom]="'small'">
          <fl-text
            [size]="TextSize.XSMALL"
            [flMarginBottom]="'small'"
          >
            <fl-text
              [fontType]="FontType.STRONG"
              [size]="TextSize.XSMALL"
            >
              A human action
            </fl-text>
            is an actionable item the user can take and should be:
          </fl-text>
          <div [flMarginBottom]="'small'">
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.XSMALL"
              [flMarginBottom]="'xxsmall'"
            >
              Descriptive
            </fl-heading>
            <fl-text [flMarginBottom]="'small'">
              Needs to be precise on what the user can do - use steps if
              necessary. Assume that the user has no idea what they are doing.
            </fl-text>
            <fl-grid>
              <fl-col
                [col]="12"
                [colTablet]="6"
              >
                <fl-banner-alert
                  bannerTitle="Good"
                  [compact]="true"
                  [type]="BannerAlertType.SUCCESS"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  Check that you have the necessary funds in your account by
                  going into <fl-link>Account > Funds</fl-link>
                </fl-banner-alert>
                <fl-banner-alert
                  bannerTitle="Good"
                  [compact]="true"
                  [type]="BannerAlertType.SUCCESS"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  Deposit more funds into your account. Visit for more
                  information on <fl-link>payment methods</fl-link>.
                </fl-banner-alert>
                <fl-banner-alert
                  bannerTitle="Good"
                  [compact]="true"
                  [type]="BannerAlertType.SUCCESS"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  Go back to the <fl-link>buy exam page</fl-link> and attempt to
                  buy the exam again
                </fl-banner-alert>
                <fl-banner-alert
                  bannerTitle="Good"
                  [compact]="true"
                  [type]="BannerAlertType.SUCCESS"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  If the problem persists visit our <fl-link>FAQ</fl-link> or
                  <fl-link>contact support</fl-link>
                </fl-banner-alert>
              </fl-col>
              <fl-col
                [col]="12"
                [colTablet]="6"
              >
                <fl-banner-alert
                  bannerTitle="Bad"
                  [compact]="true"
                  [type]="BannerAlertType.ERROR"
                  [closeable]="false"
                  [flMarginBottom]="'small'"
                >
                  Deposit more funds into your account
                </fl-banner-alert>
              </fl-col>
            </fl-grid>
          </div>
          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.XSMALL"
            [flMarginBottom]="'xxsmall'"
          >
            Educational
          </fl-heading>
          <fl-text>
            We should take this time to educate the user on the process of how
            to perform the task(s) properly to reduce future occurrences. We can
            do this by providing steps as before or linking the user to an
            appropriate article.
          </fl-text>
        </div>

        <fl-text [size]="TextSize.XSMALL">
          Note that for every error, there should be more than one action that
          the user can take. Usually, there will be an action for contacting
          support and searching the knowledge base for more information. These
          guidelines should also be used when writing additional information
          that will be used by support.
        </fl-text>
      </fl-card>

      <fl-card
        class="SectionCard"
        [edgeToEdge]="true"
        [flMarginBottom]="'mid'"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Resources and References
        </fl-heading>
        <div *ngFor="let resource of resources; trackBy: trackByDescription">
          <fl-link [link]="resource.link">
            {{ resource.description }}
          </fl-link>
        </div>
      </fl-card>
    </div>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './error-handling.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandlingComponent {
  BannerAlertType = BannerAlertType;
  FontColor = FontColor;
  TextSize = TextSize;
  FontStyle = FontStyle;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
  trackByValue = trackByValue;

  /**
   * Iterate over this to reduce template bloat
   * This is only ok because we don't translate Bits
   * Don't try this at home (in gaf)
   */
  readonly purposes: readonly Section[] = [
    {
      heading: 'Awareness',
      description: 'Show the user an error occurred and why',
    },
    {
      heading: 'Action',
      description: `Give the user actions to take to rectify the issue.
          We want them to be able to fix the issue themselves if possible.`,
    },
    {
      heading: 'Education',
      description: `Educate users if they made a mistake
          so it doesn’t happen again.`,
    },
  ];
  readonly userExperience: readonly string[] = [
    'Preserving the user’s work',
    'Reducing the effort involved in rectifying the issue',
    'Ensuring visibility of the error if it is not whole-page',
    'Providing links to support, FAQ and common areas of the site',
    'Providing a search field linked to site’s search engine if feasible',
  ];
  readonly errorDescriptions: readonly Section[] = [
    {
      heading: 'Explicit',
      description: 'Needs to include what went wrong as well as reasons why',
      good: `You cannot purchase the exam because it seems you do not have enough funds`,
      bad: 'Not enough funds',
    },
    {
      heading: 'Polite',
      description: 'Must not put blame on the user or imply they are wrong',
      good: `The page you are looking for could not be found. This may be because...`,
      bad: 'You tried to access an illegal page',
    },
    {
      heading: 'Precise',
      description: 'Must be specific to the erroneous event that occurred',
      good: `Date of birth must be in the format DD-MM-YY`,
      bad: 'Syntax Error',
    },
  ];
  readonly resources: readonly Resource[] = [
    {
      description: 'How to Write a Perfect Error Message - UX Planet',
      link: 'https://uxplanet.org/how-to-write-a-perfect-error-message-da1ca65a8f36',
    },
    {
      description: 'How to implement errors in GAF',
      link: 'https://phabricator.freelancer.com/w/workflow/error_handling/',
    },
  ];

  trackByHeading(_: number, section: Section): string {
    return section.heading;
  }

  trackByDescription(_: number, resource: Resource): string {
    return resource.description;
  }
}
