import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TimeTrackingPunch } from './time-tracking-punches.model';

export interface GenerateTimeTrackingPunchOptions {
  readonly bidId: number;
  readonly sessionId: number;
  readonly url?: string;
}

export interface GenerateTimeTrackingPunchesOptions {
  readonly bidId: number;
  readonly sessionIds: readonly number[];
}

export function generateTimeTrackingPunches({
  sessionIds,
  bidId,
}: GenerateTimeTrackingPunchesOptions): readonly TimeTrackingPunch[] {
  return sessionIds.map(sessionId => ({
    id: generateId(),
    sessionId,
    timeCreated: Date.now(),
    bidId,
  }));
}

export function generateTimeTrackingPunch({
  bidId,
  sessionId,
  url,
}: GenerateTimeTrackingPunchOptions): TimeTrackingPunch {
  return {
    id: generateId(),
    sessionId,
    timeCreated: Date.now(),
    bidId,
    url,
  };
}

// Mixins

export function withScreenshot(): Pick<
  GenerateTimeTrackingPunchOptions,
  'url'
> {
  return { url: '/screenshots/screenshot.jpg' };
}
