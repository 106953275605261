import { generateId } from '@freelancer/datastore/testing/helpers';
import { Enterprise } from '../enterprise/enterprise.model';
import type { EnterpriseModel } from './enterprises.model';

export interface GenerateEnterpriseOptions {
  readonly id?: number;
  readonly internalName: string;
  readonly name: string;
  readonly seoUrl: string;
  readonly isTestEnterprise?: boolean;
  readonly kycVerified?: boolean;
  readonly dateCreated?: number;
  readonly description?: string;
  readonly landingPageUrl?: string;
  readonly logoFileId?: string;
  readonly avatarFileId?: string;
}

export function generateEnterpriseObject({
  id,
  internalName,
  name,
  seoUrl,
  isTestEnterprise = false,
  kycVerified = false,
  dateCreated = Date.now(),
  description,
  landingPageUrl,
  logoFileId,
  avatarFileId,
}: GenerateEnterpriseOptions): EnterpriseModel {
  return {
    id: id || generateId(),
    internalName,
    name,
    seoUrl,
    isTestEnterprise,
    kycVerified,
    dateCreated,
    description,
    landingPageUrl,
    logoFileId,
    avatarFileId,
  };
}

export function generateEnterpriseObjects(
  enterpriseOptionsList: readonly GenerateEnterpriseOptions[],
): readonly EnterpriseModel[] {
  return enterpriseOptionsList.map(enterpriseOptions =>
    generateEnterpriseObject(enterpriseOptions),
  );
}

export function facebookEnterprise(): GenerateEnterpriseOptions {
  return {
    id: Enterprise.FACEBOOK,
    internalName: 'facebook',
    seoUrl: 'facebook',
    name: 'Facebook',
    description: 'Facebook is a Social Network.',
    landingPageUrl: 'https://facebook.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
  };
}

export function deloitteEnterprise(): GenerateEnterpriseOptions {
  return {
    id: Enterprise.DELOITTE_DC,
    internalName: 'deloitte_dc',
    seoUrl: 'deloitte',
    name: 'Deloitte',
    description: 'Deloitte description',
    landingPageUrl: 'https://deloitte.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
    kycVerified: true,
  };
}

export function hpEnterprise(): GenerateEnterpriseOptions {
  return {
    id: Enterprise.HP,
    internalName: 'hewlett_packard',
    seoUrl: 'hewlett-packard',
    name: 'HP',
    description: 'HP description',
    landingPageUrl: 'https://hp.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
  };
}

export function insourceEnterprise(): Required<GenerateEnterpriseOptions> {
  return {
    id: 999,
    internalName: 'insource',
    seoUrl: 'insource',
    name: 'Insource',
    description: 'Insource description',
    landingPageUrl: 'https://insource.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
    dateCreated: new Date(2021, 2, 16).getTime(),
    isTestEnterprise: false,
    kycVerified: true,
  };
}

export function freightlancerEnterprise(): Required<GenerateEnterpriseOptions> {
  return {
    id: Enterprise.FREIGHTLANCER,
    internalName: 'freightlancer',
    seoUrl: 'freightlancer',
    name: 'Freightlancer',
    description: 'Freightlancer description',
    landingPageUrl: 'https://freightlancer.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
    dateCreated: new Date(2021, 2, 16).getTime(),
    isTestEnterprise: false,
    kycVerified: true,
  };
}

export function nasaEnterprise(): Required<GenerateEnterpriseOptions> {
  return {
    id: Enterprise.NASA,
    internalName: 'nasa',
    seoUrl: 'nasa',
    name: 'NASA',
    description: 'NASA description',
    landingPageUrl: 'https://nasa.example',
    logoFileId: 'uuid-logo',
    avatarFileId: 'uuid-avatar',
    dateCreated: new Date(2021, 3, 8).getTime(),
    isTestEnterprise: false,
    kycVerified: true,
  };
}
