import { generateId } from '@freelancer/datastore/testing/helpers';
import { MilestoneStatusApi } from 'api-typings/projects/projects';
import type { Bid } from '../bids/bids.model';
import { CurrencyCode } from '../currencies/currencies.model';
import {
  currencyCodeFromId,
  generateCurrencyObject,
} from '../currencies/currencies.seed';
import type { QuotationItemRevision } from '../quotation-item-revisions/quotation-item-revisions.model';
import type { Milestone } from './milestones.model';

export interface GenerateMilestoneOptions {
  readonly bidId?: number;
  readonly bidderId?: number;
  readonly projectId: number;
  readonly projectOwnerId: number;
  readonly description?: string;
  readonly amount?: number;
  readonly currencyCode?: CurrencyCode;
  readonly status: MilestoneStatusApi;
  readonly cancellationRequested?: boolean;
  readonly timeCreated?: number;
  readonly timeReleased: number | undefined;
  readonly isInitialPayment?: boolean;
}

export function generateMilestoneObject({
  bidId,
  bidderId,
  projectId,
  projectOwnerId,
  description = 'Default milestone description',
  amount = 1,
  currencyCode = CurrencyCode.USD,
  status,
  cancellationRequested,
  timeCreated,
  timeReleased,
  isInitialPayment,
}: GenerateMilestoneOptions): Milestone {
  const currency = generateCurrencyObject(currencyCode);

  return {
    id: generateId(),
    bidId,
    bidderId,
    projectId,
    projectOwnerId,
    description,
    amount,
    currency,
    status,
    cancellationRequested,
    timeCreated: timeCreated ?? Date.now(),
    timeReleased,
    isInitialPayment,
  };
}

export function partiallyPaidBidMilestone(
  bid: Bid,
): Partial<GenerateMilestoneOptions> {
  return {
    amount: bid.amount * 0.5,
  };
}

export function fullyPaidBidMilestone(
  bid: Bid,
): Partial<GenerateMilestoneOptions> {
  return {
    amount: bid.amount,
  };
}

export function initialPayment(bid: Bid): Partial<GenerateMilestoneOptions> {
  return {
    isInitialPayment: true,
    description: 'Initial Payment',
    amount: (bid.amount * bid.period) / 10,
  };
}

export function milestoneFromBid(
  bid: Bid,
): Pick<
  GenerateMilestoneOptions,
  'bidId' | 'bidderId' | 'projectId' | 'projectOwnerId'
> {
  return {
    bidId: bid.id,
    bidderId: bid.bidderId,
    projectId: bid.projectId,
    projectOwnerId: bid.projectOwnerId ?? generateId(),
  };
}

export function milestoneFromQuotationItemRevision({
  quotationItemRevision,
  textToAppend,
}: {
  readonly quotationItemRevision: QuotationItemRevision;
  readonly textToAppend?: string /** Useful in UI tests to distinguish between multiple milestones for a quotation item */;
}): Pick<GenerateMilestoneOptions, 'amount' | 'currencyCode' | 'description'> {
  return {
    amount: quotationItemRevision.totalPrice,
    currencyCode: currencyCodeFromId(quotationItemRevision.currencyId),
    description: quotationItemRevision.name + (textToAppend ?? ''),
  };
}

export function frozenMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.FROZEN,
    timeReleased: undefined,
  };
}

export function canceledMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'cancellationRequested' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.CANCELED,
    cancellationRequested: false,
    timeReleased: undefined,
  };
}

export function clearedMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'cancellationRequested' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.CLEARED,
    cancellationRequested: false,
    timeReleased: Date.now(),
  };
}

export function disputedMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.DISPUTED,
    timeReleased: undefined,
  };
}

export function pendingMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.PENDING,
    timeReleased: undefined,
  };
}

export function requestedReleaseMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.REQUESTED_RELEASE,
    timeReleased: undefined,
  };
}

export function pendingClearanceMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.PENDING_CLEARANCE,
    timeReleased: undefined,
  };
}

export function pendingKycMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.PENDING_RECIPIENT_KYC,
    timeReleased: undefined,
  };
}

export function unknownMilestone(): Pick<
  GenerateMilestoneOptions,
  'status' | 'timeReleased'
> {
  return {
    status: MilestoneStatusApi.UNKNOWN,
    timeReleased: undefined,
  };
}
