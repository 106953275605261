import type { IconColor } from '@freelancer/ui/icon';

export enum RatingTicksColor {
  PRIMARY = 'primary',
  SUCCESS = 'success',
}

export type RatingColor = IconColor.DARK | IconColor.INHERIT | IconColor.LIGHT;

export enum RatingType {
  STARS = 'stars',
  TICKS = 'ticks',
}

export type RatingTextColor = 'foreground' | 'white';
