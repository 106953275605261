import type { MapCoordinates } from '@freelancer/datastore/core';
import { assertNever } from '@freelancer/utils';
import type { Country } from '../countries/countries.model';
import { CountryCode } from '../countries/countries.model';
import { generateCountryObjects } from '../countries/countries.seed';
import type { Timezone } from '../timezones/timezones.model';
import type { Location } from './location.model';
import { LocationCountryCode, LocationPreset } from './location.model';

interface GenerateLocationOptions {
  readonly country?: Country;
  readonly city?: string;
  readonly mapCoordinates?: MapCoordinates;
  readonly vicinity?: string;
  readonly administrativeArea?: string;
  readonly administrativeAreaCode?: string;
  readonly fullAddress?: string;
  readonly postalCode?: string;
  readonly timezone?: Timezone;
}

export function generateLocationObject({
  country = generateCountryObjects({ countryCodes: [CountryCode.US] })[0],
  city = 'Chicago',
  mapCoordinates = {
    latitude: 41.876,
    longitude: -87.64,
  },
  vicinity = 'Chicago',
  administrativeArea,
  administrativeAreaCode,
  fullAddress = '525 W. Van Buren, Suite 1100, Chicago IL 60607',
  postalCode = '60607',
  timezone = undefined,
}: GenerateLocationOptions = {}): Location {
  return {
    country,
    city,
    mapCoordinates,
    vicinity,
    administrativeArea,
    administrativeAreaCode,
    fullAddress,
    postalCode,
    timezone,
  };
}

export function getLocationPresets(locationPreset: LocationPreset): {
  readonly countryCode: LocationCountryCode;
  readonly city: string;
  readonly mapCoordinates: MapCoordinates;
  readonly vicinity?: string;
  readonly administrativeArea?: string;
  readonly administrativeAreaCode?: string;
  readonly fullAddress?: string;
  readonly postalCode?: string;
  readonly timezone?: Timezone;
} {
  switch (locationPreset) {
    case LocationPreset.SYDNEY:
      return {
        countryCode: LocationCountryCode.AU,
        city: 'Sydney',
        fullAddress: 'Sydney, NSW',
        mapCoordinates: { latitude: -33.868, longitude: 151.209 },
        vicinity: 'Sydney',
        administrativeArea: 'New South Wales',
        administrativeAreaCode: 'NSW',
        postalCode: '2000',
        timezone: {
          id: 422,
          country: 'Australia',
          timezoneName: 'Australia/Sydney',
          offset: '+1000', // Australian Eastern Standard Time
        },
      };

    case LocationPreset.MELBOURNE:
      return {
        countryCode: LocationCountryCode.AU,
        city: 'Melbourne',
        fullAddress: 'Melbourne, VIC',
        mapCoordinates: { latitude: -37.813, longitude: 144.963 },
        vicinity: 'Melbourne',
        administrativeArea: 'Victoria',
        administrativeAreaCode: 'VIC',
        postalCode: '3000',
        timezone: {
          id: 420,
          country: 'Australia',
          timezoneName: 'Australia/Melbourne',
          offset: '+1000', // Australian Eastern Standard Time
        },
      };

    case LocationPreset.KUALA_LUMPUR:
      return {
        countryCode: LocationCountryCode.MY,
        city: 'Kuala Lumpur',
        mapCoordinates: { latitude: 3.157, longitude: 101.712 },
        vicinity: 'Kuala Lumpur',
        administrativeArea: 'Federal Territory of Kuala Lumpur',
        timezone: {
          id: 186,
          country: 'Malaysia',
          timezoneName: 'Asia/Kuala_Lumpur',
          offset: '+0800',
        },
      };

    case LocationPreset.DHAKA:
      return {
        countryCode: LocationCountryCode.BD,
        city: 'Dhaka',
        mapCoordinates: { latitude: 23.81, longitude: 90.413 },
        vicinity: 'Dhaka',
        administrativeArea: 'Dhaka Division',
      };

    case LocationPreset.KARACHI:
      return {
        countryCode: LocationCountryCode.PK,
        city: 'Karachi',
        mapCoordinates: { latitude: 24.926, longitude: 67.108 },
      };

    case LocationPreset.MUMBAI:
      return {
        countryCode: LocationCountryCode.IN,
        city: 'Mumbai',
        mapCoordinates: { latitude: 19.076, longitude: 72.877 },
        timezone: {
          id: 186,
          country: 'India',
          timezoneName: 'Asia/Kolkata',
          offset: '+0530',
        },
      };
    case LocationPreset.SEATTLE:
      return {
        countryCode: LocationCountryCode.US,
        city: 'Seattle',
        mapCoordinates: { latitude: 47.606, longitude: -122.332 },
        vicinity: 'Seattle',
        administrativeArea: 'Washington',
      };
    case LocationPreset.VATICAN_CITY:
      return {
        countryCode: LocationCountryCode.VA,
        city: 'Vatican City',
        mapCoordinates: { latitude: 41.902, longitude: 12.453 },
        vicinity: 'Vatican City',
      };
    case LocationPreset.ROME:
      return {
        countryCode: LocationCountryCode.IT,
        city: 'Rome',
        mapCoordinates: { latitude: 41.89, longitude: 12.492 },
        vicinity: 'Rome',
        administrativeArea: 'Lazio',
      };

    default:
      assertNever(locationPreset);
  }
}
