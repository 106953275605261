import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '@freelancer/theme';
import { IconColor } from '@freelancer/ui/icon';
import { BackgroundColor, LogoSize } from '@freelancer/ui/logo';
import { TabsSize } from '@freelancer/ui/tabs';
import { FontColor, FontWeight, TextTransform } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  template: `
    <div
      class="Header"
      data-testing="sticky-header"
    >
      <div
        class="Header-inner"
        [ngClass]="{ Homepage: isHomepage }"
      >
        <div class="Header-primary">
          <div
            class="LogoContainer"
            [flMarginRight]="'xsmall'"
            [flMarginRightTablet]="'none'"
          >
            <fl-button
              class="MenuButton"
              [flMarginRight]="'xsmall'"
              [flHideDesktop]="true"
              (click)="handleMenuButtonClick()"
            >
              <fl-icon
                [name]="'ui-bars-v2'"
                [color]="IconColor.FOREGROUND"
              ></fl-icon>
            </fl-button>
            <fl-logo
              class="Logo"
              [backgroundColor]="BackgroundColor.THEME"
              [size]="LogoSize.SMALL"
              [flMarginRight]="'xxxsmall'"
              (click)="onClick()"
            ></fl-logo>
          </div>
          <app-theme-toggle [flHideDesktop]="true" />
          <app-search
            class="Header-search"
            [flHideDesktop]="true"
            [isExpandable]="true"
            [hideInternalPages]="hideInternalPages"
            [expandableIconLight]="false"
          ></app-search>
        </div>
        <ng-container *ngIf="(storyOnly$ | flAsync) === false">
          <div
            *ngIf="isHomepage"
            class="Spacer"
          ></div>
          <app-nav-tabs
            [flHideMobile]="isHomepage"
            [flHideTablet]="isHomepage"
            [size]="TabsSize.LARGE"
            [sizeDesktop]="TabsSize.XLARGE"
            [hideInternalPages]="hideInternalPages"
          ></app-nav-tabs>
          <app-theme-toggle
            [flHideMobile]="true"
            [flHideTablet]="true"
          />
          <app-search
            class="Header-search"
            [hideInternalPages]="hideInternalPages"
            [flShowDesktop]="true"
          ></app-search>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  LogoSize = LogoSize;
  BackgroundColor = BackgroundColor;
  TabsSize = TabsSize;
  TextTransform = TextTransform;

  storyOnly$: Observable<boolean>;

  @Output() menuButtonClicked = new EventEmitter<void>();
  @Input() hideInternalPages = false;
  @Input() isHomepage = false;
  currentTheme$: Observable<string>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.currentTheme$ = this.themeService.getTheme();
    this.storyOnly$ = this.activatedRoute.queryParamMap.pipe(
      map(params => isDefined(params.get('story_only'))),
    );
  }

  onClick(): void {
    this.router.navigate(['/']);
  }

  handleMenuButtonClick(): void {
    this.menuButtonClicked.emit();
  }
}
