import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { trackByValue } from '@freelancer/ui/helpers';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { InputComponent, InputType } from '@freelancer/ui/input';
import type { ListItemComponent } from '@freelancer/ui/list-item';
import type { Subscription } from 'rxjs';
import { Focus } from '../focus/focus.service';
import { FontWeight, TextSize, TextTransform } from '../text';
import type { GroupedSearchResults, SearchItem } from './search.types';

@Component({
  selector: 'fl-search',
  template: `
    <fl-input
      #inputComponent
      class="Input"
      [borderless]="borderless"
      [borderlessMobile]="borderlessMobile"
      [dynamicValidation]="dynamicValidation"
      [control]="queryControl"
      [disabled]="disabled"
      [expanded]="expanded"
      [iconMarginMobile]="iconMarginMobile"
      [iconStart]="showIcon ? 'ui-search' : undefined"
      [iconStartLabel]="'Search'"
      [id]="inputId"
      [isExpandable]="isExpandable"
      [leftIconColor]="searchIconColor"
      [placeholder]="placeholder"
      [rightIconColor]="IconColor.FOREGROUND"
      [type]="InputType.SEARCH"
      [searchRoundCorners]="roundCorners"
      [shakeOnBlur]="shakeOnBlur"
      (iconStartClick)="handleIconStartClick($event)"
      (keyup)="handleInputKeyUp($event)"
      (keydown)="handleInputKeyDown($event)"
    ></fl-input>
    <fl-button
      class="ButtonClear"
      *ngIf="queryControl.value && clearButton"
      [disabled]="disabled"
      (click)="handleIconClearClick($event)"
    >
      <fl-icon
        class="IconClear"
        [name]="'ui-close-alt'"
        [size]="IconSize.SMALL"
      ></fl-icon>
    </fl-button>

    <fl-card
      class="SearchResults"
      *ngIf="
        (displayResultsWithEmptyInput && !queryControl.value) ||
        (displayResults && queryControl.value)
      "
      [edgeToEdge]="true"
      [class.SearchResults-callout]="increaseZIndex"
    >
      <fl-scrollable-content
        *ngIf="scrollableSearchResults; else nonScrollable"
        class="ScrollbarContainer"
        [autoHeight]="true"
      >
        <ng-container *ngTemplateOutlet="searchResults"></ng-container>
      </fl-scrollable-content>
      <ng-template #nonScrollable>
        <ng-container *ngTemplateOutlet="searchResults"></ng-container>
      </ng-template>
    </fl-card>

    <ng-template #searchResults>
      <ng-container *ngFor="let type of itemTypes; trackBy: trackByValue">
        <fl-list>
          <fl-list-item *ngIf="itemTypeTitles[type]">
            <fl-text
              [size]="TextSize.XXXSMALL"
              [weight]="FontWeight.BOLD"
              [textTransform]="TextTransform.UPPERCASE"
              >{{ itemTypeTitles[type] }}</fl-text
            >
          </fl-list-item>
        </fl-list>
        <fl-list
          [bottomBorder]="true"
          [clickable]="true"
        >
          <fl-list-item
            #listItem
            *ngFor="
              let item of groupedSearchResults[type];
              let i = index;
              trackBy: trackBySearchItem
            "
            (mousemove)="mouseMove(listItem)"
            (mouseleave)="mouseLeave()"
            (click)="selectItem(item)"
            [attr.itemType]="type"
            [attr.itemIndex]="i"
          >
            <ng-container
              *ngTemplateOutlet="
                itemTemplates[item.type];
                context: { $implicit: item.context }
              "
            ></ng-container>
          </fl-list-item>
        </fl-list>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
  IconSize = IconSize;
  IconColor = IconColor;
  InputType = InputType;
  FontWeight = FontWeight;
  TextTransform = TextTransform;
  TextSize = TextSize;
  expanded = false;
  groupedSearchResults: GroupedSearchResults = {};
  queryControl = new FormControl('', { nonNullable: true });
  focusIndex = -1;
  focusItem?: ListItemComponent<unknown>;
  itemTypes: readonly string[] = [];
  searchIconColor: IconColor;
  trackByValue = trackByValue;

  @Input() disabled = false;
  @Input() isExpandable = false;
  @Input() iconEnd?: string;
  @Input() itemTypeTitles: { [type: string]: string } = {};
  @Input() itemTemplates: { [type: string]: TemplateRef<any> } = {};
  /** Limits search results' container height to 200px and makes it scrollable. */
  @Input() scrollableSearchResults = false;
  @Input() searchResults: readonly SearchItem[] = [];
  @Input() placeholder = $localize`Search`;
  @Input() displayResults = true;
  @Input() initialValue = '';
  /** HTML id for the input element. */
  @Input() inputId?: string;
  /**
   * Show options when the user has not entered anything
   */
  @Input() displayResultsWithEmptyInput = false;
  /**
   * Increase the result card z-index to callout level. Default: dropdown.
   */
  @Input() increaseZIndex = false;
  /**
   * Not for general use. This is only for Messaging search.
   * If you'd like to use this input, make sure you discuss with UI Eng
   */
  @Input() borderless = false;
  /**
   * Not for general use. This is only for browse mobile page.
   * If you'd like to use this input, make sure you discuss with UI Eng
   */
  @Input() borderlessMobile = false;
  /**
   * Not for general use. This is only for browse mobile page.
   * If you'd like to use this input, make sure you discuss with UI Eng.
   */
  @Input() iconMarginMobile = true;
  @Input() expandableIconLight = false;
  @Input() clearQueryOnSelect = false;

  @Input() showIcon = true;

  @Input() roundCorners = true;

  // Toggles the clear button
  @Input() clearButton = true;

  // Additional togglable fields for fl-input
  @Input() dynamicValidation = false;
  @Input() shakeOnBlur = true;

  // Custom control for use-cases that needs input suggestion
  @Input() control?: FormControl<string>;

  @Output() query = new EventEmitter<string>();
  @Output() select = new EventEmitter<SearchItem>();
  @Output() submit = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();

  // Reference to input component
  @ViewChild('inputComponent')
  inputComponent: InputComponent<any>;
  // Reference to list of components
  @ViewChildren('listItem') listItems: QueryList<ListItemComponent<unknown>>;
  // Reference to list of ElementRef<HTMLElement>s
  @ViewChildren('listItem', { read: ElementRef })
  listItemElements: QueryList<ElementRef<HTMLElement>>;

  private querySubscription?: Subscription;

  constructor(private focus: Focus) {}

  // Listening for clicks outside the input component
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent): void {
    if (
      event &&
      !this.inputComponent.inputContainer.nativeElement.contains(
        event.target as HTMLElement,
      ) &&
      this.expanded
    ) {
      // expanded while there is a query
      this.expanded = !!this.queryControl.value;
      this.displayResults = false;
      this.setSearchIconColor();
    }
  }

  ngOnInit(): void {
    this.queryControl = this.control ?? this.queryControl;
    this.querySubscription = this.queryControl.valueChanges.subscribe(query => {
      this.query.emit(query);
    });

    this.setSearchIconColor();
  }

  setSearchIconColor(): void {
    if (!this.isExpandable || this.expanded) {
      this.searchIconColor = IconColor.FOREGROUND;
      return;
    }

    if (this.expandableIconLight) {
      this.searchIconColor = IconColor.LIGHT;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.removeFocus();
    const baseGroupedSearchResults: GroupedSearchResults = { untyped: [] };
    this.groupedSearchResults = this.searchResults.reduce((acc, i) => {
      if (!(i.type in acc)) {
        acc[i.type] = [];
      }
      acc[i.type].push(i);
      return acc;
    }, baseGroupedSearchResults);

    this.itemTypes = Object.keys(this.groupedSearchResults);

    if (this.clearQueryOnSelect) {
      this.clearQuery();
    }

    if ('initialValue' in changes && this.initialValue !== '') {
      this.queryControl.setValue(this.initialValue);
    }
  }

  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
  }

  handleIconStartClick(e: MouseEvent): void {
    e.stopPropagation();
    this.focus.focusElement(this.inputComponent.nativeElement);
    this.displayResults = true;
    this.expanded = true;
    this.setSearchIconColor();
  }

  handleIconClearClick(e: MouseEvent): void {
    e.stopPropagation();
    this.clearQuery();
    this.focus.focusElement(this.inputComponent.nativeElement);
  }

  clearQuery(): void {
    this.queryControl.setValue('');
    this.clear.emit();
  }

  handleInputKeyDown(e: KeyboardEvent): void {
    if (!e.key) return;

    const k = e.key.toLowerCase();
    switch (k) {
      case 'arrowdown': {
        // Prevent cursor from moving to the right
        e.preventDefault();
        break;
      }
      case 'arrowup': {
        // Prevent cursor from moving to the left
        e.preventDefault();
        break;
      }
      case 'tab': {
        // Prevent default tab action
        e.preventDefault();
        break;
      }
      default:
        break; // Do nothing
    }
  }

  handleInputKeyUp(e: KeyboardEvent): void {
    if (!e.key) return;

    const k = e.key.toLowerCase();
    if (k === 'enter') {
      this.selectOrSubmit();
      return;
    }
    switch (k) {
      case 'escape': {
        // IMPORTANT - if you modify this behaviour make sure
        // to update multi-select. They should offer the same functionality.
        this.clearQuery();
        break;
      }
      case 'arrowdown': {
        this.moveDown();
        e.stopPropagation();
        e.preventDefault();
        break;
      }
      case 'arrowup': {
        this.moveUp();
        e.stopPropagation();
        e.preventDefault();
        break;
      }
      case 'tab': {
        if (e.shiftKey) {
          this.moveUp();
        } else {
          this.moveDown();
        }
        e.stopPropagation();
        e.preventDefault();
        break;
      }
      default:
        break; // Do nothing
    }
  }

  selectOrSubmit(): void {
    if (this.focusItem) {
      const itemType = this.listItemElements
        .toArray()
        [this.focusIndex].nativeElement.getAttribute('itemType') as string;
      const itemIndex = Number(
        this.listItemElements
          .toArray()
          [this.focusIndex].nativeElement.getAttribute('itemIndex') as string,
      );
      this.select.emit(this.groupedSearchResults[itemType][itemIndex]);
    } else {
      this.submit.emit();
    }
  }

  selectItem(item: SearchItem): void {
    this.select.emit(item);
    if (item.displayValue) {
      this.queryControl.setValue(item.displayValue, {
        emitEvent: false,
      });
    } else {
      this.clearQuery();
    }
  }

  // Focus helpers - there's quite a few of these, sorry.
  moveDown(): void {
    this.focusIndex += 1;
    this.reFocus();
  }

  moveUp(): void {
    this.focusIndex -= 1;
    this.reFocus();
  }

  mouseMove(item: ListItemComponent<unknown>): void {
    this.focusIndex = this.listItems.toArray().indexOf(item);
    this.reFocus();
  }

  mouseLeave(): void {
    this.removeFocus();
  }

  reFocus(): void {
    this.fixFocusOverflow();
    this.updateFocusItem();
  }

  updateFocusItem(): void {
    const focusItem = this.listItems.toArray()[this.focusIndex];
    if (focusItem) {
      this.focusItem = focusItem;
    }
  }

  removeFocus(): void {
    if (this.focusItem) {
      delete this.focusItem;
      this.focusIndex = -1;
    }
  }

  fixFocusOverflow(): void {
    if (this.focusIndex < 0) {
      this.focusIndex = this.searchResults.length - 1;
    } else if (this.focusIndex >= this.searchResults.length) {
      this.focusIndex = 0;
    }
  }

  trackBySearchItem(_: number, item: SearchItem): SearchItem {
    return item;
  }

  // T252371 - Set the query value on selection of an item in
  // a custom (external) search results list
  setQuery(query: string): void {
    this.queryControl.setValue(query);
  }
}
