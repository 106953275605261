import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FreelancerAsyncPipeModule } from '@freelancer/pipes';
import { ThemeService } from '@freelancer/theme';
import { IconColor, IconModule, IconSize } from '@freelancer/ui/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';

@UntilDestroy({ className: 'ThemeToggleComponent' })
@Component({
  selector: 'app-theme-toggle',
  standalone: true,
  template: `
    <fl-icon
      [name]="(theme$ | flAsync) === 'dark' ? 'ui-moon' : 'ui-sun'"
      [useIconFont]="true"
      [fillIconFont]="true"
      [color]="IconColor.FOREGROUND"
      [clickable]="true"
      [size]="IconSize.SMALL"
      (click)="toggle()"
    />
  `,
  styleUrls: ['./theme-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconModule, FreelancerAsyncPipeModule],
})
export class ThemeToggleComponent {
  IconColor = IconColor;
  IconSize = IconSize;

  private themeService = inject(ThemeService);
  theme$ = this.themeService.getTheme();

  async toggle(): Promise<void> {
    const currentTheme = await firstValueFrom(
      this.theme$.pipe(untilDestroyed(this)),
    );

    await this.themeService.setThemeSetting(
      currentTheme === 'dark' ? 'light' : 'dark',
    );
  }
}
