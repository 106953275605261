import { transition, trigger, useAnimation } from '@angular/animations';
import { slideInHorizontal, slideOutHorizontal } from '../slide';

export const slideInHorizontalAnimation = trigger(
  'slideInHorizontalAnimation',
  [transition(':enter', [useAnimation(slideInHorizontal)])],
);

export const slideOutHorizontalAnimation = trigger(
  'slideOutHorizontalAnimation',
  [transition(':leave', [useAnimation(slideOutHorizontal)])],
);

export const slideInOutHorizontalAnimation = trigger(
  'slideInOutHorizontalAnimation',
  [
    transition(':enter', [useAnimation(slideInHorizontal)]),
    transition(':leave', [useAnimation(slideOutHorizontal)]),
  ],
);
