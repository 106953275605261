import {
  AnimationEvent,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { fadeIn, fadeOut } from '@freelancer/animations';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor, LinkWeight } from '@freelancer/ui/link';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';
import { Margin } from '../margin';
import {
  BannerAlertButton,
  BannerAlertLevel,
  BannerAlertType,
} from './banner-alert.types';

@Component({
  selector: 'fl-banner-alert',
  template: `
    <div
      class="Container"
      [attr.data-compact]="compact"
      [attr.data-bordered]="bordered"
    >
      <div
        *ngIf="!compact"
        [ngSwitch]="type"
        [flHideMobile]="true"
        [flMarginRight]="'xsmall'"
      >
        <fl-icon
          *ngSwitchCase="BannerAlertType.INFO"
          [color]="IconColor.SECONDARY"
          [name]="'ui-info'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
        />
        <fl-icon
          *ngSwitchCase="BannerAlertType.ERROR"
          [name]="'ui-error'"
          [size]="IconSize.SMALL"
          [color]="IconColor.ERROR"
          [useIconFont]="true"
        />
        <fl-icon
          *ngSwitchCase="BannerAlertType.SUCCESS"
          [color]="IconColor.SUCCESS"
          [name]="'ui-check-in-circle-v2'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
        />
        <fl-icon
          *ngSwitchCase="BannerAlertType.WARNING"
          [color]="IconColor.CONTEST"
          [name]="'ui-warning'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
        />
        <fl-icon
          *ngSwitchCase="BannerAlertType.CRITICAL"
          [color]="IconColor.LIGHT"
          [fillIconFont]="true"
          [name]="'ui-error'"
          [size]="IconSize.SMALL"
          [useIconFont]="true"
        />
      </div>
      <div
        class="Content"
        [flMarginRight]="'small'"
        [flMarginRightTablet]="
          compact ? 'small' : !closeable && !bordered ? 'none' : 'large'
        "
      >
        <fl-text
          *ngIf="bannerTitle"
          [color]="
            fontColor
              ? fontColor
              : type === BannerAlertType.CRITICAL
              ? FontColor.LIGHT
              : FontColor.FOREGROUND
          "
          [size]="TextSize.XSMALL"
          [weight]="FontWeight.BOLD"
          [flMarginBottom]="marginBottom"
        >
          {{ bannerTitle }}
        </fl-text>
        <fl-text
          class="Description"
          [size]="TextSize.XSMALL"
          [color]="
            fontColor
              ? fontColor
              : type === BannerAlertType.CRITICAL
              ? FontColor.LIGHT
              : FontColor.FOREGROUND
          "
          [flMarginBottom]="bannerAlertButton ? 'xxsmall' : 'none'"
        >
          <ng-content />
        </fl-text>
        <fl-link
          *ngIf="bannerAlertButton"
          [link]="bannerAlertButton.link"
          [fragment]="bannerAlertButton.fragment"
          [queryParams]="bannerAlertButton.queryParams"
          [weight]="LinkWeight.BOLD"
          [color]="
            type === BannerAlertType.CRITICAL
              ? LinkColor.LIGHT
              : LinkColor.DEFAULT
          "
          (click)="handleButtonClick()"
        >
          {{ bannerAlertButton.text }}
        </fl-link>
      </div>

      <fl-button
        *ngIf="closeable"
        class="CloseButton"
        i18n-label="Banner alert close button"
        label="Close banner alert"
        (click)="hide()"
      >
        <fl-icon
          [color]="
            type === BannerAlertType.CRITICAL
              ? IconColor.LIGHT
              : IconColor.FOREGROUND
          "
          [name]="'ui-close'"
          [size]="IconSize.SMALL"
        />
      </fl-button>
    </div>
  `,
  styleUrls: ['./banner-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('hideElement', [
      transition('visible => hidden', [
        useAnimation(fadeOut, {
          params: { duration: '500' },
        }),
      ]),
      transition('* => visible', [
        useAnimation(fadeIn, {
          params: { duration: '250' },
        }),
      ]),
    ]),
  ],
})
export class BannerAlertComponent {
  BannerAlertType = BannerAlertType;
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  IconSize = IconSize;
  LinkColor = LinkColor;
  LinkWeight = LinkWeight;
  TextSize = TextSize;

  @Input() bannerTitle?: string;
  @Input() bannerAlertButton?: BannerAlertButton;
  @Input() @HostBinding('attr.data-type') type = BannerAlertType.INFO;
  @Input() @HostBinding('attr.data-closeable') closeable = true;
  @Input() compact = false;
  @Input() @HostBinding('attr.data-level') level = BannerAlertLevel.NONE;
  @Input() @HostBinding('attr.data-bordered') bordered = true;
  @Input() fontColor?: FontColor;
  @Input() marginBottom: Margin = 'xxxsmall';

  @Output() buttonClicked = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  @HostBinding('attr.role') readonly role = 'alert';
  @HostBinding('class.IsHidden') bannerAlertHidden = false;
  @HostBinding('@hideElement') state = 'visible';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('@hideElement.done', ['$event'])
  animationDone(event: AnimationEvent): void {
    if (event.toState === 'hidden') {
      this.bannerAlertHidden = true;
    }
  }

  handleButtonClick(): void {
    this.buttonClicked.emit();
  }

  hide(): void {
    this.state = 'hidden';
    this.close.emit();
    this.changeDetectorRef.markForCheck();
  }

  show(): void {
    this.state = 'visible';
    this.bannerAlertHidden = false;
    this.changeDetectorRef.markForCheck();
  }
}
